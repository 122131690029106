import React from 'react';
import './styles.scss';

export function DocumentosLegais() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Documentos Legais</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A sessão <strong>“Documentos de Legais”</strong> tem como objetivo armazenar, organizar e gerenciar todos os documentos legais relacionados ao dossier fiscal da empresa.</p>
          <p>Esta funcionalidade é responsável por categorizar e arquivar esses documentos de maneira estruturada, permitindo um acesso fácil e rápido a eles quando necessário.</p>
          <p>Essa organização facilita a localização dos documentos para auditorias, consultas legais, revisões fiscais ou outras necessidades legais.</p>

        </div>
      </div>
    </React.Fragment>
  )
}
