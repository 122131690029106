import React from 'react';
import { Lista } from '../../components/Lista';

export function Administrador() {

  const items = [
    [
      { pointer:'•	Gerenciamento de usuários: ', text: 'criar, modificar ou excluir usuários do sistema, definir permissões de acesso e redefinir senhas;' },
      { pointer:'•	Configurações do sistema: ', text: 'configurar opções do sistema, como idioma, moeda, formato de data, entre outros;' },
      { pointer:'•	Gerenciamento de backups: ', text: 'programar e executar backups automáticos do sistema e restaurar backups anteriores;' },
      { pointer:'•	Monitoramento do sistema: ', text: 'verificar o status do sistema, identificar erros ou falhas, e tomar medidas preventivas ou corretivas;' },
      { pointer:'•	Personalização do sistema: ', text: 'personalizar o layout e as cores do sistema de acordo com a identidade visual da empresa;' },
      { pointer:'•	Gerenciamento de licenças: ', text: 'gerenciar as licenças do sistema e verificar a validade das mesmas;' },
      { pointer:'•	Atualizações do sistema: ', text: 'realizar atualizações do sistema e instalar novas versões ou funcionalidades;' },
      { pointer:'•	Suporte ao usuário: ', text: 'fornecer suporte técnico aos usuários do sistema, respondendo a dúvidas e solucionando problemas.' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Administrador</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A Administração central do sistema é realizada pelo formulário central do administrador, onde o usuário com permissões de administrador pode realizar diversas tarefas, tais como:</p>
          <Lista isOrdered={false}  items={items[0]}/>
        </div>
      </div>
    </React.Fragment>
  )
}
