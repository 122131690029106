import React from 'react';
import './styles.scss';
import { Lista } from '../../../../components/Lista';

export function DossierContabilistico() {

  const items = [
    [
      { text:'Trespasses' },
      { text:'Imobilizações ' },
      { text:'Participações ' },
      { text:'Reservas' },
      { text:'Despesas' },
      { text:'Existências ' },
      { text:'Provisões ' },
      { text:'Restrições Especializações ' },
      { text:'Empréstimos ' },
      { text:'Capitalizações ' },
      { text:'Contratos Plurianuais' }
    ],
    [
      { pointer:'•	IMPRIMIR', text: ' - Funcionalidade de Visualização PDF. '},
      { pointer:'•	ADICIONAR', text: ' - Comando de abertura do formulário de adição de conteúdo.'},
      { pointer:'•	REMOVER', text: ' - Comando de exclusão de conteúdo. '},
      { pointer:'•	IMPORTAR', text: ' - Permite importar template do mapa selecionado no Ficheiro XLS. '},
      { pointer:'•	TEMPLATE', text: ' - Ficheiro do tipo modelo para preenchimento em formato XLS. '},
      { pointer:'•	ANEXOS', text: ' - Permite relacionar vários anexos ao respetivo mapa. '},
      { pointer:'•	MODELOS', text: ' - Seleção do modelo “Mapa” a preferência do utilizador. '},
      { pointer:'•	FUNDAMENTAÇÃO LEGAL', text: ' - Notas legais explicativas sobre a preparação do dossier. '},
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Dossier Contabilístico</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p> O Dossier Contabilístico refere-se ao conjunto de documentos e informações utilizados para registrar, organizar e documentar as transações financeiras e contabilísticas de uma empresa.</p>
          <p> Esse conjunto de documentos é fundamental para manter um registro adequado das atividades financeiras e contábeis da organização, além de cumprir com as obrigações legais e regulatórias.</p>
          <p> Nesta sessão, foram desenvolvidas 11 categorias de mapas para facilitar a preparação, e a organização dos documentos e informações contidas no dossier de forma clara e sistemática:</p>
          <Lista isOrdered={true} items={items[0]}/>
          <p><strong>Funcionalidades dos formulários das análises das demonstrações financeiras:</strong></p>
          <Lista isOrdered={false} items={items[1]}/>
        
        </div>
      </div>
    </React.Fragment>
  )
}
