import React from 'react'; 
import { Lista } from '../../../../components/Lista';

export function Servicos() {

  const items = [
    [
      { pointer:'◾ Funcionalidades adicionais: ', text: 'Os serviços em um sistema desktop podem fornecer funcionalidades adicionais que não estão disponíveis diretamente na interface do usuário. Eles podem executar tarefas em segundo plano, como processamento de dados em lote, sincronização com outros sistemas, manipulação de eventos ou monitoramento de recursos do sistema. Os serviços permitem que o sistema opere de forma eficiente, mesmo quando não há interação direta com o usuário.' },
      { pointer:'◾ Execução em segundo plano: ', text: 'Os serviços são executados em segundo plano, sem a necessidade de intervenção do usuário. Eles são iniciados automaticamente durante o processo de inicialização do sistema ou podem ser ativados manualmente quando necessário. Os serviços geralmente são executados como processos separados e têm sua própria lógica de execução e ciclo de vida.' },
      { pointer:'◾ Comunicação com a interface do usuário: ', text: 'Embora os serviços não tenham uma interface de usuário própria, eles podem interagir com o sistema e fornecer informações à interface do usuário ou receber comandos dela. Por exemplo, um serviço de atualização pode verificar periodicamente por novas versões de software e notificar o usuário quando uma atualização estiver disponível. Os serviços podem se comunicar com a interface do usuário por meio de mecanismos como eventos, notificações ou chamadas de API.' },
      { pointer:'◾ Independência da sessão do usuário: ', text: 'Os serviços são executados de forma independente da sessão do usuário. Isso significa que eles continuam a ser executados mesmo quando um usuário faz logoff do sistema. Essa independência permite que os serviços executem tarefas agendadas, processos em segundo plano ou operações contínuas sem interrupção, garantindo que as funcionalidades do sistema estejam sempre disponíveis.' },
      { pointer:'◾ Gerenciamento de serviços: ', text: 'Os serviços podem ser gerenciados por meio de ferramentas específicas fornecidas pelo sistema operacional ou por meio de APIs disponíveis para desenvolvedores. Essas ferramentas permitem iniciar, parar, pausar ou reiniciar serviços, bem como ajustar as configurações de execução. O gerenciamento adequado dos serviços é importante para garantir que eles funcionem corretamente, consumam recursos adequadamente e atendam aos requisitos de segurança.' },
      { pointer:'◾ Depuração e monitoramento: ', text: 'Durante o desenvolvimento e implantação de serviços, é essencial ter recursos para depuração e monitoramento. As ferramentas de desenvolvimento e diagnóstico permitem rastrear a execução dos serviços, identificar erros, analisar logs e monitorar o desempenho. Isso facilita a detecção e solução de problemas nos serviços e garante sua operação correta.' },
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Serviços</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>Em um sistema desktop, os serviços desempenham um papel importante no fornecimento de funcionalidades e recursos adicionais. Um serviço é um programa executado em segundo plano, independentemente da interação direta do usuário, e é responsável por realizar tarefas específicas ou fornecer funcionalidades para o sistema. Aqui estão alguns pontos importantes sobre os serviços em um sistema desktop:</p>
          <Lista isOrdered={false} items={items[0]} />
          <p>Em resumo, os serviços em um sistema desktop fornecem funcionalidades adicionais em segundo plano, executando tarefas específicas e permitindo a comunicação com a interface do usuário. Eles são executados de forma independente da sessão do usuário e podem ser gerenciados e monitorados por meio de ferramentas específicas. Os serviços desempenham um papel fundamental no aprimoramento do</p>
        </div>
      </div>
    </React.Fragment>
  )
}
