import React from 'react';
import { Lista } from '../../components/Lista';

export function Gerenciamento() {

  const items = [
    [
      { pointer:'•	Gestão de tarefas: ', text: 'permite criar, atribuir, acompanhar e concluir tarefas relacionadas a diversas áreas do sistema, como contabilidade, fiscalidade, relatórios, entre outras.' },
      { pointer:'•	Gestão de exercício: ', text: 'possibilita a configuração de parâmetros relacionados ao exercício fiscal, como período de apuração, data de encerramento do exercício, entre outros.' },
      { pointer:'•	Gestão de correspondências: ', text: 'permite o registro, acompanhamento e controle de correspondências recebidas e enviadas pela empresa, incluindo notificações fiscais, comunicações com clientes e fornecedores, entre outras.' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Gerenciamento</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>O módulo de gerenciamento é uma área geral onde são disponibilizadas diversas ferramentas para gerenciamento de atividades e informações do sistema. </p>
        <p>Algumas das funcionalidades que podem ser encontradas nesse módulo são:</p>
        <Lista isOrdered={false} items={items[0]} />
        <p>Além disso, o módulo de gerenciamento pode conter outras funcionalidades, dependendo das necessidades da empresa e das especificidades do sistema utilizado.</p>
        </div>
      </div>
    </React.Fragment>
  )
}