import React from 'react';
import './styles.scss';
import { Lista } from '../../../components/Lista';

export function PlaneamentoFiscal() {

  const items = [
    [
      { pointer: '•	Planeamento de transações', text: ': Estruturar transações financeiras e comerciais de forma a otimizar a tributação, por exemplo, através do uso de preços de transferência em operações entre empresas relacionadas.' },
      { pointer: '•	Planeamento de Obrigações', text: ': Estruturar os documentos que farão parte do dossier fiscal (Contabilístico, de gestão e permanente) ' },
      { pointer: '•	Planeamento de Liquidações', text: ': Estruturar transações financeiras e liquidações a serem realizadas no âmbito das dívidas fiscais. ' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Planeamento Fiscal</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O planeamento fiscal, também conhecido como planeamento tributário, é a prática de antecipar e estruturar as atividades financeiras e operacionais de uma empresa de maneira a otimizar a carga tributária de forma legal.</p>
          <p>O objetivo principal do planeamento fiscal é reduzir os impostos pagos pela empresa e estabelecer, aproveitando as oportunidades e benefícios previstos na legislação tributária e estabelecer as diretrizes de constituição do dossier fiscal.</p>
          <p>O planeamento fiscal envolve a análise e o estudo dos diferentes regimes fiscais, incentivos fiscais, deduções, isenções e outros mecanismos legais disponíveis.</p>
          <p>Ele é realizado por profissionais especializados em direito tributário e contabilidade, que buscam identificar oportunidades legais de economia de impostos, respeitando as leis fiscais vigentes e as obrigações fiscais atinentes a atividade operacional da empresa.</p>
          <p><strong>No sistema mastercontas é subdividido em três sessões:</strong></p>
          <Lista isOrdered={false} items={items[0]} />
          <p>É importante ressaltar que o planeamento fiscal deve ser realizado dentro dos limites da legalidade e da ética, evitando a evasão fiscal ou a utilização de estratégias agressivas que possam ser consideradas abusivas pelas autoridades fiscais.</p>
          <p>As práticas de planeamento fiscal devem estar em conformidade com as leis fiscais e regulamentos aplicáveis e devem ser transparentes para as autoridades fiscais e demais partes interessadas.</p>
        </div>
      </div>
    </React.Fragment>
  )
}