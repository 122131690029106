import React from 'react'; 
import { Lista } from '../../../../components/Lista';
// import { RequiredLogin } from '../../../../components/RequiredLogin';

const items = [
  [
    { text: 'Aceder ao Administrador; ' },
    { text: 'Na sessão “ACESSOS” clicar em Rescrições ' },
    { text: 'Selecionar o Projeto “Contabilidade”' },
    { text: 'Selecionar a Empresa ' },
    { text: 'Selecionar o exercício que pretende proteger' },
    { text: 'Proteger o exercício e gravar' }
  ]
]


export function Restricoes() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Restrições Exercício</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <RequiredLogin/> */}

          <p>O Sistema Mastercontas permite realizar a restrição de acesso por cada exercício financeiro que a empresa tem, o que ajuda a manter a segurança das informações.</p>
          <p><strong>Como restringir um exercício?</strong></p>
          <Lista isNumeric={true} items={items[0]}/>

          <p><strong>Informações complementares sobre as restrições do exercício:</strong> </p>
          <p>Com essa funcionalidade, é possível definir as permissões de acesso para cada exercício financeiro individualmente. Por exemplo, um usuário pode ter permissão para acessar as informações financeiras de um determinado exercício, mas não ter acesso a informações de outro exercício.</p>
          <p>A ferramenta de proteção do exercício ajuda a proteger a segurança das informações financeiras da empresa, impedindo que usuários não autorizados acessem dados financeiros de exercícios antigos ou que não sejam relevantes para suas atividades.</p>
          <p>No geral, os níveis de proteção do Sistema Mastercontas ajudam a garantir que as informações financeiras da empresa estejam seguras e acessíveis apenas para usuários autorizados, oferecendo assim uma camada adicional de segurança para a organização</p>

        </div>
      </div>
    </React.Fragment>
  )
}
