import React from 'react';
import './styles.scss';
import { RequiredLogin } from '../../../../components/RequiredLogin';
 
export function ApuramentoImposto() {
  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Apuramento Imposto</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <RequiredLogin/> 
        </div>
      </div>
    </React.Fragment>
  )
};
