import {
  HomePage,
  ProfilePage,
  EventoPrincipalPage,
  InicializacaoEmpresaPage,
  GestaoConrrespondenciaPage,
  GestaoTarefaPage,
  MemorandosOcorrenciasPage,
  CalendarioGestaoPage,
  GestaoExerciciosPage,
  PlanosContasPage,
  LancamentosPage,
  integracoesPage,
  ImportacoesPage,
  EspecializacoesPage,
  DocumentoAberturaPage,
  TransferenciasContasPage,
  ReconcilhacoesPage,
  ResultadosPage,
  AnalisesResultadosPage,
  ExtratosContasPage,
  BalancetesExerciciosPage,
  ConferenciaMovimentosPage,
  InportacaoPage,
  AtualizacaoPage,
  EventosProcessamentosPage,
  amortizacoesReintegracoesPage,
  NotasContasPage,
  DemonstracoesResultadosPage,
  BalancoPage,
  DRENaturezaFuncoesPage,
  DemonstracoesFluxoCaixaPage,
  DeclaracaoProvisoriaPage,
  DeclaracoesAnuaisPage,
  ApuramentoImpostoPage,
  AnalisesContabilisticasPage,
  ParametrosRelatorioPage,
  RepInportacaoPage,
  RepAtualizacaoPage,
  RepEventosProcessamentosPage,
  DemonstracoesFinanceirasPage,
  PosicaoPatrimonialPage,
  RelFinDemonstracoesFinanceirasPage,
  RelFinDemonstracoesFluxoCaixaPage,
  IndicadoresFinanceirosPage,
  PainelReportingPage,
  FinancialAnalysisPage,
  BussinesAtivityPage,
  PlanoAnaliticoPage,
  CentrosCustosPage,
  CustosIndustriaisPage,
  CustosNaoIndustriaisPage,
  ObjectoCustoPage,
  VolumeProducaoPage,
  MomentoExploracaoPage,
  CustoFuncaoPage,
  ExploracaoPage, 
  AnaliseTextuaisPage,
  VáriaveisNegocioPage,
  PainelGestaoIndicadoresPage,
  AnaliseDemonstracoesPage,
  AnaliseIndicadoresPage,
  MaketAnalysisPage,
  ReportingMapaPage,
  MarketPage,
  PerspetivaAgregadaPage,
  SintesePerspectivasPage,
  PlaneamentoFiscalPage,
  PlaneamentoObrigacoesPage,
  PlaneamentoLiquidacoesPage,
  ImpostosCorrentesPage,
  ImpostoIndustrialPage,
  DeclaracaoAnualImpostoIndustrialPage,
  OutrasDeclaracoesFiscaisPage,
  DeclaracoesMensaisPage,
  DossierContabilisticoPage,
  DocumentosLegaisPage,
  DocumentosGestaoPage,
  MapasIntenosPage,
  GestaoDossierFiscalPage,
  ObrigacoesDeclarativasPage,
  ObrigacoesContributivasPage,
  PedidoEsclarecimentosPage,
  DocumentoRespostaPage,
  ObjectosFiscalizacoesPage,
  ExecucaoFiscalPage,
  GestaoProcessosPage,
  GarantiasEmbargoPage,
  LiquidacoesTributariasPage,
  ConfiguracaoEmpresaPage,
  ParmGestaoExerciciosPage,
  LiqgacaoExtesionPage,
  FundamentosDossierPage,
  BalancetesPage,
  ContabildiadePage,
  RelatorioPage,
  AutomacoesPage,
  AutomacoesEspecializadasPage,
  DemonstracoesContabilisticasPage,
  RelatorioFinanceiroPage,
  ParmApuramentoImpostoPage,
  DiagnosticosFiscaisPage,
  CriacaoPage,
  ConfiguracaoPage,
  AdmAtualizacaoPage,
  ConfiguracoesSistemaPage,
  PerfisPage,
  AcessosPage,
  UtilizadoresPage,
  RestricoesPage,
  BackupPage,
  RestorePage,
  ManutencaoPage,
  ServicosPage,
  TabelasPage,
  Page404,
  Balancetes,
  PortalSuportePage,
  AnaliseEmpresarialPage,
  ContabilidadePage,
  ControllerPage,
  FiscalidadePage,
  FiscalizacoesPage,
  ReportingPage,
  SistemaPage,
  AuditoriaRevisaoContasPage,
  ConsolidacaoContasPage,
  GerenciamentoPage,
  RelatorioContasPage,
  ParametrosPage,
  AdministradorPage,
  ProjectoPage,
  ComunicacoesPage,
  BussinesAnalysisPage,
  BalancedScorcardPage,
  PlaneamentoTransacoesPage,
  DeclaracoesFiscaisPage,
  DoddierFiscalPage,
  GuiaFormacaoPage

} from './pages'; 
import { withNavigationWatcher } from './contexts/navigation';  

const routes = [
  { path: '/profile', element: ProfilePage },
  { path: '/home', element: HomePage },
  { path: '/eventoPrincipal', element: EventoPrincipalPage }, 
  { path: '/contabilidade', element: ContabilidadePage }, 
  { path: '/analiseEmpresarial', element: AnaliseEmpresarialPage  },
  { path: '/controller', element: ControllerPage },
  { path: '/fiscalidade', element: FiscalidadePage },
  { path: '/fiscalizacoes', element: FiscalizacoesPage },
  { path: '/reporting', element: ReportingPage },
  { path: '/sistema', element: SistemaPage },
  { path: '/auditoriaRevisaoContas', element: AuditoriaRevisaoContasPage },
  { path: '/consolidacaoContas', element: ConsolidacaoContasPage },
  { path: '/gerenciamento', element: GerenciamentoPage },
  { path: '/relatorioContas', element: RelatorioContasPage },
  { path: '/parametros', element: ParametrosPage },
  { path: '/administrador', element: AdministradorPage },
  { path: '/projectos', element: ProjectoPage },

  { path: '/gerenciamento/inicializacaoEmpresa', element: InicializacaoEmpresaPage },
  { path: '/gerenciamento/gestaoConrrespondecia', element: GestaoConrrespondenciaPage },
  { path: '/gerenciamento/gestaoTarefas', element: GestaoTarefaPage },
  { path: '/gerenciamento/memorandoOcorrencia', element: MemorandosOcorrenciasPage },
  { path: '/gerenciamento/calendarioGestao', element: CalendarioGestaoPage },
  { path: '/gerenciamento/gestaoExercicios', element: GestaoExerciciosPage },

  { path: '/contabilidade/planosContas', element: PlanosContasPage },
  { path: '/contabilidade/lancamentos', element: LancamentosPage },
  { path: '/contabilidade/movimentos/integracoes', element: integracoesPage },
  { path: '/contabilidade/movimentos/importacoes', element: ImportacoesPage },
  { path: '/contabilidade/movimentos/especializacoes', element: EspecializacoesPage },
  { path: '/contabilidade/operacoesCorrentes/documentoAbertura', element: DocumentoAberturaPage },
  { path: '/contabilidade/operacoesCorrentes/transferenciasContas', element: TransferenciasContasPage },
  { path: '/contabilidade/reconcilhacoes', element: ReconcilhacoesPage },
  { path: '/contabilidade/resultados', element: ResultadosPage },
  { path: '/contabilidade/exploracao/conferenciaMovimentos', element: ConferenciaMovimentosPage },
  { path: '/contabilidade/exploracao/analisesResultados', element: AnalisesResultadosPage },
  { path: '/contabilidade/exploracao/extratosContas', element: ExtratosContasPage },
  { path: '/contabilidade/exploracao/balancetesExercícios', element: BalancetesExerciciosPage },

  { path: '/relatorioContas/balancetes/inportacao', element: InportacaoPage },
  { path: '/relatorioContas/balancetes/atualizacao', element: AtualizacaoPage },
  { path: '/relatorioContas/balancetes/eventosProcessamentos', element: EventosProcessamentosPage },
  { path: '/relatorioContas/amortizacoesReintegracoes', element: amortizacoesReintegracoesPage },
  { path: '/relatorioContas/notasContas', element: NotasContasPage },
  { path: '/relatorioContas/DFCsDemostracoes', element: DemonstracoesResultadosPage }, 
  { path: '/relatorioContas/demonstracoesFinanceiras/balancoPatrimonial', element: BalancoPage }, 
  { path: '/relatorioContas/demonstracoesFinanceiras/demonstracoesResultados', element: DRENaturezaFuncoesPage },
  { path: '/relatorioContas/demonstracoesFinanceiras/demonstracoesFluxoCaixa', element: DemonstracoesFluxoCaixaPage },
  { path: '/relatorioContas/tributacao/declaracaoProvisoria', element: DeclaracaoProvisoriaPage },
  { path: '/relatorioContas/tributacao/declaracoesAnuais', element: DeclaracoesAnuaisPage },
  { path: '/relatorioContas/tributacao/apuramentoImposto', element: ApuramentoImpostoPage },
  { path: '/relatorioContas/analisesContabilisticas', element: AnalisesContabilisticasPage },
  { path: '/relatorioContas/parametrosRelatorio', element: ParametrosRelatorioPage }, 

  { path: '/reporting/balancetes/inportacao', element: RepInportacaoPage }, 
  { path: '/reporting/balancetes/atualizacao', element: RepAtualizacaoPage }, 
  { path: '/reporting/balancetes/eventosProcessamentos', element: RepEventosProcessamentosPage }, 
  { path: '/reporting/demonstracoesFinanceiras', element: DemonstracoesFinanceirasPage }, 
  { path: '/reporting/relatorioFinanceiro/posicaoPatrimonial', element: PosicaoPatrimonialPage }, 
  { path: '/reporting/relatorioFinanceiro/demonstracoesFinanceiras', element: RelFinDemonstracoesFinanceirasPage }, 
  { path: '/reporting/relatorioFinanceiro/demonstracoesFluxoCaixa', element: RelFinDemonstracoesFluxoCaixaPage }, 
  { path: '/reporting/relatorioFinanceiro/indicadoresFinanceiros', element: IndicadoresFinanceirosPage }, 
  { path: '/reporting/reporting/painelReporting', element: PainelReportingPage }, 
  { path: '/reporting/reporting/financialAnalysis', element: FinancialAnalysisPage }, 
  { path: '/reporting/reporting/bussinesAtivity', element: BussinesAtivityPage },
 
  { path: '/controller/planoAnalitico', element: PlanoAnaliticoPage },
  { path: '/controller/centrosCustos', element: CentrosCustosPage },
  { path: '/controller/custosIndustriais', element: CustosIndustriaisPage },
  { path: '/controller/custosNaoIndustriais', element: CustosNaoIndustriaisPage },
  { path: '/controller/classificacaoCustos/objectoCusto', element: ObjectoCustoPage },
  { path: '/controller/classificacaoCustos/volumeProducao', element: VolumeProducaoPage },
  { path: '/controller/classificacaoCustos/momentoExploracao', element:  MomentoExploracaoPage },
  { path: '/controller/classificacaoCustos/custoFuncao', element: CustoFuncaoPage },
  { path: '/controller/exploracao', element: ExploracaoPage },

  { path: '/analiseEmpresarial/analiseTextual', element: AnaliseTextuaisPage },
  { path: '/analiseEmpresarial/variaveisNegocio', element: VáriaveisNegocioPage },
  { path: '/analiseEmpresarial/painelGestaoIndicadores', element: PainelGestaoIndicadoresPage },
  { path: '/analiseEmpresarial/analiseDemostracoes', element: AnaliseDemonstracoesPage },
  { path: '/analiseEmpresarial/analiseIndicadores', element: AnaliseIndicadoresPage },
  { path: '/analiseEmpresarial/bussinesAnalysis/maketAnalysis', element: MaketAnalysisPage },
  { path: '/analiseEmpresarial/bussinesAnalysis/reportingMapa', element: ReportingMapaPage },
  { path: '/analiseEmpresarial/bussinesAnalysis/market', element: MarketPage },
  { path: '/analiseEmpresarial/balancedScorcard/perspetivaAgregada', element: PerspetivaAgregadaPage },
  { path: '/analiseEmpresarial/balancedScorcard/sintesePerspectivas', element: SintesePerspectivasPage },

  { path: '/fiscalidade/planeamentoFiscal', element: PlaneamentoFiscalPage},
  { path: '/fiscalidade/planeamentoFiscal/planeamentoObrigacoes', element: PlaneamentoObrigacoesPage},
  { path: '/fiscalidade/planeamentoFiscal/planeamentoLiquidacoes', element: PlaneamentoLiquidacoesPage},
  { path: '/fiscalidade/planeamentoFiscal/planeamentoTransacoes', element: PlaneamentoTransacoesPage}, 
  { path: '/fiscalidade/apuramentoImpostos/impostosCorrentes', element: ImpostosCorrentesPage},
  { path: '/fiscalidade/apuramentoImpostos/impostoIndustrial', element: ImpostoIndustrialPage},
  { path: '/fiscalidade/declaracoesFiscais', element: DeclaracoesFiscaisPage},
  { path: '/fiscalidade/declaracoesFiscais/declaracaoAnualImpostoIndustrial', element: DeclaracaoAnualImpostoIndustrialPage},
  { path: '/fiscalidade/declaracoesFiscais/outrasDeclaracoesFiscais', element: OutrasDeclaracoesFiscaisPage},
  { path: '/fiscalidade/declaracoesFiscais/declaracoesMensais', element: DeclaracoesMensaisPage },
  { path: '/fiscalidade/dossierFiscal', element: DoddierFiscalPage },
  { path: '/fiscalidade/dossierFiscal/dossierContabilistico', element: DossierContabilisticoPage },
  { path: '/fiscalidade/dossierFiscal/documentosLegais', element: DocumentosLegaisPage },
  { path: '/fiscalidade/dossierFiscal/documentosGestao', element: DocumentosGestaoPage },
  { path: '/fiscalidade/dossierFiscal/mapasIntenos', element: MapasIntenosPage },
  { path: '/fiscalidade/dossierFiscal/gestaoDossierFiscal', element: GestaoDossierFiscalPage },

  { path: '/fiscalizacoes/diagnosticosFiscal/obrigacoesDeclarativas', element: ObrigacoesDeclarativasPage},
  { path: '/fiscalizacoes/diagnosticosFiscal/obrigacoesContributivas', element: ObrigacoesContributivasPage},
  { path: '/fiscalizacoes/pedidoEsclarecimentos', element: PedidoEsclarecimentosPage},
  { path: '/fiscalizacoes/procedimentosFiscalizacao/documentoResposta', element: DocumentoRespostaPage},
  { path: '/fiscalizacoes/procedimentosFiscalizacao/ObjectosFiscalizacoes', element: ObjectosFiscalizacoesPage},
  { path: '/fiscalizacoes/execucaoFiscal', element: ExecucaoFiscalPage},
  { path: '/fiscalizacoes/gestaoProcessos', element: GestaoProcessosPage},
  { path: '/fiscalizacoes/garantiasEmbargo', element: GarantiasEmbargoPage},
  { path: '/fiscalizacoes/liquidacoesTributarias', element: LiquidacoesTributariasPage},

  { path: '/parametros/configuracaoEmpresa', element: ConfiguracaoEmpresaPage},
  { path: '/parametros/gestaoExercicios', element: ParmGestaoExerciciosPage},
  { path: '/parametros/liqgacaoExtesion', element: LiqgacaoExtesionPage},
  { path: '/parametros/fundamentosDossier', element: FundamentosDossierPage},
  { path: '/parametros/conformidades/balancetes', element: BalancetesPage},
  { path: '/parametros/conformidades/contabildiade', element: ContabildiadePage},
  { path: '/parametros/conformidades/relatorio', element: RelatorioPage},
  { path: '/parametros/automacoesNotasContas/automacoes', element: AutomacoesPage},
  { path: '/parametros/automacoesNotasContas/notasEspecializadas', element: AutomacoesEspecializadasPage},
  { path: '/parametros/automacoes/demonstracoesContabilisticas', element: DemonstracoesContabilisticasPage},
  { path: '/parametros/automacoes/relatorioFinanceiro', element: RelatorioFinanceiroPage},
  { path: '/parametros/automacoes/apuramentoImposto', element: ParmApuramentoImpostoPage},
  { path: '/parametros/automacoes/diagnosticosFiscais', element: DiagnosticosFiscaisPage},

  { path: '/administrador/empresa/criacao', element: CriacaoPage },
  { path: '/administrador/empresa/configuracao', element: ConfiguracaoPage },
  { path: '/administrador/configuracaoSistema/atualizacao', element: AdmAtualizacaoPage },
  { path: '/administrador/configuracaoSistema/licenciamento', element: ConfiguracoesSistemaPage },
  { path: '/administrador/controlo/perfis', element: PerfisPage },
  { path: '/administrador/controlo/acessos', element: AcessosPage },
  { path: '/administrador/controlo/utilizadores', element: UtilizadoresPage },
  { path: '/administrador/controlo/restricoes', element: RestricoesPage },
  { path: '/administrador/seguranca/backup', element: BackupPage },
  { path: '/administrador/seguranca/restore', element: RestorePage },
  { path: '/administrador/seguranca/manutencao', element: ManutencaoPage },
  { path: '/administrador/seguranca/servicos', element: ServicosPage },
  { path: '/administrador/tabelas', element: TabelasPage },

  { path: '/portalSuporte', element: PortalSuportePage },
  { path: '/administrador/comunicacoes', element: ComunicacoesPage },
  { path: '/analiseEmpresarial/bussinesAnalysis', element: BussinesAnalysisPage },
  { path: '/analiseEmpresarial/balancedScorcard', element: BalancedScorcardPage },
 
  { path: '/page4040', element: Page404 },
  { path: '/page4041', element: Page404 },
  { path: '/page4042', element: Page404 },
  { path: '/page4043', element: Page404 },

  {path: '/relatorioContas/balancetes', element: Balancetes },
  {path: '/guiaFormacao', element: GuiaFormacaoPage }

];

export default routes.map(route => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path)
  };
});
