import React from 'react';
import { CardModulos } from "../../components/CardModulos";
import './styles.scss';
//import imgLogoMaster from '../../../assets/img/1200px-HP_logo_2012.svg.png'

export function Home() {
  return (
    
    <React.Fragment>
      {/* <h2 className={'content-block'}></h2> */}
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <div className={'logos-container'}>
            <img src="https://portal.mastercontas.com/img/logo-login_1.png" alt="MasterContas" />
          </div> */}
          <div className={'conteiner-card'}>
            <CardModulos moduloName='Evento Principal' icon='pulldown' color='#04ccf8' root='eventoPrincipal'/> 
            <CardModulos moduloName='Contabilidade' icon='chart' color='#04ccf8' root='contabilidade'/> 
            <CardModulos moduloName='Relatório & Contas' icon='textdocument' color='#04ccf8' root='relatorioContas'/> 
            <CardModulos moduloName='Reporting' icon='print' color='#01b4ef' root='reporting'/> 
            <CardModulos moduloName='Controller' icon='box' color='#01b4ef' root='controller'/> 
            <CardModulos moduloName='Analise Empresarial' icon='columnproperties' color='#01b4ef' root='analiseEmpresarial'/> 
            <CardModulos moduloName='Fiscalidade' icon='equal' color='#048dde' root='fiscalidade'/> 
            <CardModulos moduloName='Fiscalizações' icon='search' color='#048dde' root='fiscalizacoes'/> 
            <CardModulos moduloName='Gerenciamento' icon='toolbox' color='#048dde' root='gerenciamento'/> 
            <CardModulos moduloName='Sistema' icon='verticalaligntop' color='#1e74bd' root='sistema'/> 
            <CardModulos moduloName='Consolidação de Contas' icon='selectall' color='#1e74bd' root='consolidacaoContas'/> 
            <CardModulos moduloName='Auditoria Revisão Contas' icon='bookmark' color='#1e74bd' root='auditoriaRevisaoContas'/> 
            <CardModulos moduloName='Projectos' icon='mediumiconslayout' color='#205194' root='projectos'/> 
            <CardModulos moduloName='Parâmetros' icon='box' color='#205194' root='parametros'/> 
            <CardModulos moduloName='Administrador' icon='preferences' color='#205194' root='administrador'/> 
            <CardModulos moduloName='Portal de Suporte' icon='verticalaligntop' color='#223a7c' root='portalSuporte'/> 
          </div> 
        </div>
      </div>
    </React.Fragment>
  ) 
}
