import React from "react"; 

export function Balancetes() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Balancetes</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            Para testificação das conformidades dos balancetes o sistema
            mastercontas utiliza a regra da conformidade da natureza da conta, e
            do saldo final.
          </p>
          <p>
            Aquando da criação da base de dados, o utilizador terá acessos
            parâmetros de conformidades “customizações 1 e 2” de contas padrão:
            PGG e SAP.
          </p>
          <p>
            Os parâmetros de conformidade padrão são passiveis de edição,
            anulação e reflecção em outras empresas criadas no sistema.
          </p>
          <p>
            Isso significa que o usuário pode personalizar o parâmetro da
            conformidade de contas de acordo com sua própria realidade e
            necessidades específicas, utilizando os parâmetros padrão como base
            ou desenvolver novas customizações.
          </p>
          <p><strong>Como aceder os parâmetros das conformidades dos balancetes?</strong></p>
          <ol>
            <li>Aceder ao Administrador;</li>
            <li>Aceder ao Parâmetros;</li>
            <li>Clicar em conformidades dos balancetes;</li>
          </ol>
          <p><strong>Como criar um novo parâmetro de conformidades dos balancetes?</strong></p>
          <ol>
            <li>
              Clicar a direita do rato e escolher a opção <b>“Adicionar conformidade conta”</b>
            </li>
            <li>Introduzir a conta</li>
            <li>Definir a natureza da conta</li>
            <li>Definir a conformidade da natureza</li>
            <li>Definir a conformidade do Saldo</li>
            <li>Adicionar o primeiro valor da primeira condição</li>
            <li>Adicionar a primeira regra condicional</li>
            <li>Adicionar o segundo valor da primeira condição</li>
            <li>Adicionar o primeiro valor da segunda condição</li>
            <li>Adicionar a segunda regra condicional</li>
            <li>Adicionar o segundo valor da segunda condição</li>
          </ol>
          <p>
            Para editar uma regra – O utilizador deve fazer duplo click na conta
            pretendida e seguir os passos acima.
          </p>
          <p>
            <b>Para remover uma conta:</b> O utilizador deve clicar a direita do rato e
            escolher a opção <b>“Remover conformidade conta”</b>
          </p>
          <p><b>Os parâmetros foram desenvolvidos com a mascara padrão:</b></p>
          <ol>
            <li>(Balancete + Tipo do Balancete + Tipo do Valor + Conta)</li>
            <li>Exemplo: BT-FIN-VD-31121</li>
          </ol>
          <p>Acrónimos das descrições dos Balancetes:</p>
          <ol>
            <li>FIN – Final</li>
            <li>REG – Movimentos do período.</li>
            <li>RAN – Movimentos do período anterior</li>
            <li>INC – Final do período anterior</li>
          </ol>
          
 
  <p>Acrónimos das descrições Valores:</p>
  <ol>
    <li>SC – Saldo Credor</li>
    <li>SD – Saldo Devedor</li>
    <li>VD – Valor Movimento Devedor</li>
    <li>VC – Valor do Movimento Credor</li>
    <li>MD – Valor Movimento Devedor</li>
    <li>MC – Valor Movimento Credor</li>
  </ol>
  <p>Regras Condicionais:</p>
  <ol>
    <li><b>{"<"} – Menor</b></li>
    <li><b>{">"} – Maior</b></li>
    <li><b>= – Igual</b></li>
    <li><b>{"<"} = – Menor ou Igual</b></li>
    <li><b>{">"}= – Maior ou Igual</b></li>
  </ol>
  <p>Exemplos para a formação de um parâmetro de conformidade do balancete:</p>
  <ul>
    <li>A conta 115 deve ser de natureza devedor, e o saldo a débito no balancete final deve ser maior ou igual da conta de amortização acumulada 1815.</li>
  </ul>
  <p>Parâmetro:</p>
  <p>Os parâmetros utilizados para a testificação das conformidades dos balancetes devem estar alinhados com as normas e princípios contabilísticos vigentes. Além disso, as alterações feitas nos parâmetros são refletidas automaticamente em outras empresas o que agiliza e facilita o processo da elaboração do relatório.</p>
 
</div> 
</div>

    </React.Fragment>
  );
}
