import React from 'react';
import './styles.scss';
import { Lista } from '../../../../components/Lista';

export function PlaneamentoLiquidacoes() {

  const items = [
    [
      { text: 'Aceder módulo fiscalidade ' },
      { text: 'Sessão Planeamento obrigações' },
      { text: 'Clicar em Planeamento Obrigações ' }
    ],
    [
      { text: 'Após aceder o formulário, clicar em “Novo”' },
      { text: 'Preencher o formulário “editor” do formulário central' },
      { text: 'Clicar em gravar ' }
    ],
    [
      { text: 'Após aceder o formulário' },
      { text: 'Efetuar duplo clique na alínea que pretende editar ' },
      { text: 'Editar as informações o formulário “editor” do formulário central.' },
      { text: 'Clicar em gravar.  ' }
    ],
    [
      { text: 'Após aceder o formulário' },
      { text: 'Efetuar duplo clique na alínea que pretende remover' },
      { text: 'Clicar em remover ' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Planeamento Liquidações</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>Através dessa funcionalidade, as empresas podem ter uma visão abrangente das suas dívidas fiscais pendentes, permitindo uma análise detalhada da situação financeira / fiscal e do cronograma de pagamento, proporcionando uma visão abrangente das suas dívidas fiscais pendente.</p>
          <p><strong>1 - Como aceder ao editor Planeamento Liquidações? </strong></p>
          <Lista isOrdered={true} items={items[0]}/>
          <p><strong>2 - Como adicionar uma nova divida fiscal? </strong></p>
          <Lista isOrdered={true} items={items[1]}/>
          <p><strong>3 - Como editar a divisa fiscal? </strong></p>
          <Lista isOrdered={true} items={items[2]}/>
          <p><strong>4 - Como remover a divida fiscal? </strong></p>
          <Lista isOrdered={true} items={items[3]}/>
          <p>A funcionalidade de adicionar anexos permite que o usuário faça upload de arquivos em diferentes formatos, como PDF, documentos do Word, imagens, entre outros, que comprovem o cumprimento fiscais ou forneçam informações adicionais necessárias.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
