import React from "react";
import { Lista } from "../../../../components/Lista";
import './styles.scss';

const items = [
  [
    { pointer: '•	GRAVAR', text: ' - Informações preenchidas.' },
    { pointer: '•	IMPRIMIR', text: ' - Funcionalidade de Visualização.' },
    { pointer: '•	CONSULTA', text: ' - Visualização / do preenchimento dos valores de forma manual e automatizada bem como os parâmetros de preenchimento das notas.' },
    { pointer: '•	PROCESSAMENTO', text: ' - Efetua o preenchimento dos dados dos formulários com as informações provenientes das Notas às Contas e da Demonstração de Resultados do Exercício.' },
    { pointer: '•	ANULAÇÃO', text: ' - Eliminação do preenchimento dos dados do formulário.' },
    { pointer: '•	IDIOMA', text: ' - Visualização da nota em Linguagem Estrangeiras como EN - Inglês, FR - Francês.' },
    { pointer: '•	MOEDA', text: ' - Visualização da nota em outras Moedas como USD- Dólar, EUR - Euro.' },
    { pointer: '•	UNIDADES', text: ' - Visualização da grandeza de valores como Milhões, Dezenas de Milhares, Centenas de Milhares, Milhões, Mil Milhões.' },
    { pointer: '•	CONTEXTO', text: ' - Permite a importação e a exportação do Template.' }, 
  ]
];

export function DRENaturezaFuncoes() {

  function GetTitleList() {
    return (
      <>
        <strong>Funcionalidades do formulário:</strong>
        <Lista isOrdered={false} items={items[0]} />
      </>
    )
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>DRE - Natureza / Funções</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>

          <p className="sub-title">DRE - NATUREZA</p>
          <p><strong>Demonstração do Resultado do Exercício por Natureza</strong> (DRE): Apresenta as receitas, custos e despesas da empresa durante um período, resultando no lucro ou prejuízo.</p>
          <p>No sistema Mastercontas, o preenchimento da DRE por natureza pode ser realizado de forma manual ou de forma automatizada, dependendo das preferências e necessidades do utilizador.</p>
          <p><strong>1 - Preenchimento Manual:</strong> É realizado através da inserção manual dos valores diretamente nos campos do formulário. </p>
          <p><strong>2 – Preenchimento Automatizado:</strong> Este evento permite a inserção dos valores de forma automatizada proveniente das <span className="sub-title">Notas às Contas</span> e do <span className="sub-title">apuramento do resultado</span>.</p>
          <p>O preenchimento automatizado poderá ser executado pelo <span className="sub-title">Evento Principal</span>, Evento do Formulários “Processamento”.</p>
          { GetTitleList() }
          <hr />

          <p className="sub-title">DRE - FUNÇÕES</p>
          <p><strong>Demonstração do Resultado do Exercício por Funções</strong> (DRE): Apresenta as receitas, custos e despesas da empresa durante um período, resultando no lucro ou prejuízo pelas sessões.</p>
          <p>No sistema Mastercontas, o preenchimento da DRE por funções pode ser realizado de forma manual ou de forma automatizada, dependendo das preferências e necessidades do utilizador.</p>
          <p>1 - Preenchimento Manual: É realizado através da inserção manual dos valores diretamente nos campos do formulário. </p>
          <p>2 – Preenchimento Automatizado: Este evento permite a inserção dos valores de forma automatizada proveniente dos parâmetros das demonstrações de resultado por funções.</p>
          <p>O preenchimento automatizado poderá ser executado pelo Evento Principal, Evento Parcial, Evento do Formulários “Processamento”</p>
          {GetTitleList()}

        </div>
      </div>
    </React.Fragment>
  );
}
