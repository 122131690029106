import React from "react"; 

export function FundamentosDossier() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Fundamentos do Dossier</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            Os parâmetros dos fundamentos do dossier são textos técnicos padrões
            que dão suporte de base legal para a preparação do dossier fiscal.
            Esses textos padrões ajudam o utilizador na compreensão técnica da
            elaboração do dossier fiscal, bem como na elaboração das alegações
            probatórias nos processos de fiscalizações e auditorias.
          </p>
          <p><strong>
            Como acessar os parâmetros fiscais do fundamento dos dossiers
            fiscais?</strong>
          </p>
          <ol>
            <li>1 - Acessar o Administrador;</li>
            <li>2 - Acessar os Parâmetros;</li>
            <li>
              3 - Na sessão "PARÂMETROS FISCAIS", clicar em Fundamentos do Dossier;
            </li>
            <li>4 - Selecionar a disposição do dossier fiscal;</li>
            <li>5 - Selecionar a sessão de afetação;</li>
            <li>6 - Alterar o texto;</li>
            <li>7 - Clicar em Gravar.</li>
          </ol>
          <p>
            O dossier fiscal deve ser preparado com metodologia padronizada,
            tendo como objetivo atender os requisitos fiscais e regulatórios.
            Isso pode incluir a aderência a uma estrutura do dossier fiscal, a
            documentação de processos de negócios, a minimização de erros e
            inconsistências e a revisões regulares nas normas.
          </p>
          <p><strong>Sessões de afetações dos fundamentos do Dossier:</strong></p>
          <ol>
            <li>Normas Contabilísticas;</li>
            <li>Normas Fiscais;</li>
            <li>Normas Regulatórias;</li>
            <li>Normas Internas;</li>
            <li>Práticas Contabilísticas Comuns.</li>
          </ol>
          <p>
            1. Normas Contabilísticas: os princípios contabilísticos geralmente
            aceites (PCGA) fornecem orientação sobre como preparar as
            demonstrações financeiras de uma empresa. Eles também estabelecem
            requisitos de divulgação para informações financeiras e contábeis.
          </p>
          <p>
            2. Normas Fiscais: a legislação tributária estabelece as regras e
            regulamentos que as empresas devem seguir para cumprir suas
            obrigações fiscais. Isso inclui impostos, taxas e contribuições.
          </p>
          <p>
            3. Normas Regulatórias: as empresas podem ser regulamentadas por
            várias agências governamentais, como o IGAPE, BODIVA, ARSEG,
            Ministérios de Tutelas ou grupos de participações societárias que
            podem ter requisitos específicos de preparação de informações e
            outras obrigações.
          </p>
          <p>
            4. Normas Internas: as empresas muitas vezes têm políticas e
            procedimentos internos que orientam a maneira como elas conduzem
            seus negócios. Essas normas incluem políticas de contabilidade, de
            divulgação de informações financeiras, de gestão de riscos e de
            governança corporativa.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
