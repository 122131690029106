import React from 'react';
import { Lista } from '../../components/Lista';

export function Controller() {

  const items = [
    [
      { pointer:'•	Análise de custos: ', text: 'ajuda a identificar os custos de produção, distribuição e venda, bem como as despesas administrativas.'  },
      { pointer:'•	Análise de rentabilidade: ', text: 'ajuda a identificar a rentabilidade de cada produto ou serviço oferecido pela empresa.'  },
      { pointer:'•	Análise de margens: ', text: 'ajuda a identificar a margem de lucro de cada produto ou serviço.'  },
      { pointer:'•	Análise de orçamento: ', text: 'ajuda a comparar o orçamento planejado com os custos e despesas reais.'  },
      { pointer:'•	Análise de despesas: ', text: 'ajuda a identificar áreas que podem ser cortadas para reduzir custos e melhorar a rentabilidade.'  }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Controller</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O módulo de Controller foi desenvolvido para ajudar as empresas na identificação, alocação e análises dos custos e os resultados de forma analítica.</p>
          <p>Este módulo permite que as empresas acompanhem seus custos de produção, despesas administrativas, despesas com vendas, além de outras despesas e receitas, a fim de obter informações precisas sobre o desempenho financeiro e operacional da empresa.</p>
          <p>As informações coletadas são usadas para tomadas de decisão estratégicas, permitindo que a empresa identifique áreas que precisam de melhoria e planeje investimentos futuros.</p>
          <p>Algumas das funcionalidades do módulo de Reporting são:</p>
          <Lista isOrdered={false} items={items[0]}/>
          <p>Com o módulo de Reporting as empresas podem acompanhar seus custos e despesas de forma mais precisa, identificando áreas que precisam de atenção e melhorando a sua eficiência operacional. Isso ajuda a empresa a tomar decisões estratégicas mais informadas e a melhorar sua rentabilidade geral.</p>
        </div>
      </div>
    </React.Fragment>
  )
}