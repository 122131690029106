import React from "react";
import "./styles.scss";
import { Lista } from "../../../components/Lista";

export function AnalisesContabilisticas() {

  const items = [
    [
      { text: 'Custos' },
      { text: 'Proveitos' },
      { text: 'Resultados' },
      { text: 'Ativos' },
      { text: 'Passivos' },
      { text: 'Capital' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Analises Contabilísticas</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2>ANÁLISES</h2>
          <p>
            A sessão Análises Contabilidade permite um exame da evolução
            histórica dos valores que compõem o patrimônio da empresa no período
            analisado. Essa análise é realizada de forma qualitativa e
            quantitativa no balanço da empresa, na demonstração de resultados e
            na demonstração dos fluxos de caixa.
          </p>
          <p>Formulários análises contabilísticas:</p>
          <Lista isOrdered={true} items={items[0]} />
          <p>
            Funcionalidades dos Mapas/Formulários de Análises Contabilísticas:
          </p>
          <ul>
            <li><strong>IMPRIMIR</strong> - Funcionalidade de Visualização;<p></p></li>
            <li>
              <strong>PROCESSAMENTO</strong> - Efetua o preenchimento dos dados dos formulários
              com automações com base nos parâmetros das demonstrações
              financeiras;<p></p>
            </li>
            <li>
              <strong>ANULAÇÃO</strong> - Eliminação do preenchimento dos dados dos formulários;<p></p>
            </li>
            <li>
              <strong>IDIOMA</strong> - Visualização nas linguagens EN - Inglês, FR - Francês;<p></p>
            </li>
            <li>
              <strong>ANÁLISE GRÁFICA </strong> - Exibição de Gráficos a preferência do
              utilizador;<p></p>
            </li>
            <li><strong>NOTA</strong> - Campo para introdução de texto explicativo; <p></p></li>
            <li>
              <strong>CONTEXTO</strong> - Funcionalidade que permite a importação e a exportação
              do Template.
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}
