import React from 'react';
import './styles.scss';
import { CardImage } from '../../components/CardImage';
import { Lista } from '../../components/Lista'; 
 
export function PortalSuporte() {

  const items = [
    [
      { text: 'Ticket de Suporte' },
      { text: 'Apoio ao cliente'  },
      { text: 'Atualizações no sistema' }
    ],
    [
      { text: 'Selecionar o serviço' },
      { text: 'Indicar o grau de Urgência' },
      { text: 'Adicionar uma descrição analítica sobre o serviço'  },
      { text: 'Opcionalmente poderá carregar um anexo para melhor evidência da solicitação'  },
      { text: 'Clicar em registar' }
    ], 
    [ 
      { text: 'Selecionar o serviço' },
      { text: 'Selecionar o assunto' },
      { text: 'Adicionar uma descrição analítica sobre o serviço'  },
      { text: 'Opcionalmente poderá carregar um anexo para melhor evidência da solicitação'  },
      { text: 'Clicar em registar'   }
    ],
    [ 
      { text: 'Clicar Gestão de Versão' },
      { text: 'Clicar em Atualização' },
      { text: 'Clicar em descarregamento'  },
      { text: 'Após download do ficheiro proceder instalação aceitando todos os parâmetros de instalação.'   }
    ],
    [ 
      { text: 'Aceder ao administrador;' },
      { text: 'Na sessão configuração clicar no ícone Atualização;' },
      { text: 'No formulário aberto clicar em baixar;'  },
      { text: 'Após o download proceder instalação aceitando todos os parâmetros de instalação.'   }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Portal de Suporte</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A gestão das atualizações, licenciamentos e comunicações com a equipa técnica da Mastercontas é realizada 100% on-line através do <a href="https://portal.mastercontas.com/">portal do cliente</a></p>

          <p>No portal do cliente, poderá encontrar os serviços: </p>

          <Lista isOrdered={false} isNumeric={true} items={items[0]}/>
          <br />
          <hr /><br />
          <strong>1.	Ticket de suporte:</strong>
          <p>Ticket de suporte é o registro de uma solicitação de atendimento gerado do portal com finalidade de Help Desk.</p>

          <p>Ao registar um ticket de suporte um número é atribuído, o que colabora para organizar e controlar de maneira mais otimizada os incidentes e soluções de personalizações.</p>

          <strong>Como registar um Ticket de suporte: </strong>
          <Lista isOrdered={false} items={items[1]}/>

          <CardImage filename='TiketSuporte.png' alt='Ticket suporte | abertura ' ></CardImage><br />
          <hr />
          <br />
          <strong>2.	Apoio ao cliente</strong>
          <p>O apoio ao cliente é o veículo de comunicação eletrónica que o subscritor dispõe para efetuar sugestões e reclamações sobre as funcionalidades do sistema. </p>
          <p>Estas informações são rececionadas direitamente pelo departamento de controlo de gestão e qualidade de sistemas, que oportunamente realiza um inquérito de averiguação dos factos reportados. </p>
          <strong>Como contactar o apoio ao cliente: </strong>

          <Lista isOrdered={false} items={items[2]}/>
          <br />
          <CardImage filename='ApoioCliente .png' alt='Serviço de apoio ao cliente | Cadastramento' ></CardImage><br />
          <hr />
          <br />

          <strong>3. Atualizações da aplicação </strong><br /><br />
          <strong>3.1. Atualização pelo portal</strong>
          <p>O sistema mastercontas possui varias modalidades de atualizações de acordo com a solução subscrita pelo cliente. </p>
          <p>Na gestão de Versão do portal.mastercontas.com encontrará sempre a atualização mais recente da aplicação, sendo que, para proceder atualização deve seguir os seguintes passos: </p>
         
          <Lista isOrdered={false} items={items[3]}/>

          <strong>3.2. Atualização direita da plataforma. </strong>
          <p>Para atualizar direitamente o sistema, deverá seguir os seguintes passos: </p>

          <Lista isOrdered={false} items={items[4]}/>
          <p><strong>⚠ Nota importante:</strong> para os clientes com a subscrição personalizada, as atualizações devem ser realizadas pela equipa técnica da mastercontas. </p>
         
        </div>
      </div>
    </React.Fragment>
  )
};


