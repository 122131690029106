import React from 'react'; 
// import { RequiredLogin } from '../../../../components/RequiredLogin';
import { Lista } from '../../../../components/Lista';
import './styles.scss';
 
export function Balanco() {

  const items = [
    [
      { pointer: '•	GRAVAR', text: ' - Informações preenchidas.'},
      { pointer: '•	IMPRIMIR', text: ' - Funcionalidade de Visualização.'},
      { pointer: '•	CONSULTA', text: ' - Visualização / do preenchimento dos valores de forma manual e automatizada bem como os parâmetros de preenchimento das notas.'},
      { pointer: '•	PROCESSAMENTO', text: ' - Efetua o preenchimento dos dados dos formulários com as informações provenientes das Notas às Contas e da Demonstração de Resultados do Exercício.'},
      { pointer: '•	ANULAÇÃO', text: ' - Eliminação do preenchimento dos dados do formulário.'},
      { pointer: '•	IDIOMA', text: ' - Visualização da nota em Linguagem Estrangeiras como EN - Inglês, FR - Francês.'},
      { pointer: '•	MOEDA', text: ' - Visualização da nota em outras Moedas como USD- Dólar, EUR - Euro.'},
      { pointer: '•	UNIDADES', text: ' - Visualização da grandeza de valores como Milhões, Dezenas de Milhares, Centenas de Milhares, Milhões, Mil Milhões.'},
      { pointer: '•	CONTEXTO', text: ' - Permite a importação e a exportação do Template.'},

    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Balanço Patrimonial</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <RequiredLogin/>  */}
          <p>O Balanço Patrimonial apresenta o ativo, passivo e patrimônio líquido da empresa em um determinado momento, refletindo sua posição financeira.</p>
          <p>No sistema Mastercontas, o preenchimento do Balanço Patrimonial pode ser realizado de forma manual ou de forma automatizada, dependendo das preferências e necessidades do utilizador.</p>
          <p> <strong>1 - Preenchimento Manual:</strong> É realizado através da inserção manual dos valores diretamente nos campos do formulário. </p>
          <p> <strong>2 – Preenchimento Automatizado:</strong> Este evento permite a inserção dos valores de forma automatizada proveniente das <span className='sub-title'>Notas às Contas</span> e da <span className='sub-title'>Demonstração de Resultados do Exercício</span>.</p>
          <p>O preenchimento automatizado poderá ser executado pelo <span className='sub-title'>Evento Principal</span>, Evento do Formulários “Processamento”.</p>
          <p><strong>Funcionalidades do formulário:</strong></p>
          <Lista isOrdered={false} items={items[0]} />
        </div>
      </div>
    </React.Fragment>
  )
};
