import React from 'react';

export function Parametros() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Parâmetros</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>Atendendo que cada empresa tem a sua própria realidade de especialização do código de contas, o sistema mastercontas foi desenvolvido com parametrização aberta, isto é, adaptável a qualquer sistema de contabilidade. </p>
        <p>Aquando da criação da empresa, o utilizador terá acessos a parâmetros padrões de conta nos sistemas de contas: PGCA, SAP, CONTIF. </p>
        <p>Os parâmetros padrão são passiveis de edição, anulação e reflecção em outras empresas criadas no sistema. Isso significa que o usuário pode personalizar o código de contas de acordo com sua própria realidade e necessidades específicas, utilizando os parâmetros padrão como base.</p>
        <p>Além disso, as alterações feitas nos parâmetros são refletidas automaticamente em outras empresas o que agiliza e facilita o processo da elaboração do relatório. </p>

        </div>
      </div>
    </React.Fragment>
  )
}
