import React from "react"; 
import { Lista } from "../../../components/Lista";
  
const items = [
  [
    { text: 'Submissão Eletrónica ' },
    { text: 'Gestão SAFT (AO) ' },
    { text: 'Transmissão Websever ' },
    { text: 'Master Files Operations ' } 
  ]
];
 
export function Comunicacoes() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Comunicações</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
        <p>A sessão comunicações do módulo administrador do sistema Mastercontas é uma importante funcionalidade que permite o gerenciamento das comunicações e submissões eletrônicas de documentos ou arquivos gerados no sistema. </p>
        <p>Nessa sessão, é possível configurar as ligações com as plataformas de submissão eletrônica, como a Administração Geral Tributária, permitindo o envio de informações fiscais e contabilísticas de forma automatizada e segura.</p>
        <p>A sessão comunicação efetua o gerenciamento das comunicações: </p>
        <Lista isOrdered={true} items={items[0]} />
        </div>
      </div>
    </React.Fragment>
  );
}
