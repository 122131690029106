import React from 'react'; 

export function Projecto() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Projectos</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>O módulo Projetos foi desenvolvido para planejar, executar e monitorar projetos em uma organização. </p>
        <p>Embora o módulo de projetos possa ser usado para desenvolver planos de negócios e projetos de investimentos, sua função principal é gerenciar projetos em todas as fases, desde a concepção até a conclusão.</p>
        <p>Contem vários modelos de projetos para atender a diferentes necessidades de uma empresa. </p>
        <p>Esses modelos podem incluir projetos de expansão, reestruturação, investimentos, fusões e aquisições, cisões, entre outros.</p>
        <p>Cada modelo de projeto pode incluir um conjunto diferente de etapas e atividades, dependendo dos objetivos do projeto. Por exemplo, um projeto de expansão pode exigir pesquisa de mercado e planeamento de marketing para identificar novas oportunidades de negócios, enquanto um projeto de reestruturação pode exigir a análise e melhoria de processos internos para melhorar a eficiência operacional.</p>
        <p>Ao utilizar modelos de projetos, as empresas podem economizar tempo e recursos na elaboração de projetos, pois muitas das atividades e etapas necessárias para cada tipo de projeto já estão definidas. </p>
        <p>No entanto, é importante lembrar que cada projeto é único e pode exigir adaptações nos modelos para atender às necessidades específicas da empresa e do projeto em questão.</p>

        </div>
      </div>
    </React.Fragment>
  )
}
