import React from "react";
import { Lista } from '../../../../components/Lista';

const items = [
  [
    { text: 'Aceder Administrador  ' },
    { text: 'Clicar em configuração da empresa ' },
    { text: 'Clicar na alínea da empresa pretendida  ' },
    { text: 'Clicar em editar ' },
    { text: 'Proceder a edição pretendida nos dados da empresa  ' },
    { text: 'Após conclusão da edição clicar em gravar ' }
  ],
  [
    { text: 'Aceder Administrador  ' },
    { text: 'Clicar em configuração de empresa ' },
    { text: 'Clicar na alínea da empresa pretendida  ' },
    { text: 'Clicar em selecionar empresa ' },
    { text: 'O sistema irá encerrar para o utilizador e após o login o remeterá para a empresa selecionada.  ' }
  ],
  [
    { text: 'Aceder Gerenciamento ' },
    { text: 'Clicar em listagem de empresa ' },
    { text: 'Clicar na alínea da empresa pretendida  ' },
    { text: 'Clicar em selecionar empresa ' },
    { text: 'O sistema irá encerrar para o utilizador e após o login o remeterá para a empresa selecionada' }

  ]
]

export function Configuracao() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Configuração</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <strong>1. Como editar as informações de uma empresa? </strong>
          <Lista isOrdered={false} items={items[0]} />
          <strong>2. Como selecionar uma empresa</strong>
          <p>A seleção de uma empresa pode ocorrer no administrador ou no Gerenciamento.</p>
          <p><strong>2.1. Passos para selecionar a partir do administrador:</strong></p>
          <Lista isOrdered={false} items={items[1]} />
          <p><strong>2.2. Passos para selecionar a partir do gerenciamento:</strong></p>
          <Lista isOrdered={false} items={items[2]} />
          <p><strong>Notas sobre configurações da empresa: </strong> </p>
          <p>Por rotina de segurança das informações todas as operações realizadas de afetação a configuração da empresa são gravadas em um formulário de histórico, onde descrimina o tipo de operação realizada e o usuário que procedeu.  </p>
          <p>Essa medida de segurança visa garantir a integridade dos dados e informações da empresa, possibilitando uma análise detalhada de eventuais irregularidades ou inconformidades nas operações realizadas.  </p>
          <p>Além disso, essa documentação pode ser utilizada como prova em processos administrativos.  </p>

        </div>
      </div>
    </React.Fragment>
  );
}
