
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import React, { useState } from 'react';
import './styles.scss';

interface propscard {
    moduloName: string;
    root?: string;
    icon?: string;
    color?: string;
}

export function CardModulos(parm: propscard) {

    const [isLoadPanelVisible, setIsLoadPanelVisible] = useState(false);
    const img = require('../../assets/img/Group2cirecu.png');

    function handleHide() {
        setIsLoadPanelVisible(false);
    }

    function handleClick(path?: string): void {
        //* notify({ message: "Funcionalidade em desenvolvimento!", width: 300, shading: false }, "info", 1000);
        setIsLoadPanelVisible(true);
        setTimeout(() => goToPageByPath(path), 2000);
    }

    function goToPageByPath(path: string | undefined) {
        if (path)
            window.location.href = `#/${path}`;
    }

    return (
        <div className={'card-main'} style={{ backgroundColor: parm.color }} onClick={() => handleClick(parm.root)}>
            <a className={'text-main'} href={`#/${parm.root}`}>
                {parm.moduloName}
            </a>
            <div className={'div-design'}>
                <img className='img-circle' src={img} alt="Circulo" />
                <Button
                    className={'btn'}
                    icon={parm.icon}
                    onClick={() => handleClick(parm.root)}
                />
            </div>
            <LoadPanel 
                message='Processando...' 
                shadingColor="rgba(0,0,0,0.4)"
                shading={true}
                position={'center'}
                hideOnOutsideClick={true} 
                showIndicator={true}
                showPane={true}
                visible={isLoadPanelVisible}
                onHidden={handleHide}
            />
        </div>
    );
}


