import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';

export default function Content() {
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='*'
          element={<Navigate to='/home' />}
        />
      </Routes>
      <Footer>
        <p className='parag'>
          Copyright © 2022-{new Date().getFullYear()} {appInfo.company} Inc.
          <br />
          Todas as marcas comerciais ou marcas registradas são de propriedade de seus respectivos proprietários.
          <br />
          Desenvolvido por <a href="https://www.linkedin.com/company/codinalta/" target="_blank" rel="noopener noreferrer">Codinalta</a>   
        </p> 
      </Footer>
    </SideNavBarLayout>
  );
}

