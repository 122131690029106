import React from 'react';
import './styles.scss';
import { Lista } from '../../../../components/Lista';

export function OutrasDeclaracoesFiscais() {

  const items = [
    [ 
      { text:'O editor procede a abertura da declaração fiscal e o respetivo anexo. ' },
      { text:'As informações genéricas das declarações são preenchidas automaticamente com as informações de castramento da empresa. ' },
      { text:'As informações Anexos são passiveis de adicionar e remover alíneas e podem ser agregada no ato de impressão da declaração fiscal. ' }
    ],
    [
      { pointer:'•	Declaração do Imposto Sobre Aplicação de Capitais (IAC):', text: 'É a declaração utilizada pelas empresas para reportar os rendimentos obtidos/pagos através da aplicação de capitais. ' },
      { pointer:'•	Declaração do Imposto Predial (IP):', text: 'É uma declaração anual apresentada pelos proprietários de imóveis urbanos. Essa declaração informa o valor patrimonial dos imóveis e calcula o imposto predial devido.' },
      { pointer:'•	Declaração Anual Imposto Selo (IS):', text: 'É a declaração utilizada para reportar as transações de compra e venda sujeitas ao Imposto de selo, permitindo o cálculo do imposto devido. ' },
      { pointer:'•	Declaração de Rendimentos de Trabalho (Modelo 2 IRT):', text: 'É uma declaração anual apresentada pelos indivíduos que obtêm rendimentos do trabalho por conta de outrem. Essa declaração informa os rendimentos auferidos e permite o cálculo do Imposto sobre o Rendimento do Trabalho' },
      { pointer:'•	Declaração Provisória:', text: 'É uma declaração utilizada para reportar informações sobre as vendas realizadas no primeiro semestre do exercício económico para o apuramento do imposto industrial provisório. ' },
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Outras Declarações Fiscais</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>As declarações fiscais são documentos exigidos pelas autoridades fiscais que visam relatar as informações financeiras e fiscais de uma empresa ou indivíduo.</p>
          <p>Essas declarações são utilizadas para calcular e pagar os impostos devidos, bem como cumprir as obrigações legais relacionadas à área fiscal.</p>
          <p>O preenchimento das declarações fiscais apresentam características homogéneas de elaboração</p>
          <Lista isNumeric={true} items={items[0]}/>
          <p><strong>Outras declarações Fiscais disponíveis no sistema:</strong></p>
          <Lista isOrdered={false} items={items[1]}/> 
        </div>
      </div>
    </React.Fragment>
  )
}
