import React from 'react'; 
import { Lista } from '../../../../components/Lista';

const items = [
  [
    { pointer:'💠	Tema:', text: 'Essa seção permite o utilizador escolher o layout do sistema de acordo com suas preferências.'},
    { pointer:'💠	Atualização:', text: 'Esta sessão permite o utilizador consultar a versão em execução no sistema. '},
    { pointer:'💠	Configurações “Geral”:', text: 'Essa seção abrange configurações de conectividade com a base de dados ou instância de trabalho. '}, 
  ],
  [
    { text: 'Aceder administrador ' },
    { text: 'Configuração' },
    { text: 'Clicar em configurações sistema ' },
    { text: 'Selecionar a aba tema ' },
    { text: 'Escolher o tema pretendido ' },
    { text: 'Clicar em aplicar ' }, 
  ],
  [
    { text: 'Aceder administrador ' },
    { text: 'Configuração' },
    { text: 'Clicar em configurações sistema ' },
    { text: 'Selecionar atualização ' }
  ],
  [
    { text:	'Inserir nome do servidor ' },
    { text:	'Inserir nome usuário ' },
    { text:	'Inserir palavra passe' }
  ],
  [
    { text: 'Ativar a opção posto '},
    { text: 'Inserir as referências da porta'}, 
  ],
  [
    { text: 'Esta sessão permite o usuário reportar todos os incidentes registados no sistema ' },
  ],
  [
    { text: 'Esta sessão permite repor configurações, repor tema, apagar dados do reporte, apagar a seleção da empresa. ' },
  ]
]

export function ConfiguracoesSistema() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Configurações Sistema</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}> 
        <p>Formulário de configurações do Sistema permite aos usuários personalizar e ajustar as configurações de acordo com suas preferências.</p>
        <Lista isOrdered={false} items={items[0]} />
        <strong>1.	Como alterar tema?</strong>
        <Lista isOrdered={true} items={items[1]} />
        <strong>2.	Como consultar a versão em execução?</strong>
        <Lista isOrdered={true} items={items[2]} />
        <strong>3.	Configurações geral?</strong>
        <p><strong>💠 Servidor</strong></p>
        <Lista isOrdered={true} items={items[3]} />
        <p><strong>Caso pretenda trabalhar como posto:</strong></p>
        <Lista isOrdered={true} items={items[4]} />
        <p><strong>💠 Reporte</strong></p>
        <Lista isOrdered={true} items={items[5]} />
        <p><strong>💠 Repor</strong></p>
        <Lista isOrdered={true} items={items[6]} />

        </div>
      </div>
    </React.Fragment>
  )
}
