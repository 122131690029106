import React from 'react'; 
import { RequiredLogin } from '../../../../components/RequiredLogin';
import { Lista } from '../../../../components/Lista';
 

const items = [
  [
    { pointer: '•	GRAVAR', text: ' - Informações preenchidas.' },
    { pointer: '•	IMPRIMIR', text: ' - Funcionalidade de Visualização.' },
    { pointer: '•	CONSULTA', text: ' - Visualização / do preenchimento dos valores de forma manual e automatizada bem como os parâmetros de preenchimento das notas.' },
    { pointer: '•	PROCESSAMENTO', text: ' - Efetua o preenchimento dos dados dos formulários com as informações provenientes das Notas às Contas e da Demonstração de Resultados do Exercício.' },
    { pointer: '•	ANULAÇÃO', text: ' - Eliminação do preenchimento dos dados do formulário.' },
    { pointer: '•	IDIOMA', text: ' - Visualização da nota em Linguagem Estrangeiras como EN - Inglês, FR - Francês.' },
    { pointer: '•	MOEDA', text: ' - Visualização da nota em outras Moedas como USD- Dólar, EUR - Euro.' },
    { pointer: '•	UNIDADES', text: ' - Visualização da grandeza de valores como Milhões, Dezenas de Milhares, Centenas de Milhares, Milhões, Mil Milhões.' },
    { pointer: '•	CONTEXTO', text: ' - Permite a importação e a exportação do Template.' },

  ]
]

export function DemonstracoesFluxoCaixa() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Demonstrações Fluxo de Caixa</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <RequiredLogin/>  */}
          <p>Demonstração do Fluxo de Caixa: Apresenta as entradas e saídas de dinheiro da empresa ao longo de um período, fornecendo informações sobre a liquidez e as atividades operacionais, de investimento e de financiamento.</p>
          <p>No sistema Mastercontas, o preenchimento das DFCs podem ser realizados de forma manual ou de forma automatizada, dependendo das preferências e necessidades do utilizador.</p>
          <p>1 - Preenchimento Manual: É realizado através da inserção manual dos valores diretamente nos campos do formulário. </p>
          <p>2 – Preenchimento Automatizado: Este evento permite a inserção dos valores de forma automatizada proveniente dos parâmetros das demonstrações fluxo de caixa.</p>
          <p>O preenchimento automatizado poderá ser executado pelo Evento Principal, Evento Parcial, Evento do Formulários “Processamento”</p>

          <strong>Funcionalidades do formulário:</strong>
          <Lista isOrdered={false} items={items[0]} />
        </div>
      </div>
    </React.Fragment>
  )
};
