import React from 'react'; 
import { Lista } from '../../../../components/Lista';

export function Backup() {

  const items = [
    [
      { pointer:'1. Backup completo (Full Backup): ', text: 'É o tipo mais básico de backup e envolve a cópia de todos os dados e objetos do banco de dados. Ele cria um ponto de restauração completo do banco de dados, permitindo a sua recuperação em sua totalidade.' },
      { pointer:'2. Backup diferencial (Differential Backup): ', text: 'Esse tipo de backup captura somente as alterações feitas desde o último backup completo. Ele armazena apenas os dados modificados desde o último backup completo, o que geralmente resulta em um tempo de backup mais curto e o tamanho do ficheiro consideravelmente menor. No entanto, a restauração envolve a combinação do último backup completo com o backup diferencial mais recente.' }
    ],
    [
      { text: 'Abra o formulario "Administrador" '},
      { text: 'Selecione a aba "Adminstrador" '},
      { text: 'No grupo denominado "Segurança" '},
      { text: 'Clique em "Backup" '},
      { text: 'Selecione a base de dados que pretende efectuar o backup (pode selecionar mais de uma base de dados)'},
      { text: 'Define o tipo de backup'},
      { text: 'Clique em "Efectuar backup"'}
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Backup</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>Backup é um aspecto crítico no gerenciamento de dados e é essencial para garantir a integridade e disponibilidade das informações em caso de falhas, erros ou desastres. O backup envolve a cópia dos dados de um sistema para um local seguro, permitindo a sua recuperação posterior.</p>
          <p>No contexto do <strong>Master Contas</strong>, o backup é usado para proteger os bancos de dados e os objetos que eles contêm. Existem diferentes tipos de backup que podem ser realizados:</p>
          <Lista isOrdered={false} isNumeric={true} items={items[0]}/>
          <p>É importante definir uma estratégia adequada de backup, considerando a importância dos dados e a tolerância à perda de informações</p>
          <p>O sistema Master Contas oferece ferramentas para realizar e automatizar os backups, como formulario de BACKUP, que podemos encontrar em:.</p>
          <Lista isNumeric={false} items={items[1]}/>
          <p>Seguindo os passos a cima, o backup será efectuado com sucesso e o ficheiro ".bak" estará disponivel na pasta: c:/MR/Backup/MR+(Coodigo da empresa)/</p>
          <p>Em resumo, o backup no Master Contas é uma prática essencial para garantir a disponibilidade e a integridade dos dados. É importante desenvolver uma estratégia adequada de backup, considerando os tipos de backup, a frequência, o local de armazenamento e a realização de testes de recuperação.</p>
          </div>
      </div>
    </React.Fragment>
  )
}
