import React from 'react'; 
import { Lista } from '../../../components/Lista'; 

export function AnaliseDemonstracoes() {

  const items = [
    [
      { text: 'Balanço ' },
      { text: 'Demonstração de Resultados por Natureza ' },
      { text: 'Demonstração Fluxo de Caixa - Método Direito  ' }
    ],
    [
      { text: 'Variação da comparação de dois períodos ' },
      { text: 'A evolução percentual da variação ' },
      { text: 'Análise da evolução  ' }
    ],
    [
      { pointer: '•	GRAVAR ', text: '- informações preenchidas' },
      { pointer: '•	IMPRIMIR ', text: '- Funcionalidade de Visualização' },
      { pointer: '•	NOTAS ', text: '- Texto explicativo.' },
      { pointer: '•	DADOS ', text: '- Visualização / do preenchimento dos valores de forma manual e automatizada.' },
      { pointer: '•	PROCESSAMENTO ', text: '- Efetua o preenchimento dos dados no formulários com base na informação da respetiva DFs.   ' },
      { pointer: '•	ANULAÇÃO ', text: '- Eliminação do preenchimento dos dados dos formulários com automações.' },
      { pointer: '•	IDIOMA ', text: '- Visualização da nota em Linguagem Estrangeiras como EN - Inglês, FR - Francês.' },
      { pointer: '•	MOEDA ', text: '- Visualização da nota em outras Moedas como USD - Dólar, EUR - Euro' },
      { pointer: '•	CONTEXTO ', text: '- Permite a importação e a exportação do Template' },
      { pointer: '•	UNIDADES ', text: '- Visualização da grandeza de valores como Milhões, Dezenas de Milhares, Centenas de Milhares, Milhões, Mil Milhões.' }
    ]
  ];
  const items1 = [
    [
      { text: 'Balanço ' },
      { text: 'Demonstração de Resultados por Natureza ' },
      { text: 'Demonstração Fluxo de Caixa - Método Direito  ' }
    ],
    [
      { text: 'Variação da comparação de dois períodos ' },
      { text: 'A evolução percentual da variação ' },
      { text: 'Análise da evolução  ' }
    ],
    [
      { pointer: '•	GRAVAR ', text: '- informações preenchidas' },
      { pointer: '•	IMPRIMIR ', text: '- Funcionalidade de Visualização' },
      { pointer: '•	NOTAS ', text: '- Texto explicativo.' },
      { pointer: '•	DADOS ', text: '- Visualização / do preenchimento dos valores de forma manual e automatizada.' },
      { pointer: '•	PROCESSAMENTO ', text: '- Efetua o preenchimento dos dados no formulários com base na informação da respetiva DFs.   ' },
      { pointer: '•	ANULAÇÃO ', text: '- Eliminação do preenchimento dos dados dos formulários com automações.' },
      { pointer: '•	IDIOMA ', text: '- Visualização da nota em Linguagem Estrangeiras como EN - Inglês, FR - Francês.' },
      { pointer: '•	MOEDA ', text: '- Visualização da nota em outras Moedas como USD - Dólar, EUR - Euro' },
      { pointer: '•	CONTEXTO ', text: '- Permite a importação e a exportação do Template' },
      { pointer: '•	UNIDADES ', text: '- Visualização da grandeza de valores como Milhões, Dezenas de Milhares, Centenas de Milhares, Milhões, Mil Milhões.' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Analise Demonstrações</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>A análise das demonstrações financeiras é foi desenvolvida para interpretar e avaliar a situação financeira e econômica de uma empresa. </p>
        <p>Esta análise é feita de forma automática com base nas informações apresentadas nas demonstrações contabilísticas do relatório e contas. </p>
        <strong> A análise é realizada nas três principais demonstrações financeiras: </strong>
        <Lista isOrdered={true} isNumeric={false} items={items1[0]} />
        <p>Através dessa análise é possível identificar: </p>
        <Lista isOrdered={true} isNumeric={false} items={items1[1]} />
        <p>Funcionalidades dos formulários das análises das demonstrações financeiras:</p>
        <Lista isOrdered={false} items={items1[2]} />
        </div>
      </div>
    </React.Fragment>
  )
}
