import React from 'react';
import { Lista } from '../../../../components/Lista';

const items = [
  [
    { pointer: '•	1.º Dígito - Versão principal (X.0.0.0):', text: 'O primeiro dígito é usado para indicar grandes lançamentos ou atualizações. Mudanças nesse dígito significa alterações significativas na funcionalidade, recursos ou na estrutura do sistema.' },
    { pointer: '•	2.º Dígito - Versões secundárias (0.X.0.0):', text: 'O segundo dígito é usado para indicar versões secundárias ou atualizações intermediárias com impactos na base de dados. A mudança nesse dígito inclui adição de recursos, correções de bugs e melhorias significativas.' },
    { pointer: '•	3.º Dígito - Versões de manutenção (0.0.X.0):', text: 'O terceiro dígito é usado para indicar versões de manutenção ou pequenas atualizações. Geralmente, isso inclui correções de bugs menores e ajustes no software.' },
    { pointer: '•	4.º Dígito - Revisões de correção (0.0.0.X):', text: 'O quarto dígito é usado para indicar revisões de correção ou correções de bugs menores e atualizações menores que não introduzem novos recursos.' }
  ],
  [
    { text: 'Aceder ao administrador' },
    { text: 'Configuração' },
    { text: 'Clicar em Atualização; ' },
    { text: 'Escolher Versão LPS ou Versão Beta' },
    { text: 'Clicar em Baixar' }
  ],
  [
    { text: 'Aceder ao portal.mastercontas.com' },
    { text: 'Clicar na aba "Gestão de Versão"' },
    { text: 'Clicar em Atualização ' },
    { text: 'Consultar as alterações e recomendações' },
    { text: 'Clicar na opção Descarregamento' },
    { text: 'Após conclusão do download, clicar no executável para início do processo de atualização.' },
    { text: 'O usuário deve seguir as etapas fornecidas para concluir a atualização.' } 
  ],
  [
    { text: 'A atualização só é realizada se a conexão de internet for estável. ' },
    { text: 'Antes da realização de qualquer atualização, é importante fazer um backup de seus dados importantes, caso algo dê errado durante o processo.' },
    { text: 'Mantenha o sistema atualizado para garantir o acesso aos recursos mais recentes e às correções de segurança.' },
    { text: 'Caso ocorra algum erro na atualização deve imediatamente contactar o suporte técnico da Mastercontas pelo portal.mastercontas.com ' }
  ]
]

export function Atualizacao() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Atualizações Sistema</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O sstema Mastercontas possui um ritmo constante de melhorias e desenvolvimento de novas funcionalidades para garantir que os usuários tenham acesso a recursos mais avançados e eficazes, bem como para manter o sistema em conformidade com as mudanças regulatórias.</p>
          <p><strong>1 - Como consultar a existência uma nova atualização?</strong></p>
          <p>Notificação de uma nova versão aparece no canto superior direito com a descrição "Atualização disponível". </p>
          <hr />

          <p><strong>2 - Codificação da Atualização</strong> </p>
          <p>O código de atualização possui um formato de quatro dígitos separados por pontos (X.X.X.X).</p>
          <p>Cada dígito desempenha um papel específico na identificação da versão do sistema.</p>
          <Lista isOrdered={false} items={items[0]} />
          <hr />

          <p><strong>3 – Como proceder a atualização?</strong></p>
          <p>O sistema oferece duas vias para atualizações, proporcionando flexibilidade aos usuários para escolher a melhor opção para suas necessidades.</p>
          <p><strong>A)	Atualização pelo Sistema:</strong></p>
          <Lista isOrdered={true} items={items[1]} />
          <p>O sistema fará o download da nova versão e iniciará o processo de instalação automaticamente.</p>
          <p>O usuário deve seguir as etapas fornecidas para concluir a atualização. </p>
          <p><strong>B)	Atualização pelo Portal:</strong></p>
          <Lista isOrdered={true} items={items[2]} />
          <hr />

          <p className='txt-italic'><strong>Recomendações: </strong></p>
          <Lista isOrdered={false} isNumeric={true} items={items[3]} />
        </div>
      </div>
    </React.Fragment>
  )
}
