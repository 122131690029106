import React from "react";
import "./styles.scss";
import { Lista } from "../../../../components/Lista";

export function Inportacao() {

  const items = [
    [
      { text: 'Formato XML – (Exportados dos Sistemas PRIMAVERA e SAP)'},
      { text: 'Formato XLS “EXCEL” (Exportados do SAP ou Template Mastercontas)'}
    ],
    [
      { text: 'Selecionar o tipo de balancete; '},
      { text: 'Clicar em importar; '},
      { text: 'Selecionar o ficheiro conforme definido no sistema contabilístico em gerenciamento do exercício;'},
      { text: 'Realizar o aploud; '},
      { text: 'Gravar'}
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Importações</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div>
            <p>
              <strong>Importação e atualização:</strong>
            </p>
            <p>
              A importação dos Balancetes devem obedecer a obrigatoriedade dos
              Formatos:
            </p>
            
            <Lista isOrdered={false} isNumeric={false} items={items[0]} />

            <p><strong>Como importar o balancete:</strong></p>

            <Lista isOrdered={false} isNumeric={true} items={items[1]} />

          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
