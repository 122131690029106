import React from "react";
import "./styles.scss";

export function EventosProcessamentos() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Eventos Processamentos</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>Os eventos de processamentos são rotinas preenchimentos, gravação, anulação e atualização dos formulários que integram o evento principal. </p>
 
              <p><strong className="def">Processar:</strong> Corresponde ao evento de gravação.</p>
             
              <p><strong className="def">Processar e Atualizar:</strong> Esta funcionalidade
              primeiramente elimina todas as informações constantes nos
              formulários e mapas que fazem parte do evento principal e de
              preenchimento manual, posteriormente, efetua o preenchimento dos
              dados com base nos parâmetros.</p>
             
              <p><strong className="def">Processar Automação:</strong> Esta funcionalidade
              primeiramente elimina todas as informações constantes nos
              formulários e mapas que fazem parte do evento principal,
              posteriormente, efetua o preenchimento dos dados dos formulários
              com automações com base nos parâmetros.</p>
             
              <p><strong className="def">Anular:</strong> Corresponde às funcionalidades do botão
              Desgravar a informação.</p>
             
              <p><strong className="def">Anular e Atualizar:</strong> Esta funcionalidade
              primeiramente elimina todas as informações constantes nos
              formulários e mapas que fazem do evento principal e de
              preenchimento manual, posteriormente, elimina o preenchimento dos
              dados com base nos parâmetros.</p>
             
              <p><strong className="def">Anular Automação:</strong> Esta funcionalidade
              primeiramente elimina todas as informações constantes nos
              formulários e mapas que fazem do evento principal, posteriormente,
              elimina o preenchimento dos dados com automações com base nos
              parâmetros.</p>
            
      
          <p>O objetivo final dos eventos de processamento é garantir que todas as tarefas necessárias para o evento principal sejam executadas com precisão e eficiência. Isso inclui garantir que os dados sejam corretamente capturados, validados, armazenados e atualizados conforme necessário.</p>
        </div>
      </div>
    </React.Fragment>
  );
}
