import React from 'react'; 
import { Lista } from '../../../components/Lista';

export function AnaliseIndicadores() {

  const items = [
    [
      { text: 'Rácios Financeiros ' },
      { text: 'Rácios Investimentos ' },
      { text: 'Rácicos Rotação ' },
      { text: 'Rácios de Atividades ' },
      { text: 'Rácios Rendibilidade ' },
      { text: 'Indicadores de Desempenho ' }
    ],
    [
      { pointer: '•	GRAVAR ', text: '- informações preenchidas' },
      { pointer: '•	IMPRIMIR ', text: '- Funcionalidade de Visualização' },
      { pointer: '•	COMENTÁRIO TÉCNICO ', text: '- Texto explicativo.' },
      { pointer: '•	DADOS ', text: '- Visualização / do preenchimento dos valores de forma manual e automatizada.' },
      { pointer: '•	PROCESSAMENTO ', text: '- Efetua o preenchimento dos dados no formulários com base na informação da respetiva DFs.   ' },
      { pointer: '•	ANULAÇÃO ', text: '- Eliminação do preenchimento dos dados dos formulários com automações.' },
      { pointer: '•	GRAFICO ', text: '- Visualização gráfica da evolução do indicador. ' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Analise dos Indicadores</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O sistema Mastercontas possui ferramentas para análise econômica e financeira das demonstrações financeiras com base em rácios financeiros. </p>
          <p>Os rácios permitem avaliar a saúde financeira da empresa e sua capacidade de gerar lucros e fluxos de caixa positivos.</p>
          <p> <strong>Os indicares/rácios desenvolvidos e que podem ser calculados e analisados no sistema são: </strong></p>
          <Lista isOrdered={true} items={items[0]} />
         <p> Os rácios são calculados de forma automática ático com base nas informações das demonstrações financeiras.</p>
         <p> Os rácios também podem ser calculados de forma manual, sendo responsabilidade do utilizador inserir os dados nos formulários.</p>

         <p><strong>Funcionalidades dos formulários de análises financeiras: </strong></p>
         <Lista isOrdered={false} items={items[1]} />

        </div>
      </div>
    </React.Fragment>
  )
}
