import React from 'react';
import { Lista } from '../../components/Lista';

export function AuditoriaRevisaoContas() {

  const items = [
    [
      { pointer: '1. Planeamento da auditoria e revisão: ', text: 'ajuda a definir o escopo do trabalho, os objetivos da auditoria e revisão, o cronograma do projeto e as necessidades de recursos.'},
      { pointer: '2. Gerenciamento da equipa de auditoria: ', text: 'ajuda a gerenciar a equipa de auditoria, incluindo a designação de responsabilidades e tarefas, a programação de horários de trabalho e a comunicação de informações relevantes para a equipe.'},
      { pointer: '3. Documentação de auditoria e revisão: ', text: 'permite documentar todas as atividades da auditoria e revisão, desde a coleta de informações até a preparação dos relatórios.'},
      { pointer: '4. Gerenciamento de relatórios: ', text: 'ajuda a gerar relatórios de auditoria e revisão e compartilhar essas informações com as partes interessadas relevantes.'},
      { pointer: '5. Monitoramento e análise dos resultados: ', text: 'ajuda a monitorar e analisar os resultados da auditoria e revisão para identificar áreas de melhoria e desenvolver planos de ação.'}
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Auditoria e Revisão Contas</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>O módulo de Auditoria e Revisão de Contas gerência todo o processo inspetivo nas contas de uma empresa, desde a programação da auditoria e revisão até a comunicação dos resultados. </p>
        <p>Algumas das funcionalidades do módulo são:</p>
        <Lista isOrdered={false} items={items[0]}></Lista>
        <p>Em resumo, o módulo de Auditoria e Revisão de Contas é uma ferramenta valiosa para ajudar as empresas a gerenciar o processo de auditoria e revisão de contas de forma eficaz, melhorando a precisão e a confiabilidade das informações contabilísticas e/ou financeiras.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
