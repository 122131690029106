import React from 'react';
import { Lista } from '../../components/Lista';

export function ConsolidacaoContas() {

  const items = [
    [
      { pointer: '•	Consolidação de dados financeiros: ', text: 'permite a consolidação automática de dados financeiros de várias empresas pertencentes ao mesmo grupo empresarial.', },
      { pointer: '•	Tratamento de ajustes de consolidação: ', text: 'permite que os contabilistas efetuem ajustes necessários na consolidação, como a eliminação de transações entre empresas do grupo' },
      { pointer: '•	Consolidação de demonstrações financeiras: ', text: 'permite a consolidação das demonstrações financeiras individuais de cada empresa pertencente ao grupo, como o balanço patrimonial, a demonstração de resultados e o fluxo de caixa.' },
      { pointer: '•	Controle e monitoramento de resultados: ', text: 'ajuda a controlar e monitorar os resultados da consolidação, permitindo que os contabilistas analisem a performance financeira do grupo.' },
      { pointer: '•	Geração de relatórios consolidados: ', text: 'ajuda a gerar relatórios consolidados para o grupo empresarial, permitindo a apresentação de demonstrações financeiras consolidadas aos acionistas e outros interessados.' },

    ]
  ]
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Consolidacão de Contas</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A consolidação de contas é um processo contabilístico que envolve a combinação das demonstrações financeiras de várias empresas pertencentes ao mesmo grupo empresarial, a fim de apresentar um conjunto de demonstrações financeiras consolidadas para o grupo.</p>
          <p>O módulo de consolidação de contas foi desenvolvido para ajudar as empresas a automatizarem os processos desta tarefa. Realizar as atividades de forma rápida e eficiente, reduzindo potencialmente riscos de erros, e significativamente o tempo necessário para elaborar a consolidação manualmente. </p>
          <p>Algumas das funcionalidades do módulo de consolidação de contas são:</p>
          <Lista isOrdered={false} items={items[0]} />

          <p>Em resumo, o sistema de consolidação de contas pode ajudar as empresas a realizar a consolidação de forma eficiente e eficaz, reduzindo o tempo e o esforço necessários para realizar a tarefa manualmente.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
