import React from "react"; 

export function Contabildiade() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Contabildiade</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            O sistema mastercontas foi desenvolvido de forma vertical para
            interagir com diversas soluções de sistemas de contabilidade, por
            via importação de ficheiros em diversos formatos, ou pela ligação
            extesion.
          </p>
          <p>
            A ligação extesion – corresponde a sincronização entre o banco de
            dados da aplicação mastercontas e o banco de dados onde o utilizador
            armazena os movimentos ou lançamentos contabilísticos. Esta ligação
            é estabelecida de forma segura e automática, permitindo ao
            utilizador ter acesso às informações dos Balancetes em tempo real,
            sem a necessidade de efetuar transferências ou upload de ficheiros
            manualmente.
          </p>
          <p>
            Para realizar a ligação extesion deve realizar duas operações,
            nomeadamente:
          </p>
          <ol>
            <li>Configuração Sever</li>
            <li>Ligação BD</li>
          </ol>
          <p>
            Configuração Server: Ligação entre dois bancos de dados do SQL Sever
          </p>
          <p>
            Ligação BD: Sincronização entre BDs Especificas, isto é, Empresa A
            na aplicação XPTO com a Empresa A na aplicação Mastercontas.
          </p>
          <p><strong>Como realizar a configuração Sever:</strong></p>
          <ol>
            <li>Aceder a administrador</li>
            <li>Aceder parâmetros</li>
            <li>Clicar no ícone Ligação extesion</li>
            <li>Clicar na aba configuração</li>
            <li>Adicionar o Sever mane</li>
            <li>Adicionar o a authentication do login</li>
            <li>Adicionar o a authentication da password</li>
          </ol>
          <p><strong>Como realizar a Ligação BD:</strong></p>
          <p>Apos a configuração sever, deve:</p>
          <ol>
            <li>No formulário da ligação extesion aceder a aba ligação.</li>
            <li>
              Selecionar a empresa no sistema Mastercontas que pretende fazer a
              ligação.
            </li>
            <li>Selecionar a empresa no Sistema de Extesion a ligar.</li>
            <li>Clicar na opção ligar.</li>
          </ol>
          <p>
            <b>Nota fundamental:</b> A eficácia da ligação depende da
            adoção de padrões comuns na identificação das empresas em cada uma
            das bases, isto é, o mesmo código de indicador da BD. Dessa forma, é
            importante que as empresas sejam registradas com os mesmos
            indicadores nas diferentes bases de dados, garantindo que o código
            interno atribuído a ela seja único e facilmente identificável em
            todas as fontes de informação.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
