import React from 'react';
import './styles.scss';
import { Lista } from '../../../../components/Lista';

export function PlaneamentoObrigacoes() {

  const items = [
    [
      { text: 'Aceder módulo fiscalidade ' },
      { text: 'Sessão Planeamento obrigações' },
      { text: 'Clicar em Planeamento Obrigações ' }
    ],
    [
      { text: 'Após aceder o formulário, clicar em “Novo”' },
      { text: 'Preencher o formulário “editor” do formulário central' },
      { text: 'Clicar em gravar ' }
    ],
    [
      { text: 'Após aceder o formulário' },
      { text: 'Efetuar duplo clique na alínea que pretende editar ' },
      { text: 'Editar as informações o formulário “editor” do formulário central.' },
      { text: 'Clicar em gravar.  ' }
    ],
    [
      { text: 'Após aceder o formulário' },
      { text: 'Efetuar duplo clique na alínea que pretende remover' },
      { text: 'Clicar em remover ' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Planeamento Obrigações</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A sessão <strong>Planeamento das obrigações</strong> foi desenvolvida para auxiliar os departamentos de contabilidade e fiscalidade das empresas a realizar análises, cálculos e projeções necessários no processo de planeamento fiscal.</p>
          <p>No início de um novo exercício fiscal, o sistema apresente uma listagem padrão das obrigações fiscais convencionais que as empresas devem preparar.</p>
          <p>Essa listagem pode incluir declarações de impostos, relatórios, pagamentos de impostos, entre outros.</p>
          <p>No entanto, é importante ressaltar que o usuário tem a flexibilidade de <strong>excluir, acrescentar</strong> e <strong>ajustar as obrigações fiscais</strong> de acordo com a realidade específica da sua empresa.</p>
          <p><strong>1 - Como aceder ao editor Planeamento Obrigações Fiscais?</strong></p>
          <Lista isOrdered={true} items={items[0]}/>
          <p><strong>2 - Como adicionar uma nova obrigação fiscal?</strong></p>
          <Lista isOrdered={true} items={items[1]}/>
          <p><strong>3 - Como editar uma obrigação fiscal? </strong></p>
          <Lista isOrdered={true} items={items[2]}/>
          <p><strong>4 - Como remover obrigação fiscal? </strong></p>
          <Lista isOrdered={true} items={items[3]}/>
        </div>
      </div>
    </React.Fragment>
  )
}
