import React from 'react';
import './styles.scss';

export function DeclaracoesFiscais() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Declarações Fiscais</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>As declarações fiscais são documentos exigidos pelas autoridades fiscais que visam relatar as informações financeiras e fiscais de uma empresa ou indivíduo.</p>
          <p>Essas declarações são utilizadas para calcular e pagar os impostos devidos, bem como cumprir as obrigações legais relacionadas à área fiscal.</p>
          <p>As declarações devem ser apresentadas às autoridades fiscais competentes de acordo com os prazos e procedimentos estabelecidos por lei.</p>
          <p>É importante ressaltar que as obrigações declarativas podem variar de acordo com a atividade da empresa, o regime tributário e outras circunstâncias específicas.</p>
          <p>O sistema fiscal angolano possui diversas declarações fiscais. Aceder ao link para mais informações visete o <a href="https://portaldocontribuinte.minfin.gov.ao/">Portal do Contribuinte</a></p>

        </div>
      </div>
    </React.Fragment>
  )
}
