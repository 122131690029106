import React from 'react'; 
import { Lista } from '../../../../components/Lista';
// import { RequiredLogin } from '../../../../components/RequiredLogin';


const items = [
  [
    { text: 'Administrador' },
    { text: 'Contabilista ' },
    { text: 'Técnico de Contas ' },
    { text: 'Auditor de Contas ' },
    { text: 'Fiscal de Contas ' },
    { text: 'Fiscal tributário ' },
    { text: 'Analista Financeiro ' },
    { text: 'Assistente Administração ' },
    { text: 'Assistente de Contabilidade ' }
  ]
]

export function Perfis() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Perfis</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <RequiredLogin/> */}

          <p>A aplicação dispões de diferentes tipos de perfis de usuários, cada um com um conjunto diferente de permissões e acessos ao sistema, de acordo com as necessidades e responsabilidades na empresa.</p>
          <p>Os perfis disponíveis no sistema são: </p>
          <Lista isOrdered={true} items={items[0]}/>

          <p>Esses perfis podem ser configurados de forma flexível, permitindo que a empresa personalize o acesso do usuário de acordo com suas responsabilidades. Por exemplo, um perfil de administrador pode ter acesso a todas as funcionalidades do sistema.</p>
          <p>Já um perfil de contabilista pode ter acesso apenas às funcionalidades relacionadas à contabilidade, como lançamentos, preenchimento das declarações fiscais. E um perfil de analista financeiro pode ter acesso a relatórios e análises financeiras, mas não ter permissão para fazer alterações nos dados do sistema.</p>
          <p>Essa segmentação de perfis ajuda a garantir que cada usuário tenha acesso apenas às informações e funcionalidades necessárias para realizar suas tarefas, ao mesmo tempo em que protege a segurança e a privacidade dos dados da empresa. É uma forma eficiente de gerenciar o acesso dos usuários e manter a integridade dos dados financeiros da empresa.</p>

        </div>
      </div>
    </React.Fragment>
  )
}
