import React from 'react'; 
import { RequiredLogin } from '../../../components/RequiredLogin';

export function VáriaveisNegocio() {
  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Váriáveis de Negócio</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <RequiredLogin/>
        </div>
      </div>
    </React.Fragment>
  )
}
