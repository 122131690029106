import React from 'react'; 
import { Lista } from "../../../components/Lista";


const items = [
  [
    { text: "Introduzir o texto livre." },
    { text: "Proceder a formatação a preferência do utilizador." },
    { text: "Clicar no botão gravar." },
     
  ],
  [
    { text: "Clicar em Adicionar." },
    { text: "Proceder o preenchimento da informação vinculativa da ocorrência." },    
    { text: "Clicar em gravar." }, 

  ],
  [
    { text: "Clicar em memorandos e ocorrência." },
    { text: "Aceder módulo Gerenciamento." },   
  ],
  [
    { text: "Proceder o Upload do ficheiro." },
    { text: "Proceder o preenchimento da informação vinculativa do ficheiro." },   
    { text: "Clicar em gravar." },    
  ],
  
];

export function MemorandosOcorrencias() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Memorandos e Ocorrências</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A funcionalidade de memorandos e ocorrências é utilizada para registrar informações importantes e acompanhar todas as ocorrências relevantes do exercício contabilístico.</p>
          <p></p>
          <p>Essa ferramenta permite aos usuários criar e gerenciar memorandos e ocorrências, adicionando informações importantes, como datas, descrições, arquivos anexos e status de andamento. </p>
          <p></p>
          <p>Os memorandos são usados para registrar informações importantes sobre transações contábeis, como detalhes sobre o lançamento contabilístico, observações sobre determinada conta ou transação, entre outros.</p>

<p>As deliberações são usadas guardar os ficheiros deliberativos probatórios.</p>

<p>As ocorrências são usadas para registrar eventos relevantes, como concertações técnicas.</p>

<p>Como aceder a funcionalidade?</p>

<p><Lista isOrdered={true} items={items[2]}/></p> 

<p>Como registar uma ocorrência?</p>

<p>Após aceder a sessão Ocorrências do formulário memorando e ocorrências:</p> 

<p><Lista isOrdered={true} items={items[1]}/></p> 

<p><b>Como adicionar um ficheiro Deliberações?</b></p>

<p>Após aceder a sessão Deliberações do formulário memorando e ocorrências:</p>

<p></p>

<p><Lista isOrdered={true} items={items[3]}/></p> 

<p></p>

<p><b>Como registar uma anotação de memorando?</b></p>

<p>Após aceder a sessão <b>Anotações Memorandos</b> do formulário memorando e ocorrências:</p>

<p><Lista isOrdered={true} items={items[0]}/></p>
 
<p>Os memorandos e ocorrências no sistema Mastercontas ajudam a manter um registro preciso e completo das atividades contabilísticas e eventos relevantes.</p>

        <p></p>
        </div>
      </div>
    </React.Fragment>
  )
}
