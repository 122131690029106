import React from 'react';
import { Lista } from '../../../../components/Lista';

export function Restore() {

  const items = [
    [
      { pointer:'1. Restore de backup completo (Full Backup Restore): ', text: 'É o tipo mais simples de restore, onde um backup completo é usado para restaurar todo o banco de dados em seu estado consistente mais recente. Esse tipo de restore é útil quando é necessário recuperar o banco de dados inteiro após uma falha.' },
      { pointer:'2. Restore diferencial (Differential Backup Restore): ', text: 'Esse tipo de restore é usado em conjunto com um backup completo e um ou mais backups diferenciais. Ele permite restaurar o banco de dados para o estado consistente no momento do último backup completo, combinando-o com o backup diferencial mais recente. Isso reduz o tempo necessário para restaurar o banco de dados, pois apenas as alterações desde o último backup completo são aplicadas.' }
    ],
    [
      { text:'Abra o formulario "Administrador" '},
      { text:'Selecione a aba "Adminstrador" '},
      { text:'No grupo denominado "Segurança" '},
      { text:'Clique em "Rstore" '},
      { text:'Siga as intruções do assistente de reposição de cópia de seguarnça'}
    ],
    [
      { pointer:'Confirmação de backups: ', text: 'Antes de realizar o restore, é importante verificar se os backups estão íntegros e completos, realizando testes de restauração em um ambiente separado.' },
      //{ text:'Planejamento da sequência de restore: ', text: 'Em cenários com backups diferenciais e de log de transações, é necessário planejar a sequência correta para aplicar os backups e restaurar o banco de dados de forma consistente.' },
      { pointer:'Gerenciamento de conexões e usuários: ', text: 'Durante o restore, é importante garantir que não haja conexões ativas no banco de dados e que os usuários tenham as permissões necessárias para acessar os dados restaurados.' },
      { pointer:'Testes pós-restore: ', text: 'Após o restore, é recomendado realizar testes para verificar a integridade dos dados restaurados e garantir que o banco de dados está funcionando corretamente.' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Restore</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p> <strong>Restore</strong> (restauração): ', text: 'é o processo de recuperar dados a partir de um backup anteriormente realizado. No sistema Master Contas, o restore é usado para trazer um banco de dados de volta a um estado consistente após falhas, erros ou desastres. O restore envolve a recuperação dos dados e objetos do banco de dados a partir dos arquivos de <a href="/#/administrador/seguranca/backup">backup</a>.</p>
          <p>Existem diferentes tipos de restore que podem ser realizados no sistema Master Contas:</p>
          <Lista isOrdered={false} isNumeric={true} items={items[0]}/>
          <p>O sistema Master Contas oferece ferramentas para realizar e automatizar os restores, como o formulario de RESTORE, que podemos encontrar em:</p>
          <Lista isNumeric={false} items={items[1]}/>
          <p>Seguindo os passos a cima, a restauração será efectuado com sucesso. Porem, durante o processo de restore, é importante considerar alguns aspectos:</p>
          <Lista isOrdered={false} items={items[2]}/>
          <p>Em resumo, o restore no SQL Server é o processo de recuperar dados a partir de backups para trazer um banco de dados de volta a um estado consistente. Existem diferentes tipos de restore disponíveis, dependendo dos backups realizados. Planejar e executar o restore corretamente é essencial para garantir a recuperação efetiva dos dados em caso de falhas.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
