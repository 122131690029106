import React from 'react';  
import { Lista } from '../../../components/Lista';

export function AnaliseTextuais() {

  const items = [
    [
      { text: 'Analise S.W.O.T(Força; Fraqueza; Oportunidade; Ameaças).' },
      { text: 'Analise Variáveis de Negocio(Estrutura; Mercado).' },
      { text: 'Proposta de Valores da Empresa(Missão; Visão; Valores).' }, 
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Analise Textuais</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A sessão de análises Textuais no <strong>Modulo de Analises</strong> permite que o usuário desenvolva textos com a formatação desejada e inclua informações relevantes para a análise contabilística e financeira.</p>
          <p>O usuário poderá criar textos e relatórios com formatação personalizada, incorporando-as a posterior no relatório agregado da análise financeira.</p>
          <p>Estão disponíveis duas sessões para execução desta análise:</p>
          <Lista isOrdered={true} items={items[0] } />
          <p>Cada sessão possui formulários em <strong>“Formato Livre”</strong> que permite o utilizador desenvolver o seu texto de análise sem limitações de formatação. </p>

        </div>
      </div>
    </React.Fragment>
  )
}
