import React from "react"; 
import { Lista } from "../../../../components/Lista";

const items = [
  [
    { text: "Aceder ao Administrador." },
    { text: "Aceder ao Parâmetros" },
    { text: "Clicar em Reporting Financeiro na sessão #Automações." },
    { text: "Selecionar o formulário especifico" },
  ],
  [
    { text: "(Balancete + Tipo do Balancete + Tipo do Valor + Conta) " },
    { text: "Exemplo: BT-FIN-SD-111" },
  ],
  [
    { text: "FIN – Final " },
    { text: "REG – Movimentos do período. " },
    { text: "RAN – Movimentos do período anterior " },
    { text: "INC – Final do período anterior " },
  ],
  [
    { text: "SC Saldo Credor" },
    { text: "SD Saldo Devedor" },
    { text: "VD Valor Movimento Devedor" },
    { text: "VC Valor Movimento Credor" },
    { text: "MD Valor Movimento Devedor" },
    { text: "MC Valor Movimento Credor" },
  ],
  [
    { text: "Selecionar a nota que pretende refletir." },
    {
      text: "Em “Copiar de”: Selecionar a empresa em que pretende copiar o parâmetro.",
    },
    {
      text: "Em “Refletir Em”: Selecionar a empresa em que pretende refletir o parâmetro.",
    },
    { text: "Clicar em gravar após a reflexão." },
  ], 
  [
    { text: "Parâmetro: BT-FIN-SD-111" }, 
  ],
];
export function Automacoes() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Automações</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            Atendendo que cada empresa tem a sua própria realidade de
            especialização do código de contas, o preenchimento do Relatório
            Financeiro no sistema mastercontas é de parametrização aberta.{" "}
          </p>
          <p></p>
          <p>
            Aquando da criação da base de dados, o utilizador terá acessos
            parâmetros padrão: PGG e SAP.
          </p>
          <p></p>
          <p>
            Os parâmetros padrão são passiveis de edição, anulação e reflecção
            em outras empresas criadas no sistema.{" "}
          </p>{" "}
          <p></p>
          <p>
            Isso significa que o usuário pode personalizar o código de contas de
            acordo com sua própria realidade e necessidades específicas,
            utilizando os parâmetros padrão como base.
          </p>
          <p></p>
          <p>
            <strong>Como aceder os parâmetros das notas as contas?</strong>
          </p>
          <p></p>
          <Lista isNumeric={true} items={items[0]} />
          <p></p>
          <p>
            <strong>Como editar ou criar um parâmetro? </strong>
          </p>
          <p>Os parâmetros foram desenvolvidos com a mascara padrão:</p>
          <Lista isOrdered={true} items={items[1]} />
          <p></p>
          <p>
            <strong>Acrónimos das descrições dos Balancetes: </strong>
          </p>
          <p></p>
          <Lista isOrdered={true} items={items[2]} />
          <p>
            <strong>Acrónimos das descrições Valores: </strong>
          </p>
          <p></p>
          <Lista isOrdered={true} items={items[3]} />
          <p></p>
          <p>
            <strong>Seleção do tipo de busca da conta: </strong>
          </p>
          <p></p>
          <p>
            <b>I</b> – Buscar a descrição e o valor da conta Integradora
          </p>
          <p>
            <b>M</b> – Buscar analiticamente as descrições e valores das contas
            de movimentos 
          </p>
          <p></p>
          <p><strong>Exemplos para a formação de um parâmetro no reporting financeiro: </strong></p>
<p></p>
          <p>A 1ª alínea do formulário Ativo{">"} “ Imobilizações corpóreas”, deve buscar no balancete final do reporting o saldo a débito da conta 111.</p>
          <Lista isNumeric={true} items={items[5]} />
          <p></p>
          <p>Além disso, as alterações feitas nos parâmetros são refletidas automaticamente em outras empresas o que agiliza e facilita o processo da elaboração do relatório financeiro. </p>
          <p></p>
          <p>É importante lembrar que as mudanças devem ser feitas com cuidado e atenção para garantir a integridade e consistência do código de contas.</p>
          <p>
            <strong>
              Como refletir o parâmetro do reporting de uma empresa para outra?
            </strong>
          </p>
          <p></p>
          <Lista isNumeric={true} items={items[4]} />
          <p></p>
        </div>
      </div>
    </React.Fragment>
  );
}
