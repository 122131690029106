import React from 'react'; 
// import { RequiredLogin } from '../../../../components/RequiredLogin';
import { Lista } from '../../../../components/Lista';
import './styles.scss';


const items = [
  [
    { text: 'Aceder ao Administrador; ' },
    { text: 'Na sessão “ACESSOS” clicar em utilizadores' },
    { text: 'Clicar em novo' },
    { text: 'Preencher as informações de identificação do utilizador ' },
    { text: 'Preencher as informações de segurança ' },
    { text: 'Definir as empresas que o utilizador deve ter acesso' },
    { text: 'Definir os módulos que o utilizador deve ter acesso' },
    { text: 'Definir as permissões de acesso em cada módulo.' }
  ]
]

export function Utilizadores() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Utilizadores</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <RequiredLogin/> */}

          <strong>Como criar um utilizador? </strong>
          <Lista isNumeric={true} items={items[0]}/>

          <p>No preenchimento da identificação do utilizador, dever-se-á definir o <span className='txt-link'>perfil</span> do utilizador. </p>
          <p>O Sistema permite diferentes tipos de <span className='txt-link'>perfil</span>, como administradores, contabilista, técnico de conta, entre outros. Cada tipo de utilizador terá o conjunto de permissões e acessos ao sistema definido no <span className='txt-link'>perfil</span> que lhe foi atribuído. </p>
          <p>O utilizador administrador tem acesso a todas as funcionalidades do sistema, incluindo a criação de novos utilizadores e a configuração de permissões.</p>
          <p>Além disso, o Sistema Mastercontas permite que as permissões de acesso sejam configuradas em diferentes níveis de detalhes, nos separadores “<span className='txt-italic'>Empresas, Módulos, Permissões</span>” no editor de criação do utilizador. </p>
          <p>Essa flexibilidade ajuda a garantir que os usuários tenham acesso apenas ao que precisam para realizar suas tarefas e responsabilidades, mantendo a segurança dos dados e informações da empresa.</p>

        </div>
      </div>
    </React.Fragment>
  )
}
