import React from "react";
import "./styles.scss";
import { Lista } from "../../../../components/Lista";

export function DeclaracaoProvisoria() {

  const items = [
    [
      { pointer: 'GRAVAR', text: ' - Informações preenchidas' },
      { pointer: 'IMPRIMIR', text: ' - Funcionalidade de Visualização' }
    ],
    [
      { text: 'Detalhes do contribuinte' },
      { text: 'Detalhes da Liquidação' },
      { text: 'Taxa do Imposto' },
      { text: 'Valor a Pagar' }
    ],
    [
      { text: 'Geral' },
      { text: 'Motivos da Liquidação' },
      { text: 'Apuramento do Imposto Industrial Provisório' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Declaração Provisória</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div>
           
            <p>
              De acordo com o Código do Imposto Industrial, os contribuintes dos
              Grupos A e B que realizem vendas devem proceder à liquidação
              provisória e ao pagamento do imposto devido até ao final de agosto
              e julho, respetivamente.
            </p>
            <p>
              Este imposto deve ser calculado mediante aplicação da taxa de 2%
              sobre o montante total das vendas efetuadas no primeiro semestre
              do exercício.
            </p>
            <strong>Funcionalidades dos Mapas/Formulário Declaração Provisória:</strong>
            <Lista isOrdered={false} items={items[0]} />
            <strong>Modo de Preenchimento da Declaração Provisória:</strong>
            <p>
              As informações gerais da Declaração Provisória são preenchidas
              automaticamente mediante o Evento Principal e o montante da
              liquidação do imposto deve ser preenchido manualmente.
            </p>
            <strong>Campos com preenchimento automático:</strong>
            <Lista isOrdered={true} items={items[1]} />
            <strong>Campo para o preenchimento manual:</strong>
            <Lista isOrdered={true} items={items[2]} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
