import React, { useEffect, useRef } from 'react';
import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { ScrollSmoother } from 'gsap/ScrollSmoother';

import './styles.scss';

export function GuiaFormacao() {

    const lenis = new Lenis({
        duration: 1.2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
    });

    function raf(time: any) {
        lenis.raf(time);
        ScrollTrigger.update();
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    // const section_1 = document.getElementById("vertical");
    // const col_left = document.querySelector(".col_left");
    let section_1 = useRef(null);
    let col_left = useRef(null);
    

    useEffect(() => {
        const timeln = gsap.timeline({ paused: true });
        gsap.registerPlugin(ScrollTrigger);

        timeln.fromTo(col_left, { y: 0 }, { y: '170vh', duration: 3, ease: 'none' }, 0);
        //gsap.registerPlugin(ScrollToPlugin);

        const st = ScrollTrigger.create({
            animation: timeln,
            trigger: section_1.current,
            start: 'top top',
            end: 'bottom center',
            scrub: true
        });

        gsap.registerPlugin(st);
    
        
    }, []);

    // const section_2 = document.getElementById("horizontal");
    // let box_items = gsap.utils.toArray(".horizontal__item");

    // gsap.to(box_items, {
    //   xPercent: -100 * (box_items.length - 1),
    //   ease: "sine.out",
    //   scrollTrigger: {
    //     trigger: section_2,
    //     pin: true,
    //     scrub: 3,
    //     snap: 1 / (box_items.length - 1),
    //     end: "+=" + section_2.offsetWidth
    //   }
    // });

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <section id="vertical" ref={section_1}>
                        <div className="container">
                            <div className="vertical__content">
                                <div className="col col_left">
                                    <h2 className="vertical__heading"><span>GUIA DE</span><span>FORMAÇÃO</span><span>MASTERCONTAS</span></h2>
                                </div>
                                <div className="col col_right" ref={col_left}>
                                    <div className="vertical__item">
                                        <h3>Criação de Empresa </h3>
                                        <p>O sistema mastercontas foi desenvolvido para realizar rotinas distintas no ramo da contabilidade, consolidação de contas, projetos de auditoria e revisão de contas, e criação de projetos de investimentos..</p>
                                    </div>
                                    <div className="vertical__item">
                                        <h3>Criação Exércicio </h3>
                                        <p>Lenis is an open-source library built to standardize scroll experiences and sauce up websites with butter-smooth navigation, all while using the platform and keeping it accessible.</p>
                                    </div>
                                    <div className="vertical__item">
                                        <h3>Importação de Balancentes </h3>
                                        <p>Lenis is an open-source library built to standardize scroll experiences and sauce up websites with butter-smooth navigation, all while using the platform and keeping it accessible.</p>
                                    </div>
                                    <div className="vertical__item">
                                        <h3>Notas ás Contas</h3>
                                        <p>Lenis is an open-source library built to standardize scroll experiences and sauce up websites with butter-smooth navigation, all while using the platform and keeping it accessible.</p>
                                    </div>
                                    <div className="vertical__item">
                                        <h3>Parâmetros das Notas ás Contas</h3>
                                        <p>Lenis is an open-source library built to standardize scroll experiences and sauce up websites with butter-smooth navigation, all while using the platform and keeping it accessible.</p>
                                    </div>
                                    <div className="vertical__item">
                                        <h3>Demonstracões Financeiras</h3>
                                        <p>Lenis is an open-source library built to standardize scroll experiences and sauce up websites with butter-smooth navigation, all while using the platform and keeping it accessible.</p>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
}