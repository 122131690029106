
import React from 'react';   
import { Lista } from "../../../components/Lista";

const items = [
  [
    { text: "Aceder módulo Gerenciamento." },
    { text: "Escolher o tipo de correspondência (Correio enviado ou Correio recebido)." },
    { text: "Adicionar as informações de registo da correspondência" },
    { text: "Definir o nível de segurança e as permissões de acessos   " }, 
  ],
  [
    { text: "Anexar o documento de resposta" },
    { text: "Observação textual sobre a correspondência" },
  ],
  
];

export function GestaoConrrespondencia() {
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Gestão de Conrrespondência</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                   <p>A funcionalidade de gestão de correspondências permite ao usuário gerenciar todas as correspondências da empresa de forma organizada e eficiente.  </p>
               <p></p>
              <p>Nesta sessão, é possível registrar o recebimento ou envio de correspondências, definir o nível de segurança e permissões de visualizações, definir prazos de resposta e monitorar o status de cada correspondência.  </p>
              <p></p>
              <p>Também é possível anexar documentos e outras informações relevantes às correspondências registradas.</p>
              <p></p>
              <p><b>Como registar uma correspondência?  </b></p>
               <p></p>
               <p><Lista isNumeric={true} items={items[0] } /></p>
               <p></p>
                <p>Em caso excecional o utilizador poderá ainda:  </p>
                <p></p>
                <p><Lista isOrdered={true} items={items[1] } /></p>
                <p></p>
                </div>
            </div>
        </React.Fragment>
    );
}   