import React from 'react'; 
import { RequiredLogin } from '../../../../components/RequiredLogin';

export function ConferenciaMovimentos() {
  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Conferência Movimentos</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <RequiredLogin/>
        </div>
      </div>
    </React.Fragment>
  )
}
