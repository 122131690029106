import React from 'react';
import './styles.scss';
import { Lista } from '../../../../components/Lista';

export function GestaoDossierFiscal() {

  const items = [
    [
      { pointer: 'Sessão Dossier do Sistema', text: '- Contém os mapas de raiz da aplicação, ou seja, os mapas ou categorias pré-definidas que são comuns a todos os usuários do sistema' },
      { pointer: 'Sessão Dossier Personalizado', text: '- Corresponde aos mapas que são desenvolvidos no âmbito de uma solução personalizada ou adaptada às necessidades específicas de uma empresa' }
    ],
    [
      { text: 'Aceder módulo fiscalidade ' },
      { text: 'Sessão Dossier Contabilístico - Gestor Dossier. ' },
      { text: 'Selecionar a sessão do dossier (Sistema / Personalizado) ' }
    ],
    [
      { text: 'Após aceder o formulário, clicar em “Novo”' },
      { text: 'Preencher o formulário “editor” do formulário central' },
      { text: 'Clicar em gravar' }
    ],
    [
      { text: 'Após aceder o formulário' },
      { text: 'Efetuar duplo clique na alínea que pretende remover' },
      { text: 'Clicar em remover' }
    ],
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Gestão Dossier Fiscal</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O gestor do dossier fiscal é a ferramenta utilizada para listar todos os documentos que foram elaborados no âmbito da preparação do dossier fiscal.</p>
          <p>Essa ferramenta tem como objetivo principal centralizar e organizar todos os documentos relacionados ao dossier fiscal, facilitando o acesso, o controle e a gestão desses documentos.</p>
          <p>No gestor do dossier fiscal, são registrados todos os documentos que compõem o dossier fiscal e o utilizar tem a possibilidade de adicionar o respetivo anexo.</p>
          <p><strong>Este editor possui duas sessões: </strong></p>
          <Lista isOrdered={false} items={items[0]} />
          <p><strong>1 - Como aceder ao editor? </strong></p>
          <Lista isOrdered={true} items={items[1]} />
          <p><strong>2 - Como adicionar um documento? </strong></p>
          <Lista isOrdered={true} items={items[2]} />
          <p><strong>3 - Como remover um documento? </strong></p>
          <Lista isOrdered={true} items={items[3]} />
          <p>A funcionalidade de <strong>adicionar anexos</strong> permite que o usuário faça <strong>upload</strong> de arquivos em diferentes formatos, como PDF, documentos do Word, imagens, entre outros, que comprovem o cumprimento fiscais ou forneçam informações adicionais necessárias.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
