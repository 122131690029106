import React from 'react';
import { Lista } from '../../../../components/Lista';

const items = [
  [
    { text: 'Aceder administrador  ' },
    { text: 'Clicar em criar empresa ' },
    { text: 'Selecionar o projeto “tipo de empresa” que pretende criar ' },
    { text: 'Em seguida aplicação abrirá o formulário assistente de criação da empresa ' },
    { text: 'Deve proceder o preenchimento dos campos obrigatórios, para o assistente avançar na próxima sessão de criação da empresa ' },
    { text: 'Após a introdução correta dos dados o assistente irá sugerir a criação do exercício, caso avançar o assistente de criação do exercício será aberto, caso não avançar o processo de criação dará prossecução. ' },
    { text: 'Após a criação da empresa, clicar na opção terminar operação, e fazer novamente o login ao sistema.  ' }
  ]
]

export function Criacao() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Criação</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O sistema mastercontas foi desenvolvido para realizar rotinas distintas no ramo da contabilidade, consolidação de contas, projetos de auditoria e revisão de contas, e criação de projetos de investimentos.</p>
          <p>A criação da empresa, deve ser de acordo o tipo do projeto pretendido a desenvolver.</p>
          <p>Toda criação da empresa ocorre com ajuda de um formulário assistente de criação que valida os campos obrigatórios e facultativos de preenchimento.</p>
          <p><strong>Como criar a empresa?</strong></p>
          <Lista isNumeric={true} items={items[0]}/>
        </div>
      </div>
    </React.Fragment>
  )
}
