import React from 'react';
import './styles.scss';
import { CardImage } from '../../../components/CardImage';

export function ParametrosRelatorio() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Parâmetros do Relatório</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>

          <p><strong>Como imprimir o relatório?</strong></p>

          <p>Os parâmetros de visualização são comandos para a composição de relatórios à preferência do utilizador. </p>
          <p>Estes parâmetros são configuráveis, permitindo ao utilizador, definir a forma como pretende ver os seus relatórios</p>
          <p>O sistema permite ao utilizador adicionar filtros para aumentar a eficácia do relatório conforme preferências tais como:</p>

          <ol>
            <li>Selecionar o Layout do Relatório;</li> <br />
            <li>Alterar as cores;</li> <br />
            <li>Adicionar anexos; </li> <br />
            <li>Ativar restrições;</li> <br />
            <li>Agregar e desagregar outros relatórios produzidos pela aplicação;</li>
 
          </ol>

          <p>⚠<i> Apos a seleção das preferências de visualização, para obtenção do relatório, o usuário deve clicar no ícone com o título do relatório. </i></p>
          <p>O Sistema oferece ao usuário a possibilidade de impressão de todas as informações que são processadas no sistema, e exportá-los nos diferentes formatos (PDF, XLS, XML, etc).</p>
        
          <CardImage filename='imgnotfound.jpg' alt='Parametros de visualização'></CardImage>
          <br />
          <hr />
          <br />
          <CardImage filename='imgnotfound.jpg' alt='img'></CardImage>
        </div>
      </div>
    </React.Fragment>
  )
};
