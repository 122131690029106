import React from 'react';
import './styles.scss';
import { Lista } from '../../../components/Lista';

export function Balancetes() {

    const items = [
        [
            { pointer: '◽ REG: ', text: 'Balancete de Regularização ➡ mês 1 ao mês 13. ' },
            { pointer: '◽ RAN: ', text: 'Balancete de Regularização Ano anterior ➡ mês 1 ao mês 13.' },
            { pointer: '◽ FIN: ', text: 'Balancete final do Exercício ➡ mês 0 ao mês 15' },
            { pointer: '◽ INC: ', text: 'Balancete final do Exercício Anterior ➡ mês 0 ao mês 15' }
        ],
        [
            { pointer: 'IMPORTAR  ➡ ', text: 'Uploud de informação;' },
            { pointer: 'ATUALIZAÇÃO ➡ ', text: 'Consulta integral de informação no banco de dados extesion;' },
            { pointer: 'IMPRIMIR ➡ ', text: 'Funcionalidade de visualização;' },
            { pointer: 'RESERT ➡ ', text: 'Reposição da configuração inicial;' },
            { pointer: 'GRAVAR ➡ ', text: 'Gravar a informação importada/processada;' },
            { pointer: 'PROCESSAMENTO ➡ ', text: 'Ordem de comando do evento principal;' },
            { pointer: 'ANULAÇÃO ➡ ', text: 'Anulação do evento principal;' },
            { pointer: 'HISTÓRICOS ➡ ', text: 'Registos dos eventos executados (Processamento e/ou Anulação)' }
        ]
    ];

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Balancetes</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <p>O balancete é um instrumento financeiro que apresenta o total dos débitos e dos créditos das contas,
                        juntamente com o saldo de cada uma delas.</p>
                    <p>Os balancetes são importados no Sistema Mastercontas atendendo a periodicidade contabilísticas dos factos patrimoniais:</p>
                    
                    <Lista isOrdered={false} items={items[0]} />  

                    <p>Os balancetes dispõe das funcionalidades:  </p>

                    <Lista isOrdered={false} items={items[1]} />  
                    
                </div>
            </div>
        </React.Fragment>
    )
};