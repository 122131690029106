import React from 'react';

export function Fiscalizacoes() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Fiscalizacões</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>O módulo de fiscalizações é uma ferramenta importante para os departamentos fiscais e contabilistas que precisam lidar com procedimentos inspetivos da AGT. </p>
        <p>Com este módulo, é possível gerir todas as fases do processo inspetivo de forma organizada e eficiente, desde o pedido de esclarecimentos até o recurso hierárquico.</p>
        <p>O sistema permite o acompanhamento detalhado das etapas do processo, como o direito de audição prévia, a notificação final e as reclamações administrativas hierárquicas e judiciais. Além disso, é possível gerir as garantias, as liquidações por parcelas e a execução fiscal de forma integrada, simplificando o processo e reduzindo o tempo de resposta.</p>
        <p>Com o uso deste módulo, é possível aumentar a eficiência no gerenciamento de processos fiscais, garantindo a conformidade com a legislação tributária e reduzindo os riscos de autuações e penalidades.</p>

        </div>
      </div>
    </React.Fragment>
  )
}