import React from "react"; 
import { Lista } from "../../../components/Lista";
const items = [
  [
    { text: "Selecionar a data inicial" },
    { text: "Selecionar a data final 3dicar o técnico responsável " },
    { text: "Indicar o status da tarefa (Corrente / Urgente)" },
    { text: "Indicar a categoria da tarefa " },
    { text: "Definir o processo atinente " },
    { text: "Colocar a descrição analítica da tarefa" },
    { text: "Clicar em agendar" },
  ],
  [
    { text: "Adicionar um ficheiro  " },
    { text: "Adicionar" },
  ],
  
];
export function GestaoTarefa() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Gestão Tarefas</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            Na sessão de gestão de tarefas o usuário pode gerenciar todas as
            tarefas diárias, como criação, edição e exclusão de tarefas. 
          </p>
          <p></p>
          <p>
            É possível atribuir tarefas para diferentes usuários, definir prazos
            e status das tarefas, além de acompanhar o progresso e o histórico
            das tarefas. 
          </p>
          <p></p>
          <p>
            A sessão de gestão de tarefas oferece recursos como a possibilidade
            de adicionar comentários às tarefas, anexar arquivos relevantes e
            definir lembretes para garantir que as tarefas sejam concluídas
            dentro do prazo estabelecido.
          </p>
          <p>
            <b>1 - Como agendar uma tarefa? </b>
          </p>

          <p></p>
          <p>
            A definição e/ou agendamento das tarefas é feita através do editor
            gestão de tarefas. 
          </p>
          <p></p>
          <p>
            Primeiramente deve selecionar o dia em que iniciará uma tarefa, em
            seguida o sistema abrirá o editor de agendamento, onde deverá: 
          </p>
          <p><Lista isOrdered={true} items={items[0]}/></p>
          <p></p>
          <p>Em seguida o assistente de agendamento abrirá um novo formulário que permite o utilizador:  </p>
          <p></p>
          <p><Lista isNumeric={false} items={items[1]}/></p>
        </div>
      </div>
    </React.Fragment>
  );
}
