import React, { useState } from 'react';
import './styles.scss';
import Form from 'devextreme-react/form';

export function Profile() {

  const [notes, setNotes] = useState(
    ''
  );

  const employee = {
    //ID: 7,
    Nome: 'Utilizador',
    Sobrenome: 'Publico',
    Prefixo: 'Srº.',
    Cargo: 'Visitante',
    //Picture: 'https://mastercontas.com/wp-content/uploads/2023/03/cropped-mastercontas_logo.png',
    DataDeAniversario: new Date('2022/08/30'),
    //HireDate: new Date('2005/05/11'),
    //Notes: notes,
    Endereço: 'Uurbanização Nova Vida, Rua 50, Ed 81', 
  };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Perfil</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'form-avatar'}>
          <img
            alt={'Master Contas'}
            //src={`${employee.Picture}`}
            src= 'https://mastercontas.com/wp-content/uploads/2023/03/cropped-mastercontas_logo.png'
          />
        </div>
        <span>{notes}</span>
      </div>

      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          defaultFormData={employee}
          onFieldDataChanged={e => e.dataField === 'Notes' && setNotes(e.value)}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
          
        />
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
