
import { Ordered } from "./Ordered";
import { Unordered } from "./Unordered";
import { propsDefault } from "./interfaces";

/** 
 * @export
 * @param {propsDefault} { 
 *  //* isOrdered => define se a lista vai ser ordenada ou n#ao ordenada, 
 *  //* isNumeric => caso n#ao ordenada, define se a lista vai ser numerico ou alfabetica, 
 *  //* items => recebe uma lista de items que vai ser apresentado na lista }
 * @return { retorna um objecto do tipo "JSX.Element"} 
 */
export function Lista({ isOrdered, isNumeric, items } : propsDefault) {
 
    return (
        <> 
            { isOrdered ? <Ordered items={items} /> : <Unordered isNumeric={isNumeric} items={items}/>}
        </>
    );
}

// ! Alteracoes master contas
// * nota 35.1 soma e não aparece no file de validacao
// * nota 34.1 soma e diponibliza periodo
