import React from "react"; 
import { Lista } from "../../components/Lista";

export function RelatorioContas() {

  const items = [
    [
      { pointer:'•	Elaboração das notas as contas: ', text: 'permite o preenchimento automatizado das notas as contas.'},
      { pointer:'•	Geração automatizada de relatórios: ', text: 'permite gerar automaticamente relatório contabilístico, incluindo balanços patrimoniais, demonstrações de resultados, demonstrações de fluxos de caixa, entre outros.'},
      { pointer:'•	Análise de dados financeiros: ', text: 'permite realizar análises e avaliações da evolução dos resultados e elementos patrimoniais da empresa.'},
      { pointer:'•	Adaptação sistemas de contas: ', text: 'permite adequar os parâmetros das automações em vários códigos de contas. '},
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Relatório & Contas</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}> 
        <p>O módulo de Relatório e Contas é uma ferramenta essencial para contabilistas e empresas na preparação do relatório anual e contas. </p>
        <p>Este módulo foi projetado para facilitar e automatizar a criação de relatórios contabilísticos, incluindo as notas as contas, o balanço patrimonial, a demonstração de resultados, a demonstração de fluxos de caixa, entre outros relatórios conexos necessários.</p>
        <p>O sistema de relatório e contas permite que os contabilistas insiram as informações contabilísticas da empresa em uma plataforma centralizada e, em seguida, gerar os relatórios precisos e atualizados em tempo real. </p>
        <p>Os relatórios gerados pelo sistema são elaborados em conformidade com as normas, princípios e regulamentos contabilísticos e fiscais aplicáveis no ordenamento jurídico fiscal angolano.</p>
        <p>Algumas das funcionalidades do módulo de Relatório e Contas são:</p>
          <Lista isOrdered={false} items={items[0]}/>
          <p>Com o módulo de relatório e contas, os contabilistas e empresas podem preparar relatórios financeiros de alta qualidade de forma rápida e eficiente, garantindo a conformidade com as normas contabilísticas e fiscais. Isso ajuda a empresa a tomar decisões estratégicas mais informadas e a melhorar sua transparência e credibilidade com os stakeholder.</p>
          {/* //* BackUp 
          <p>
            O Relatório e Contas é um documento produzido anualmente pelas
            empresas e outras organizações, constituído essencialmente por duas
            partes:
          </p>
          <ul>
            <li>
              Um relatório onde é apresentada a atividade e o desempenho da
              empresa e o contexto em que a mesma foi desenvolvida;
            </li>
            <li>
              As contas da empresa, apresentadas em mapas contabilísticos
              específicos, nomeadamente as Balanço, a Demonstração de Resultados
              e o Mapa de Fluxos de Tesouraria.
            </li>
          </ul>
          <p>
            O principal objetivo do Relatório e Contas é o de colocar à
            disposição das partes interessadas toda a informação que permite a
            correta avaliação do desempenho da empresa em causa, constituindo
            uma oportunidade para explicar aos diversos interessados as
            prioridades estratégicas do exercício, face ao enquadramento
            económico e regulamentar, caracterizar a atividade das áreas de
            negócio e analisar o seu efeito do ponto de vista económico e
            financeiro.
          </p>
          <strong>1. Como Importar Um Balancete?</strong>
          <p>
            Para importar um balancete, é necessário aceder ao sistema
            informático da empresa e selecionar a opção "Importar Balancete". Em
            seguida, deve ser selecionado o ficheiro com o balancete a ser
            importado e seguir as instruções apresentadas no ecrã.
          </p>
          <strong>2. Como gerar o mapa das Amortizações?</strong>
          <p>
            Para gerar o mapa das amortizações, é necessário aceder ao sistema
            informático da empresa e selecionar a opção "Mapa de Amortizações".
            Em seguida, deve ser selecionado o período de tempo a ser
            considerado e seguir as instruções apresentadas no ecrã.
          </p>
          <strong>3. Funcionamento das Notas às Contas?</strong>
          <p>
            As notas às contas são elementos complementares aos mapas
            contabilísticos, que permitem uma melhor compreensão das informações
            apresentadas. Estas notas fornecem informações adicionais que
            complementam a informação apresentada nas contas.
          </p>
          <strong>4. Como gerar as Demonstrações Financeiras?</strong>
          <p>
            Para gerar as demonstrações financeiras, é necessário aceder ao
            sistema informático da empresa e selecionar a opção correspondente à
            demonstração financeira que se pretende gerar. Em seguida, deve ser
            selecionado o período de tempo a ser considerado e seguir as
            instruções apresentadas no ecrã.
          </p>
          <strong>5. Como realizar o apuramento do imposto industrial?</strong>
          <p>
            Para realizar o apuramento do imposto industrial, é necessário ter
            acesso aos valores das vendas realizadas no período em questão. Em
            seguida, deve ser aplicada a taxa de imposto industrial
            correspondente e calculado o valor a pagar.
          </p>
          <strong>6. Como gerar análises Contabilísticas?</strong>
          <p>
            Para gerar análises contabilísticas, é necessário aceder ao sistema
            informático da empresa e selecionar a opção correspondente à análise
            contabilística que se pretende gerar. Em seguida, devem ser
            definidos os parâmetros de visualização e seguir as instruções
            apresentadas no ecrã.
          </p>
          <strong>7. Parâmetros de visualização do Relatório</strong> */}
        </div>
      </div>
    </React.Fragment>
  );
}
