import React from 'react'; 
import { Lista } from '../../../../components/Lista';

export function Manutencao() {

  const items = [
    [
      { pointer:'◾ Segurança: ', text: 'As atualizações de sistema geralmente incluem correções de segurança para proteger seus dados e informações pessoais contra ameaças cibernéticas. Manter o sistema atualizado com as últimas atualizações de segurança é essencial para minimizar os riscos de invasões, roubo de dados ou outros ataques maliciosos.' },
      { pointer:'◾ Desempenho e estabilidade: ', text: 'As atualizações também podem trazer melhorias de desempenho e estabilidade para o sistema. Elas podem otimizar o código do software, corrigir erros que causam travamentos ou lentidão e aprimorar a experiência geral do usuário. Ao manter o sistema atualizado, você pode desfrutar de um desempenho mais rápido e estável em suas atividades diárias.' },
      { pointer:'◾ Novas funcionalidades: ', text: 'Em muitos casos, as atualizações trazem novas funcionalidades e recursos para o sistema. Essas atualizações podem expandir as opções disponíveis, melhorar a usabilidade e adicionar recursos que podem aumentar sua produtividade ou oferecer uma experiência mais rica. Ficar atualizado permite aproveitar ao máximo as inovações e aprimoramentos oferecidos pelo fabricante ou desenvolvedor do sistema.' },
      { pointer:'◾ Compatibilidade: ', text: 'À medida que o tempo passa, os sistemas operacionais e os aplicativos podem se tornar incompatíveis com versões mais antigas de software ou hardware. Ao atualizar o sistema, você garante que ele esteja alinhado com as últimas tecnologias e padrões. Isso pode evitar problemas de compatibilidade com novos dispositivos, periféricos ou softwares que você possa querer utilizar.' },
      { pointer:'◾ Suporte técnico: ', text: 'Manter o sistema atualizado é importante para receber suporte técnico adequado do fabricante ou desenvolvedor. Em muitos casos, eles podem restringir o suporte a versões mais antigas do sistema operacional ou software. Ao estar na versão mais recente, você pode obter assistência técnica, correções de bugs e respostas a dúvidas com mais facilidade.' },
      //{ pointer:'Notificações e configurações: ', text: 'Muitos sistemas têm recursos de notificação automática de atualizações. Certifique-se de habilitar essas notificações para ser informado sobre novas atualizações disponíveis. Além disso, verifique as configurações de atualização do sistema para garantir que as atualizações sejam baixadas e instaladas automaticamente ou de acordo com suas preferências' }
    ],
    [
      { pointer:'◾ Frequência de backup: ', text: 'É necessário determinar com que frequência os backups devem ser executados. A frequência de backup depende da natureza dos dados e da importância das informações que você está armazenando. Em geral, é recomendado fazer backups regulares, diários ou semanais, para garantir a captura das alterações mais recentes nos dados.'},
      { pointer:'◾ Tipo de backup: ', text: 'Existem diferentes tipos de backup, como backup completo, backup incremental e backup diferencial. O backup completo faz uma cópia de todos os dados selecionados, enquanto o backup incremental captura apenas as alterações desde o último backup, e o backup diferencial captura as alterações desde o último backup completo. A escolha do tipo de backup depende da capacidade de armazenamento disponível e do tempo necessário para realizar o backup e a restauração.'},
      //{ pointer:'Local de armazenamento: ', text: 'Determine onde os backups serão armazenados. É recomendado ter uma abordagem de backup em camadas, armazenando os backups em locais diferentes para garantir a segurança dos dados. Isso pode incluir armazenamento em nuvem, dispositivos externos, servidores de rede ou até mesmo cópias físicas em um local seguro fora das instalações. Certifique-se de escolher locais de armazenamento confiáveis e seguros, com medidas de segurança adequadas.'},
      { pointer:'◾ Automação do agendamento: ', text: 'Automatizar o agendamento de backup é altamente recomendado. Utilize ferramentas ou recursos disponíveis no sistema operacional ou software de backup para configurar e agendar as tarefas de backup. A automação garante que os backups sejam executados regularmente, mesmo que você esqueça ou esteja ocupado.'},
      { pointer:'◾ Verificação de integridade: ', text: 'Após a conclusão de cada backup, é importante verificar a integridade dos dados de backup. Verifique se os arquivos de backup estão completos e não corrompidos. Isso pode ser feito através de testes de restauração de dados de backup em um ambiente de teste ou por meio da verificação da validade dos arquivos de backup usando ferramentas específicas.'},
      //{ pointer:'Retenção de backups antigos: ', text: 'Defina uma política de retenção de backups antigos. Determine por quanto tempo os backups serão mantidos e quando eles serão descartados. Isso depende dos requisitos legais, das políticas internas da organização e das necessidades individuais. Certifique-se de que a retenção de backups seja consistente com as regulamentações aplicáveis e com a capacidade de armazenamento disponível.'},
      { pointer:'◾ Monitoramento: ', text: 'Monitore regularmente o status dos backups para garantir que eles estejam sendo executados corretamente.'},
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Manutenção</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A manutenção de sistemas é o conjunto de atividades realizadas para garantir o funcionamento adequado e contínuo de um sistema, seja ele um software, hardware ou uma combinação dos dois. Essas atividades visam preservar a integridade, confiabilidade e desempenho do sistema ao longo do tempo.</p>
          <p><strong>1. Atualização do sistema</strong></p>
          <p>Manter o sistema atualizado é fundamental para garantir a segurança, estabilidade e desempenho adequados. Aqui estão algumas considerações importantes sobre a atualização do sistema:</p>
          <Lista isOrdered={false} items={items[0]}/>
          <p><strong>2. Armazenamento de dados</strong></p>
          <p>O armazenamento de dados envolve a organização adequada dos dados, escolha do local de armazenamento adequado, segurança dos dados, realização de backups regulares, gerenciamento de espaço e organização de documentos. Ao adotar práticas eficazes de armazenamento, você pode garantir a integridade, segurança e acessibilidade dos seus dados pesso</p>
          <p><strong>3. Agendamento de backup</strong></p>
          <p> Para proteger os dados e garantir a recuperação em caso de perda ou falha, é essencial agendar e realizar backups regulares. Isso envolve definir a frequência dos backups (diários, semanais, etc.), determinar o tipo de backup (completo, incremental, diferencial). É importante testar regularmente os backups para garantir sua integridade e praticar a restauração dos dados para verificar a eficácia do processo de recuperação.</p>
          <p>Aqui estão algumas considerações importantes sobre o agendamento de backup:</p>
          <Lista isOrdered={false} items={items[1]}/>
          <p>Em resumo, o agendamento de backup é essencial para garantir a proteção e disponibilidade dos seus dados. Estabeleça uma frequência adequada de backup, escolha o tipo</p>
          <p><strong>4. Histórico de backup</strong></p>
          <p>Manter um histórico de backup é uma prática recomendada para rastrear e documentar os backups realizados. Isso permite identificar o momento exato de cada backup, facilitando a recuperação de versões específicas dos dados, se necessário. É importante manter um registro detalhado dos backups, incluindo informações como a data e hora, tipo de backup, local de armazenamento e qualquer observação relevante.</p>
          {/* <p>Manter o sistema atualizado é fundamental para garantir a segurança, estabilidade e desempenho adequados. Aqui estão algumas considerações importantes sobre a atualização do sistema:</p> */}
          <p><strong>5. Manutenção da base de dados</strong></p>
          <p>Além dos backups, a manutenção do banco de dados inclui atividades como otimização de desempenho, monitoramento, limpeza de dados antigos e gerenciamento de espaço em disco. É importante efectuar a manutenção para garantir a eficiência do sistema e evitar problemas futuros.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
