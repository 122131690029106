import React from 'react';
import { Lista } from '../../components/Lista';

export function Contabilidade() {

  const items = [
    [
      { pointer:'1.	Lançamentos contabilísticos: ', text: 'o sistema permite o registro de todos os lançamentos contabilístico, como proveitos, custos, pagamentos, recebimentos, entre outros.' },
      { pointer:'2.	Conciliação bancária: ', text: 'o sistema possibilita a conciliação automática ou manual dos lançamentos bancários, facilitando a conferência dos extratos bancários e a identificação de possíveis divergências.' },
      { pointer:'3.	Emissão de balancetes e demonstrativos: ', text: 'o sistema gera balancetes e demonstrativos gerais e do razão.' },
      { pointer:'4.	Preenchimento da Declaração anual: ', text: 'o sistema possibilita a emissão de notas fiscais eletrônicas para vendas, serviços e outras atividades relacionadas à empresa.' },
      { pointer:'5.	Conferência de movimentos: ', text: 'o sistema permite a validação e conferência de todos os movimentos registados.  ' },
      { pointer:'6.	Integração com sistemas de gestão empresarial (ERP): ', text: 'o sistema de contabilidade pode se integrar com sistemas ERP, permitindo a importação da informações entre as diferentes áreas da empresa, como finanças, compras, vendas, estoque, entre outras' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Contabilidade</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>O Sistema Mastercontas foi desenvolvido para realizar todas as rotinas de contabilidade da empresa, desde a entrada de lançamentos, até a emissão de relatórios e demonstrativos financeiros.</p>
        <p>O sistema oferece diversas funcionalidades para facilitar o trabalho dos profissionais de contabilidade.</p>
        <p>Permite gerenciar múltiplas empresas em uma única plataforma, facilitando a organização e a gestão de informações contabilísticas de diferentes negócios.</p>
        <p>O módulo de contabilidade foi projetado para atender às necessidades de empresas de diferentes tamanhos e segmentos, oferecendo recursos personalizados de acordo com as características de cada negócio. </p>
        <p>Alem disso, o módulo de contabilidade pode realizar integrações com outras ferramentas e importações de contas para tornar o processo contábil mais eficiente e preciso.</p>
        <p>Isso ajuda a maximizar a eficiência e a produtividade da equipe contabilística, permitindo que eles se concentrem em tarefas estratégicas e de maior valor agregado para a empresa.</p>

        <strong><p>Algumas das principais funcionalidades incluem:</p></strong>

        <Lista isOrdered={false} items={items[0]} />

        <p>Em resumo, o Sistema Mastercontas é uma plataforma completa de contabilidade, que oferece recursos avançados para a gestão de informações financeiras e contabilísticas, desde o registro de lançamentos até a emissão de relatórios e demonstrativos financeiros. </p>
        <p>Com essa plataforma, os profissionais de contabilidade podem ter maior agilidade e precisão na execução de suas rotinas diárias de trabalho.</p>


        </div>
      </div>
    </React.Fragment>
  )
}