import React from 'react';
import './styles.scss';
import { Lista } from '../../../components/Lista';

export function DossierFiscal() {

  const items = [
    [
      { text:'Dossier Contabilístico ' },
      { text:'Documentos Legais ' },
      { text:'Documentos de Gestão ' },
      { text:'Mapas internos  ' }
    ],
    [
      { pointer:'•	Declarações de impostos:', text: ' Documentos que contêm as informações sobre a apuração e o pagamento dos impostos devidos pela empresa.' },
      { pointer:'•	Comprovantes de pagamento:', text: ' Recibos, faturas e outros documentos que atestam o pagamento de impostos, taxas e contribuições.' },
      { pointer:'•	Registros contabilísticos:', text: ' Balanços, demonstrações de resultados, registros de transações financeiras e outros documentos contabilísticos que registram as atividades financeiras da empresa.' },
      { pointer:'•	Correspondências com autoridades fiscais:', text: ' Cartas, notificações e comunicações trocadas entre a empresa e as autoridades fiscais, como solicitações de informações, entre outros.' },
      { pointer:'•	Certificados e licenças:', text: ' Documentos que comprovam a regularidade da empresa perante as autoridades fiscais, como certificados de regularidade fiscal, licenças de funcionamento, contratos celebrados com autoridades fiscais para regimes especiais, incentivos fiscais ou acordos de tributação específicos.' },
      { pointer:'•	Outros documentos relacionados à tributação:', text: ' Qualquer outro documento relevante para a área fiscal, como pareceres jurídicos, laudos técnicos, relatórios de auditoria, entre outros.' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Doddier Fiscal</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>O <strong>"Dossier Fiscal"</strong> é um conjunto de documentos e informações relacionadas às obrigações fiscais e tributárias de uma empresa. </p>
        <p>Esse conjunto de documentos é utilizado para manter um registro organizado das atividades financeiras e fiscais, e também para cumprir com as exigências legais e regulatórias relacionadas à tributação. </p>
        <p><strong>No sistema mastercontas a elaboração do dossier fiscal esta dividido em quarto sessões:</strong></p>
        <Lista isNumeric={true} items={items[0]}/>
        <p><strong>O dossier fiscal pode incluir uma variedade de documentos, tais como:</strong></p>
        <Lista isOrdered={false} items={items[1]}/>
        <p>O <strong>dossier fiscal</strong> é essencial para garantir a conformidade legal e para facilitar auditorias fiscais, inspeções e outros processos relacionados à fiscalização tributária. É importante manter esse conjunto de documentos de forma organizada, permitindo um acesso fácil e rápido sempre que necessário.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
