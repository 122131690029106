import React, { useRef } from 'react';
import './styles.scss';
import Form, { ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';

export function RequiredLogin() {

    const { /* user, */ signOut } = useAuth();
    const img = require('../../assets/img/requiredLogin.jpg');
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    function checkWindowsSize(): JSX.Element {
        const isMobile = windowSize.current[0] < 500;

        return (
            isMobile ? 
            <></> : 
            <img
                src={img}
                alt="Conteudo reservado para utiilizadores com sessão inicidda"
                width={600}
                height={600}
            />
        );
    }

    return (
        <div className={'content-main'}>

            <div className={'content-description'}>

                <img
                    src='https://mastercontas.com/wp-content/uploads/2023/03/cropped-mastercontas_logo.png'
                    alt="Master Contas"
                    width={100}
                    height={100}
                />

                <p>Parece que você encontrou uma das nossas funcionalidades exclusivas, disponíveis apenas para nossos usuários registrados. </p>
                <p>Para aproveitar aceder a informação desta sessão solicitamos que clique no botão "<strong>Fazer Login</strong>" abaixo e insira suas credenciais.</p>
                <p>Se ainda não possui acesso, entre em contacto com a nossa área técnica apartir do site <a href="https://mastercontas.com/">Master Contas</a> e faça a sua solicitação da inscrição</p>

                <Form>
                    <ButtonItem >
                        <ButtonOptions
                            width={'100%'}
                            type={'default'}
                            useSubmitBehavior={true}
                            onClick={signOut}
                        >
                            <span className="dx-button-text">Fazer Login </span>
                        </ButtonOptions>
                    </ButtonItem>
                </Form>

            </div>

            <div className={'img'}>
                { checkWindowsSize() }
            </div>

        </div>
    );
}