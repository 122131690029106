import React from 'react'; 
import { Lista } from '../../../components/Lista';

export function AmortizacoesReintegracoes() {

  const items = [
    [
      { text: 'Clicar na opção “Conta” e criar a conta integradora do Ativo' },
      { text: 'Com o cursor selecionado abaixo da conta criada, deve clicar em “Subconta” e adicionar todos os dados da alínea de preenchimento do ativo imobilizado.' },
      { text: 'Após a criação das “Conta”, “Subconta” e “Preenchimento das informações das subcontas” o usuário deve clicar na opção gravar, para salvar as informações do mapa.' }
    ],
    [
      { text: 'Clicar na opção “Atualizar”' },
      { text: 'Clicar na opção “Dados do balancete”' },
      { text: 'Gravar' },
      { text: 'Introduzir as descrições corretas e o valor analítico dos ativos imobilizados' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Amortizações e Reintegrações</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O mapa de amortizações e reintegrações apresenta a descrição analítica dos ativos imobilizados, a vida útil,
            as depreciações dos exercícios e acumuladas, o valor residual e as reintegrações, que são os valores que foram
            adicionados ao ativo imobilizado devido a reavaliações ou reformas.</p>
          <p>No sistema mastercontas, o mapa de amortizações e reintegrações pode ser preenchido automaticamente com a
            automação do balancete, ou pelo preenchimento manual.  </p><br />
          <ul>
            <li><strong>a) Preenchimento Manual</strong>
              <p> Para o preenchimento manual, o usuário deve: </p>
              <Lista isOrdered={false} isNumeric={true} items={items[0]} />
            </li>
            <li><strong>b) Preenchimento Automático </strong>
              <p> O preenchimento automático é a rotina de busca de informações das “Conta” e “ Subconta” do ativo imobilizado no balancete final importado na aplicação. Para gerar a rotina da automação, deve seguir os seguintes passos:  </p>
              <Lista isOrdered={false} isNumeric={true} items={items[1]} />
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
};
