import React from 'react';
import './styles.scss';

export function MapasIntenos() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Mapas Intenos</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A funcionalidade do Mapas Internos é uma ferramenta de base na preparação do dossier fiscal e personalização de acordo com a complexidade funcional de cada empresa para facilitar o processo do desenvolvimento do dossier fiscal e necessidades de criação de mapas.</p>
          <p>Esta sessão, foi incorporada com o recurso “EXCEL” da Microsoft ao sistema Matercontas.</p>
          <p>Com a integração do Excel, os usuários podem utilizar as capacidades avançadas de formatação, fórmulas, e recursos de automação disponíveis no Excel para criar e personalizar os mapas, cálculos, tabelas e gráficos do dossier fiscal.</p>
          <p>A utilização do Excel também oferece a possibilidade de importar dados de outras fontes, como sistemas contabilísticos ou bancos de dados, facilitando a integração de informações relevantes no dossier fiscal.</p>

        </div>
      </div>
    </React.Fragment>
  )
}
