

import React from 'react'; 
import { Lista } from '../../../components/Lista';

export function BalancedScorcard() {

    const items = [
        [
            { text: 'Perspetivas de desempenho (Financeira; Clientes; Processos; Crescimento) ' },
            { text: 'Síntese da Missão das perfectivas ' },
        ],
        [
            { text: 'Peso ' },
            { text: 'Objetivo ' },
            { text: 'Real ' },
            { text: '% Objetivo ' },
            { text: 'Índice de performance ' }
        ],
        [
            { pointer: '•	Gravar ', text: '- Salvar Informações' },
            { pointer: '•	Imprimir ', text: '- Funcionalidade de visualização PDF para impressão. ' },
            { pointer: '•	Adicionar ', text: '- Funcionalidade de adicionar linha no formulário. ' },
            { pointer: '•	Remover ', text: '- Funcionalidade de remover linha no formulário. ' },
            { pointer: '•	Comentário Técnico ', text: '- Desenvolvimento de texto explicativo.' }
        ],
        [
            { text: 'Definir o peso de cada perspetiva na análise do BSC' },
            { text: 'Criar o objetivo estratégico de cada perfectiva' },
            { text: 'Identificar os fatores críticos de sucesso ' },
            { text: 'Varias-Chaves para realização dos fatores críticos ' }
        ]
    ]

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Balanced Scorcard</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <p>O BSC é uma ferramenta de gestão estratégica que permite monitorar o desempenho da empresa em diferentes perspetivas, como financeira, clientes, processos internos e aprendizado e crescimento.</p>
                    <p>Com o Mastercontas, é possível criar objetivos, indicadores de desempenho, metas e iniciativas estratégicas para cada perspetiva do BSC.</p>
                    <p>Além disso, é possível monitorar o progresso das iniciativas e gerar relatórios e análises para auxiliar na tomada de decisão e no monitoramento do desempenho da empresa.</p>
                    <p>A sessão do BSC esta subdividida em:</p>
                    <Lista isOrdered={true} items={items[0]} />
                    <p><strong>1.	Perspetivas de desempenho</strong></p>
                    <p>O BSC, por ser uma ferramenta de indicadores a preferência do utilizador, deve ser o utilizador a criar as métricas de análises.</p>
                    <p>Após a definição das métricas, o usuário deverá indicar:</p>
                    <Lista isOrdered={true} items={items[1]} />
                    <p><strong>Funcionalidade do formulário: </strong> </p>
                    <Lista isOrdered={false} items={items[2]} />
                    <p><strong>2.	Síntese da Missão das perspetivas</strong></p>
                    <p>No formulário da síntese da missão das perspetivas, o sistema permite: </p>
                    <Lista isOrdered={true} items={items[3]} />
                </div>
            </div>
        </React.Fragment>
    )
}
