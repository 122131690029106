import React from 'react';
import { Lista } from '../../../../components/Lista';

const items = [
  [
    { text: 'Aceder ao Administrador;' },
    { text: 'Aceder ao Parâmetros;' },
    { text: 'Clicar na Demonstrações Financeiras;' },
    { text: 'Clicar na Demonstração Fluxo de Caixa;' }
  ],
  [
    { text: 'SC - Saldo Credor' },
    { text: 'SD - Saldo Devedor' },
    { text: 'VD - Valor Movimento Devedor' },
    { text: 'VC - Valor do Movimento Credor' },
    { text: 'MD - Valor Movimento Devedor' },
    { text: 'MC - Valor Movimento Credor' },
  ],
  [
    { text: 'Selecionar a nota que pretende refletir.' },
    { text: 'Em “Copiar de”: Selecionar a empresa em que pretende copiar o parâmetro.' },
    { text: 'Em “Refletir Em”: Selecionar a empresa em que pretende refletir o parâmetro.' },
    { text: 'Clicar em gravar após a reflexão.' },
  ],
  [
    { text: 'FIN - Final", "REG - Movimentos do período.' },
    { text: 'RAN - Movimentos do período anterior' },
    { text: 'INC - Final do período anterior' }
  ],
  [
    { text: 'Aceder ao Administrador; ' },
    { text: 'Aceder ao Parâmetros;' },
    { text: 'Clicar na Demonstrações Financeiras;' },
    { text: 'Clicar na Demonstração Fluxo de Caixa; ' }
  ],
  [
    { text: '(Balancete + Tipo do Balancete + Tipo do Valor + Conta) ' },
    { text: 'Exemplo: BT-FIN-VD-31121' }
  ],
  [
    { text: 'FIN - Final ' },
    { text: 'REG - Movimentos do período. ' },
    { text: 'RAN - Movimentos do período anterior ' },
    { text: 'INC - Final do período anterior ' }
  ],
  [
    { text: 'SC - Saldo Credor ' },
    { text: 'SD - Saldo Devedor ' },
    { text: 'VD - Valor Movimento Devedor ' },
    { text: 'VC - Valor do Movimento Credor ' },
    { text: 'MD - Valor Movimento Devedor ' },
    { text: 'MC - Valor Movimento Credor' }
  ],
  [
    { text: 'A 2.ª alínea da DFC - método direito “ Recebimento de Clientes”, deve buscar no balancete do período o valor do movimento a débito da conta 31. Parâmetro: BT-REG-SD-31' },
    { text: 'A 2.ª Alínea da DFC - método direito “Juros e Proveitos similares” deve buscar o somatório dos valores a crédito das contas 661 + 662 + 663 Do balancete do período. Parâmetro: BT-REG-VC-661 (+) BT-REG-VC-662 (+) BT-REG-VC-663' }
  ],
  [
    { text: 'Selecionar a nota que pretende refletir.' },
    { text: 'Em “Copiar de”: Selecionar a empresa em que pretende copiar o parâmetro. ' },
    { text: 'Em “Refletir Em”: Selecionar a empresa em que pretende refletir o parâmetro. ' },
    { text: 'Clicar em gravar após a reflexão' }
  ]
]

export function DemonstracoesContabilisticas() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Demonstrações Contabilísticas</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p className="sub-title">DRE - Funções</p>
          <p>
            Atendendo que cada empresa tem a sua própria realidade de
            especialização do código de contas, o preenchimento da Demonstração
            de Resultados no sistema mastercontas é de parametrização aberta e
            interna.
          </p>
          <p>
            Aquando da criação da base de dados, o utilizador terá acessos
            parâmetros padrão: PGG e SAP.
          </p>
          <p>
            Os parâmetros padrão são passiveis de edição, anulação e reflecção
            em outras empresas criadas no sistema.
          </p>
          <p>
            Isso significa que o usuário pode personalizar o código de contas de
            acordo com sua própria realidade e necessidades específicas,
            utilizando os parâmetros padrão como base.
          </p>
          <p>
            <strong>
              Como aceder os parâmetros das Demonstração de Resultados por
              Função?
            </strong>
          </p>
          <Lista isOrdered={true} items={items[0]} />
          <p>
            <strong>Como editar ou criar um parâmetro?</strong>
          </p>
          <p>Os parâmetros foram desenvolvidos com a mascara padrão: </p>
          <p>(Balancete + Tipo do Balancete + Tipo do Valor + Conta) </p>
          <p>Exemplo: BT-FIN-VD-31121</p>

          <p>
            <strong>Acrónimos das descrições dos Balancetes:</strong>
          </p>
          <Lista isOrdered={true} items={items[3]} />

          <p>
            <strong>Acrónimos das descrições Valores:</strong>
          </p>
          <Lista isOrdered={true} items={items[1]} />

          <p>Exemplos para a formação de um parâmetro da DRT por função: </p>
          <p>
            A 2.ª alínea do DR por função “Prestações de Serviços”, deve buscar
            no balancete do período o valor do movimento a crédito da conta 62.
          </p>
          <p>Parâmetro: BT-REG-SC-62</p>

          <p>
            Além disso, as alterações feitas nos parâmetros são refletidas
            automaticamente em outras empresas o que agiliza e facilita o
            processo da elaboração do relatório.
          </p>
          <p>
            É importante lembrar que as mudanças devem ser feitas com cuidado e
            atenção para garantir a integridade e consistência do código de
            contas.
          </p>

          <p><strong>Como refletir o parâmetro de uma empresa para outra?</strong></p>
          <Lista isNumeric={true} items={items[2]} />
          <p>
            A sessão “conformidade do relatório” permite o utilizador validar a
            conformidade nos dados/informações entre: Balancetes, Notas às
            Contas e As demonstrações financeiras.
          </p>
          <p>
            Atendendo que o preenchimento das Demonstrações Financeiras, são
            efetuadas pelos dados das notas, e o preenchimento daos dados das
            notas são efecuados apartir das informações dos balancetes, o
            relatório de conformidade deve apresentar-se a 100%.
          </p>
          <p>Como interpretar uma incormidades:</p>
          <hr />

          <p className="sub-title">DFC- Direto & Indireto</p>

          <p>Atendendo que cada empresa tem a sua própria realidade de especialização do código de contas, o preenchimento da Demonstrações do Fluxo de Caixa no sistema mastercontas é de parametrização aberta. </p>
          <p>Aquando da criação da base de dados, o utilizador terá acessos parâmetros padrão: PGG e SAP. </p>
          <p>Os parâmetros padrão são passiveis de edição, anulação e reflecção em outras empresas criadas no sistema.  </p>
          <p>Isso significa que o usuário pode personalizar o código de contas de acordo com sua própria realidade e necessidades específicas, utilizando os parâmetros padrão como base.</p>
          <p><strong>Como aceder os parâmetros das Demonstrações do Fluxo de Caixa?</strong></p>
          <Lista isNumeric={true} items={items[4]} />
          <p><strong>Como editar ou criar um parâmetro? </strong></p>
          <p>Os parâmetros foram desenvolvidos com a mascara padrão: </p>
          <Lista isOrdered={true} items={items[5]} />
          <p><strong>Acrónimos das descrições dos Balancetes: </strong> </p>
          <Lista isOrdered={true} items={items[6]} />
          <p><strong>Acrónimos das descrições Valores: </strong> </p>
          <Lista isOrdered={true} items={items[7]} />
          <p><strong>Exemplos para a formação de um parâmetro da DFC: </strong> </p>
          <Lista isOrdered={false} items={items[8]} />
          <p>Além disso, as alterações feitas nos parâmetros são refletidas automaticamente em outras empresas o que agiliza e facilita o processo da elaboração do relatório.</p>
          <p>É importante lembrar que as mudanças devem ser feitas com cuidado e atenção para garantir a integridade e consistência do código de contas.</p>
          <p><strong>Como refletir o parâmetro de uma empresa para outra?</strong> </p>
          <Lista isOrdered={false} isNumeric={true} items={items[9]} /> 
        </div>
      </div>
    </React.Fragment>
  )
}
