import React from 'react';
import { Lista } from '../../../components/Lista';
// import { RequiredLogin } from '../../../components/RequiredLogin';

const items = [
  [
    { text: 'Aceder Gerenciamento' },
    { text: 'Clicar em listagem de empresa' },
    { text: 'Clicar na alínea da empresa pretendida ' },
    { text: 'Clicar em selecionar empresa' },
    { text: 'O sistema irá encerrar para o utilizador e após o login o remeterá para a empresa selecionada. ' }

  ]
]

export function InicializacaoEmpresa() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Inicialização Empresa</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <RequiredLogin/> */}
          <p>O sistema Mastercontas foi desenvolvido para armazenamento e utilização de múltiplas empresas.</p>
          <p>A inicialização de empresas é a função que permite o utilizador escolher a empresa que deseja trabalhar.</p>
          <p><strong>Passos para gerenciamento</strong>:</p>
          <Lista isNumeric={true} items={items[0]}/>
        </div>
      </div>
    </React.Fragment>
  )
}
