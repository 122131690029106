import React from 'react';
import { Lista } from '../../../components/Lista';
// import { RequiredLogin } from '../../../components/RequiredLogin';


const items = [
  [
    { text: 'Configuração Sever' },
    { text: 'Ligação BD' }
  ],
  [
    { text: 'Aceder a administrador ' },
    { text: 'Aceder parâmetros ' },
    { text: 'Clicar no ícone Ligação extesion ' },
    { text: 'Clicar na aba configuração ' },
    { text: 'Adicionar o Sever mane' },
    { text: 'Adicionar o a authentication do login' },
    { text: 'Adicionar o a authentication da password' }
  ],
  [
    { text: 'No formulário da ligação extesion aceder a aba ligação. ' },
    { text: 'Selecionar a empresa no sistema Mastercontas que pretende fazer a ligação' },
    { text: 'Selecionar a empresa no Sistema de Extesion a ligar ' },
    { text: 'Clicar na opção ligar. ' },

  ]
]

export function LiqgacaoExtesion() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Ligação Extesion</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <RequiredLogin/> */}

          <p>O sistema mastercontas foi desenvolvido de forma vertical para interagir com diversas soluções de sistemas de contabilidade, por via importação de ficheiros em diversos formatos, ou pela ligação extesion. </p>
          <p><strong>A ligação extesion</strong> – Corresponde a sincronização entre o banco de dados da aplicação mastercontas e o banco de dados onde o utilizador armazena os movimentos ou lançamentos contabilísticos. </p>
          <p>Esta ligação é estabelecida de forma segura e automática, permitindo ao utilizador ter acesso às informações dos <a className='txt-link' href="#/relatorioContas/balancetes">Balancetes</a> em tempo real, sem a necessidade de efetuar transferências ou upload de ficheiros manualmente.</p>
          <p><strong>Para realizar a ligação extesion deve realizar duas operações, nomeadamente:</strong> </p>
          <Lista isOrdered={true} items={items[0]} />

          <p><strong>Configuração Server</strong>: Ligação entre dois bancos de dados do SQL Sever</p>
          <p><strong>Ligação BD</strong>: Sincronização entre BDs Especificas, isto é, Empresa A na aplicação XPTO com a Empresa A na aplicação Mastercontas.</p>
          <p><strong>Como realizar a configuração Sever:</strong></p>
          <Lista isOrdered={false} isNumeric={true} items={items[1]} />

          <strong>
            <p>Como realizar a Ligação BD:</p>
            <p>Apos a configuração sever, deve:</p>
          </strong>
          <Lista isOrdered={false} isNumeric={true} items={items[2]} />
          <p><strong>Nota fundamental</strong>: A eficácia da ligação depende da adoção de padrões comuns na identificação das empresas em cada uma das bases, isto é, o mesmo código de indicador da BD.</p>
          <p>Dessa forma, é importante que as empresas sejam registradas com os mesmos indicadores nas diferentes bases de dados, garantindo que o código interno atribuído a ela seja único e facilmente identificável em todas as fontes de informação.</p>


        </div>
      </div>
    </React.Fragment>
  )
}
