import React from 'react'; 
import { Lista } from '../../../../components/Lista';
// import { RequiredLogin } from '../../../../components/RequiredLogin';

const items = [
  [
    { text: 'Aceder ao Administrador' },
    { text: 'Na sessão “ACESSOS” clicar em Acessos ' },
    { text: 'Selecionar o Projeto “Contabilidade”' },
    { text: 'Selecionar a Empresa. ' },
    { text: 'Selecionar a empresa que pretende proteger' },
    { text: 'Proteger a empresa' }
  ]
]

export function Acessos() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Acessos a Empresa</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          {/* <RequiredLogin/> */}
          <p>A restrição de acesso às empresas é um recurso importante para garantir a segurança das informações financeiras de cada empresa e manter a privacidade e a confidencialidade dos dados financeiros. </p>
          <p><strong>Como proteger o acesso a empresa?</strong></p> 
          <Lista isNumeric={true} items={items[0]}/>
          <p>O Sistema Mastercontas também permite a definição de permissões de acesso por nível de usuário, de modo que cada usuário possa ter acesso apenas às informações e funcionalidades necessárias para realizar suas tarefas.</p>
          <p>Dessa forma, o Sistema Mastercontas oferece um conjunto de recursos de segurança e controle de acesso que ajudam a garantir a privacidade e a proteção das informações financeiras da empresa, oferecendo assim uma camada adicional de segurança e tranquilidade para os usuários.</p>

        </div>
      </div>
    </React.Fragment>
  )
}
