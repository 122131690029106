import React from "react"; 

export function GestaoExercicios() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Gestão Exercícios</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2>O exercício na aplicação Master Contas</h2>
          <p>
            O exercício é o período em que uma empresa elabora os relatórios da
            sua atividade. Na aplicação Master Contas, existem três disposições
            para os exercícios:
          </p>
          <ul>
            <li>Aberto - Exercício operacional</li>
            <li>
              Em curso – Exercício em fase de validação pelos usuários com
              perfis técnico de Contas e Auditores.
            </li>
            <li>
              Fechado - Exercício não passível de alterações nas principais
              funcionalidades.
            </li>
          </ul>
          <p><strong>1 - Como criar um novo exercício?</strong></p>
          <p>
            Para criar um novo exercício na aplicação Master Contas, siga os
            seguintes passos:
          </p>
          <ol>
            <li>
              Acesse GERENCIAMENTO {">"} Exercícios ou ADMINISTRADOR {">"}{" "}
              Parâmetros {">"} Parâmetros Exercício.
            </li>
            <li>Clique em "Novo".</li>
            <li>
              Em seguida, a aplicação abrirá o assistente de criação do
              exercício.
            </li>
            <li>Preencha os campos do formulário do editor.</li>
            <li>Clique em "Próximo".</li>
            <li>
              O sistema avançará para a criação do novo exercício. Caso queira
              carregar os dados dos exercícios anteriores no novo exercício,
              selecione a opção "Carregar exercício".
            </li>
          </ol>
          <p><strong>2 - Como editar um exercício?</strong></p>
          <p>
            Para editar um exercício na aplicação Master Contas, siga os
            seguintes passos:
          </p>
          <ol>
            <li>
              Acesse GERENCIAMENTO {">"} Exercícios ou ADMINISTRADOR {">"}{" "}
              Parâmetros {">"} Parâmetros Exercício.
            </li>
            <li>Clique em "Alterar".</li>
            <li>
              Em seguida, a aplicação abrirá o assistente de edição do
              exercício.
            </li>
            <li>Preencha os campos que deseja editar no formulário.</li>
            <li>Clique em "Confirmar".</li>
          </ol>
          <p><strong>3 - Como selecionar um exercício? </strong></p>
          <p>
            Para selecionar um exercício na aplicação Master Contas, siga os
            seguintes passos:
          </p>
          <ol>
            <li>
              Acesse GERENCIAMENTO {">"} Exercícios ou ADMINISTRADOR {">"}{" "}
              Parâmetros {">"} Parâmetros Exercício.
            </li>
            <li>Clique na alínea do exercício pretendido.</li>
            <li>Clique em "Selecionar".</li>
          </ol>
          <p>
            <strong>Notas sobre a gestão do exercício:</strong>
          </p>
          <p>
            Por rotina de segurança das informações, todas as operações
            realizadas de afetação aos exercícios são gravadas em um formulário
            de histórico, onde se descreve o tipo de operação realizada e o
            usuário que procedeu. Essa medida de segurança visa garantir a
            integridade dos dados e informações da empresa, possibilitando uma
            análise detalhada de eventuais irregularidades ou inconformidades
            nas operações realizadas. Além disso, essa documentação pode ser
            utilizada como prova em processos administrativos.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
