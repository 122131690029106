import React from "react";
import "./styles.scss";
import { Lista } from "../../../../components/Lista";

export function DeclaracoesAnuais() {

  const items = [
    [
      { text: 'Informação Gerais (Informações Preenchidas com os dados de criação da empresa)' },
      { text: 'Demonstração de Resultados – (Quadro contendo os valores do apuramento do imposto, preenchido através do Evento Principal ou preenchimento de forma Manual.' },
      { text: 'Anexo A' }
    ],
    [
      { pointer: 'GRAVAR', text: 'Informações preenchidas;' },
      { pointer: 'IMPRIMIR', text: 'Funcionalidade de Visualização;' },
      { pointer: 'PREENCHIMENTO', text: '(Validação da forma do preenchimento)' },
      { pointer: 'PROCESSMENTO', text: 'Efetua o preenchimento dos dados dos formulários com automações com base nos parâmetros.' },
      { pointer: 'ANULAÇÃO', text: 'Eliminação do preenchimento dos dados dos formulários;' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Demostrações Anuais</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div> 
            <p>
              A declaração modelo 1 é obrigatória para todas as empresas com
              contabilidade organizada, independentemente do seu volume de
              negócios. O preenchimento deste documento é feito com base no
              Relatório e Contas anual Evento Principal da empresa. A Declaração
              Anual esta dividida:
            </p>
            <Lista isOrdered={true} items={items[0]} />
            <p>Funcionalidades dos Mapas/Formulários Declaração Anual:</p>
            <Lista isOrdered={false} isNumeric={false} items={items[1]} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
