import React from 'react';
import './styles.scss';
import notify from 'devextreme/ui/notify';

export function Page404() {
    const img = require('../../assets/img/pagenotfound.png');

    function callNotyfication() {
        notify({ message: "Este utilizador não tem permições de visualizar este conteudo!", width: 300, shading: false }, "info", 1000);
    }

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <div className={'logos-container'}>
                        <img src={img} width={250} alt="MasterContas"/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}