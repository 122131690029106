//import Item from '../assets/icons/icons8_home_16.png'

export const navigation = [
  { text: 'Pagina Inicial', path: '/home', icon: 'home' }, 
  // { text: 'Guia Formação', icon: 'verticalaligntop', path: '/guiaFormacao' },
  { text: 'Evento Principal', path: '/eventoPrincipal', icon: 'pulldown' }, 
  { text: 'Contabilidade',  icon: 'chart', path: '/contabilidade', items: [
      { text: 'Planos de Contas', icon: 'textdocument' , path: '/contabilidade/planosContas'},
      { text: 'Lançamentos', icon: 'textdocument' , path: '/contabilidade/lancamentos'},
      { text: 'Movimentos', icon: 'folder', items: [
          { text: 'Integrações', icon: 'textdocument' , path: '/contabilidade/movimentos/integracoes'},
          { text: 'Importações', icon: 'textdocument', path: '/contabilidade/movimentos/importacoes'},
          { text: 'Especializações', icon: 'textdocument' , path: '/contabilidade/movimentos/especializacoes'}
        ] 
      },
      { text: 'Operações Correntes', icon: 'folder', items: [
          {text: 'Documento Abertura', icon: 'textdocument', path: '/contabilidade/operacoesCorrentes/documentoAbertura'},
          {text: 'Transferências Contas', icon: 'textdocument', path: '/contabilidade/operacoesCorrentes/transferenciasContas'}
        ]
      },
      { text: 'Reconcilhações', icon: 'textdocument' , path: '/contabilidade/reconcilhacoes'},
      { text: 'Resultados', icon: 'textdocument' , path: '/contabilidade/resultados'},
      { text: 'Exploração', icon: 'folder', items: [
          { text: 'Conferência Movimentos', icon: 'textdocument' , path: '/contabilidade/exploracao/conferenciaMovimentos'},
          { text: 'Analises Resultados', icon: 'textdocument' , path: '/contabilidade/exploracao/analisesResultados'},
          { text: 'Extratos Contas', icon: 'textdocument' , path: '/contabilidade/exploracao/extratosContas'},
          { text: 'Balancetes Exercícios', icon: 'textdocument', path: '/contabilidade/exploracao/balancetesExercícios'}
        ] 
      }
    ]
  },  
  { text: 'Relatório & Contas',  icon: 'textdocument' , path: '/relatorioContas', items: [
      { text: 'Balancetes', icon: 'folder', path: '/relatorioContas/balancetes', items: [
          { text: 'Inportação', icon: 'textdocument', path: '/relatorioContas/balancetes/inportacao' },
          { text: 'Atualização', icon: 'textdocument', path: '/relatorioContas/balancetes/atualizacao' },
          { text: 'Eventos Processamentos', icon: 'textdocument', path: '/relatorioContas/balancetes/eventosProcessamentos' }
        ] 
      },
      { text: 'Amort. Reintegrações', icon: 'textdocument', path: '/relatorioContas/amortizacoesReintegracoes' },
      { text: 'Notas às Contas', icon: 'textdocument', path: '/relatorioContas/notasContas' },
      { text: 'DFCs - Demostrações', icon: 'folder', path: '/relatorioContas/DFCsDemostracoes', items: [
          { text: 'Balanço Patrimonial', icon: 'textdocument', path: '/relatorioContas/demonstracoesFinanceiras/balancoPatrimonial' },
          { text: 'DRE - Natureza / Funções', icon: 'textdocument', path: '/relatorioContas/demonstracoesFinanceiras/demonstracoesResultados' },
          { text: 'DFC - Direito / Indireto', icon: 'textdocument', path: '/relatorioContas/demonstracoesFinanceiras/demonstracoesFluxoCaixa' }
        ] 
      },
      { text: 'Tributação', icon: 'folder', items: [
          { text: 'Declaração Provisória', icon: 'textdocument', path: '/relatorioContas/tributacao/declaracaoProvisoria' },
          { text: 'Demostrações Anuais', icon: 'textdocument', path: '/relatorioContas/tributacao/declaracoesAnuais' },
          { text: 'Apuramento Imposto', icon: 'textdocument', path: '/relatorioContas/tributacao/apuramentoImposto' }
        ] 
      },
      { text: 'Analises Contabilísticas', icon: 'textdocument', path: '/relatorioContas/analisesContabilisticas' },
      { text: 'Parâmetros do Relatório', icon: 'textdocument', path: '/relatorioContas/parametrosRelatorio' }  
    ]
  }, 
  { text: 'Consolidação de Contas', icon: 'selectall', path: '/consolidacaoContas'  },
  { text: 'Reporting', icon: 'print', path: '/reporting', items: [
      { text: 'Balancetes', icon: 'folder', items: [
          { text: 'Inportação', icon: 'textdocument', path: '/reporting/balancetes/inportacao' },
          { text: 'Atualização', icon: 'textdocument', path: '/reporting/balancetes/atualizacao' },
          { text: 'Eventos Processamentos', icon: 'textdocument', path: '/reporting/balancetes/eventosProcessamentos' }
        ] 
      },
      { text: 'Dem. Financeiras', icon: 'textdocument', path: '/reporting/demonstracoesFinanceiras'},
      { text: 'Relatório Financeiro', icon: 'folder' , items: [
          {text: 'Posição Patrimonial', icon: 'textdocument', path: '/reporting/relatorioFinanceiro/posicaoPatrimonial'},
          {text: 'Dem. Financeiras', icon: 'textdocument', path: '/reporting/relatorioFinanceiro/demonstracoesFinanceiras'},
          {text: 'Dem. Fluxo de Caixa', icon: 'textdocument', path: '/reporting/relatorioFinanceiro/demonstracoesFluxoCaixa'},
          {text: 'Indicadores Financeiros', icon: 'textdocument', path: '/reporting/relatorioFinanceiro/indicadoresFinanceiros'}
        ]
      },
      { text: 'Reporting', icon: 'folder' , items: [
          {text: 'Painel Reporting', icon: 'textdocument', path: '/reporting/reporting/painelReporting'},
          {text: 'Financial Analysis', icon: 'textdocument', path: '/reporting/reporting/financialAnalysis'},
          {text: 'Bussines Ativity', icon: 'textdocument', path: '/reporting/reporting/bussinesAtivity'}
        ]
      }
    ]
  }, 
  { text: 'Controller', icon: 'box', path: '/controller', items: [
      {text: 'Plano Analitico', icon: 'textdocument', path: '/controller/planoAnalitico'},
      {text: 'Centros de Custos', icon: 'textdocument', path: '/controller/centrosCustos'},
      {text: 'Custos Indústriais', icon: 'textdocument', path: '/controller/custosIndustriais'},
      {text: 'Custos Não Indústriais', icon: 'textdocument', path: '/controller/custosNaoIndustriais'},
      {text: 'Classificação Custos', icon: 'folder', items: [
          {text: 'Objecto Custo', icon: 'textdocument', path: '/controller/classificacaoCustos/objectoCusto'},
          {text: 'Volume de Produção', icon: 'textdocument', path: '/controller/classificacaoCustos/volumeProducao'},
          {text: 'Momento Exploração', icon: 'textdocument', path: '/controller/classificacaoCustos/momentoExploracao'},
          {text: 'Custo Por Função', icon: 'textdocument', path: '/controller/classificacaoCustos/custoFuncao'},
        ]
      }, 
      {text: 'Exploração', icon: 'textdocument', path: '/controller/exploracao'}
    ]
  },  
  { text: 'Analise Empresarial', icon: 'columnproperties',  path: '/analiseEmpresarial', items: [
      {text: 'Analise Textual', icon: 'textdocument', path: '/analiseEmpresarial/analiseTextual'},
      {text: 'Analise Demonstrações', icon: 'textdocument', path: '/analiseEmpresarial/analiseDemostracoes'},
      {text: 'Analise Indicadores', icon: 'textdocument', path: '/analiseEmpresarial/analiseIndicadores'}, 
      // {text: 'Váriáveis de Negócio', icon: 'textdocument', path: '/analiseEmpresarial/variaveisNegocio'},
      {text: 'Painel Gestão Indicadores', icon: 'textdocument', path: '/analiseEmpresarial/painelGestaoIndicadores'},
      {text: 'Bussines Analysis', icon: 'textdocument', path: '/analiseEmpresarial/bussinesAnalysis' //items: [
        //   {text: 'Maket Analysis', icon: 'textdocument', path: '/analiseEmpresarial/bussinesAnalysis/maketAnalysis'},
        //   {text: 'Reporting Mapa', icon: 'textdocument', path: '/analiseEmpresarial/bussinesAnalysis/reportingMapa'},
        //   {text: 'Market', icon: 'textdocument', path: '/analiseEmpresarial/bussinesAnalysis/market'}
        // ]
      },
      {text: 'Balanced Scorcard', icon: 'textdocument', path: '/analiseEmpresarial/balancedScorcard' //items: [
        //   {text: 'Perspetiva Agregada', icon: 'textdocument', path: '/analiseEmpresarial/balancedScorcard/perspetivaAgregada'},
        //   {text: 'Síntese Perspectivas', icon: 'textdocument', path: '/analiseEmpresarial/balancedScorcard/sintesePerspectivas'}
        // ]
      }
    ]
  }, 
  { text: 'Fiscalidade', icon: 'equal', path: '/fiscalidade', items: [
      {text: 'Planeamento Fiscal', icon: 'folder', path: '/fiscalidade/planeamentoFiscal', items: [
        {text: 'Planeamento Obrigações', icon: 'textdocument', path: '/fiscalidade/planeamentoFiscal/planeamentoObrigacoes'},
        {text: 'Planeamento Liquidações', icon: 'textdocument', path: '/fiscalidade/planeamentoFiscal/planeamentoLiquidacoes'},
        {text: 'Planeamento Transações', icon: 'textdocument', path: '/fiscalidade/planeamentoFiscal/planeamentoTransacoes '}
      ]}, 
      {text: 'Apuramento Impostos', icon: 'folder', items: [
        {text: 'Imposto Indústrial', icon: 'textdocument', path: '/fiscalidade/apuramentoImpostos/impostoIndustrial'},
        {text: 'Impostos Correntes', icon: 'textdocument', path: '/fiscalidade/apuramentoImpostos/impostosCorrentes'} 
      ]},
      {text: 'Declarações Fiscais', icon: 'folder', path: '/fiscalidade/declaracoesFiscais',items: [ 
          {text: 'Declaração Anual Modelo I', icon: 'textdocument', path: '/fiscalidade/declaracoesFiscais/declaracaoAnualImpostoIndustrial'},
          {text: 'Outras Declarações Fiscais', icon: 'textdocument', path: '/fiscalidade/declaracoesFiscais/outrasDeclaracoesFiscais'},
          {text: 'Declarações Mensais', icon: 'textdocument', path: '/fiscalidade/declaracoesFiscais/declaracoesMensais'},
        ]
      },
      {text: 'Dossier Fiscal', icon: 'folder', path: '/fiscalidade/dossierFiscal', items: [
          {text: 'Dossier Contabilístico', icon: 'textdocument', path: '/fiscalidade/dossierFiscal/dossierContabilistico'},
          {text: 'Documentos Legais', icon: 'textdocument', path: '/fiscalidade/dossierFiscal/documentosLegais'},
          {text: 'Documentos Gestão', icon: 'textdocument', path: '/fiscalidade/dossierFiscal/documentosGestao'},
          {text: 'Mapas Intenos', icon: 'textdocument', path: '/fiscalidade/dossierFiscal/mapasIntenos'},
          {text: 'Gestão Dossier Fiscal', icon: 'textdocument', path: '/fiscalidade/dossierFiscal/gestaoDossierFiscal'}
        ]
     }, 
    ]
  }, 
  { text: 'Fiscalizações', icon: 'search', path: '/fiscalizacoes', items: [
      {text: 'Diagnósticos Fiscais', icon: 'folder', items: [
          {text: 'Obrigações Declarativas', icon: 'textdocument', path: '/fiscalizacoes/diagnosticosFiscal/obrigacoesDeclarativas'},
          {text: 'Obrigações Contributivas', icon: 'textdocument', path: '/fiscalizacoes/diagnosticosFiscal/obrigacoesContributivas'}
        ]
      },
      {text: 'Pedido Esclarecimentos', icon: 'textdocument', path: '/fiscalizacoes/pedidoEsclarecimentos'},
      {text: 'Proc. Fiscalização', icon: 'folder', items: [
          {text: 'Documento Resposta', icon: 'textdocument', path: '/fiscalizacoes/procedimentosFiscalizacao/documentoResposta'},
          {text: 'Objectos Fiscalizações', icon: 'textdocument', path: '/fiscalizacoes/procedimentosFiscalizacao/ObjectosFiscalizacoes'}
        ]
      },
      {text: 'Execução Fiscal', icon: 'textdocument', path: '/fiscalizacoes/execucaoFiscal'},
      {text: 'Gestão Processos', icon: 'textdocument', path: '/fiscalizacoes/gestaoProcessos'},
      {text: 'Garantias Embargo', icon: 'textdocument', path: '/fiscalizacoes/garantiasEmbargo'},
      {text: 'Liquidações Tributárias', icon: 'textdocument', path: '/fiscalizacoes/liquidacoesTributarias'}
    ]
  }, 
  { text: 'Auditoria Revisão Contas', icon: 'bookmark', path: '/auditoriaRevisaoContas'  },
  { text: 'Gerenciamento', icon: 'toolbox', path: '/gerenciamento', items: [
      { text: 'Inicialização Empresas', icon: 'textdocument' , path: '/gerenciamento/inicializacaoEmpresa'},
      { text: 'Gestão Correspondência', icon: 'textdocument', path: '/gerenciamento/gestaoConrrespondecia'},
      { text: 'Gestão Tarefas', icon: 'textdocument', path: '/gerenciamento/gestaoTarefas'},
      { text: 'Memorandos e Ocorrências', icon: 'textdocument', path: '/gerenciamento/memorandoOcorrencia' },
      { text: 'Calendário de Gestão', icon: 'textdocument', path: '/gerenciamento/calendarioGestao' },
      { text: 'Gestão Exercícios', icon: 'textdocument', path: '/gerenciamento/gestaoExercicios'}
    ]
  },
  { text: 'Projectos', icon: 'mediumiconslayout', path: '/projectos'  },
  { text: 'Parâmetros', icon: 'box', path: 'parametros', items: 
    [ 
      {text: 'Configuração Empresa', icon: 'textdocument', path: '/parametros/configuracaoEmpresa'},
      {text: 'Gestão Exercícios', icon: 'textdocument', path: '/parametros/gestaoExercicios'},
      {text: 'Ligação Extesion', icon: 'textdocument', path: '/parametros/liqgacaoExtesion'},
      {text: 'Fundamentos do Dossier', icon: 'textdocument', path: '/parametros/fundamentosDossier'},
      {text: 'Conformidades', icon: 'folder', items: [
          {text: 'Balancetes', icon: 'textdocument', path: '/parametros/conformidades/balancetes'},
          {text: 'Contabildiade', icon: 'textdocument', path: '/parametros/conformidades/contabildiade'},
          {text: 'Relatório', icon: 'textdocument', path: '/parametros/conformidades/relatorio'}
        ]
      },
      {text: 'Auto. Notas às Contas', icon: 'folder', items: [
          {text: 'Automações', icon: 'textdocument', path: '/parametros/automacoesNotasContas/automacoes'},
          {text: 'Notas Especializadas', icon: 'textdocument', path: '/parametros/automacoesNotasContas/notasEspecializadas'}
        ]
      },
      {text: 'Automações', icon: 'folder', items: [
          {text: 'Dem. Contabilísticas', icon: 'textdocument', path: '/parametros/automacoes/demonstracoesContabilisticas'},
          {text: 'Relatório Financeiro', icon: 'textdocument', path: '/parametros/automacoes/relatorioFinanceiro'},
          {text: 'Apuramento Imposto', icon: 'textdocument', path: '/parametros/automacoes/apuramentoImposto'},
          {text: 'Diagnósticos Fiscais', icon: 'textdocument', path: '/parametros/automacoes/diagnosticosFiscais'},
        ]
      },
    ] 
  }, 
  { text: 'Administrador', icon: 'preferences', path: '/administrador', items: [
    {text: 'Empresa', icon: 'folder', items: [
        {text: 'Criação', icon: 'textdocument', path: '/administrador/empresa/criacao'},
        {text: 'Configuração', icon: 'textdocument', path: '/administrador/empresa/configuracao'}
      ]
    },
    {text: 'Configuração Sistema', icon: 'folder', items: [
        {text: 'Atualizações Sistema', icon: 'textdocument', path: '/administrador/configuracaoSistema/atualizacao'},
        {text: 'Configurações Sistema', icon: 'textdocument', path: '/administrador/configuracaoSistema/licenciamento'}
      ]
    },
    {text: 'Controlo', icon: 'folder', items: [
        {text: 'Perfis', icon: 'textdocument', path: '/administrador/controlo/perfis'},
        {text: 'Acessos a Empresa', icon: 'textdocument', path: '/administrador/controlo/acessos'},
        {text: 'Utilizadores', icon: 'textdocument', path: '/administrador/controlo/utilizadores'},
        {text: 'Restrições Exercício', icon: 'textdocument', path: '/administrador/controlo/restricoes'}
      ]
    },
    {text: 'Segurança', icon: 'folder', items: [
        {text: 'Backup', icon: 'textdocument', path: '/administrador/seguranca/backup'},
        {text: 'Restore', icon: 'textdocument', path: '/administrador/seguranca/restore'},
        {text: 'Manutenção', icon: 'textdocument', path: '/administrador/seguranca/manutencao'},
        {text: 'Serviços', icon: 'textdocument', path: '/administrador/seguranca/servicos'}
      ]
    },
    {text: 'Tabelas', icon: 'textdocument', path: '/administrador/tabelas'},
    {text: 'Comunicações', icon: 'textdocument', path: '/administrador/comunicacoes'},
  ]
  }, 
  { text: 'Portal de Suporte', icon: 'verticalaligntop' , path: '/portalSuporte'},
  { text: 'Sistema', icon: 'verticalaligntop', path: '/sistema' } 
];

//npx devextreme ass view Administrador --icon=preferences        
//{text: '', icon: '', path: ''}