import React from "react";
import { Lista } from "../../../components/Lista";

export function NotasContas() {

  const items = [
    [
      { text: 'Notas às demonstrações financeiras;' },
      { text: 'Notas de Suporte à Informação Contabilística;' },
      { text: 'Notas explicativas;' }
    ],
    [
      { pointer: 'GRAVAR', text: ' - informações preenchidas' },
      { pointer: 'IMPRIMIR', text: ' - Funcionalidade de Visualização' },
      { pointer: 'NOTAS', text: ' - Texto explicativo.' },
      { pointer: 'CONSULTAR', text: ' - Visualização / do preenchimento dos valores de forma manual e automatizada bem como os parâmetros de preenchimento das notas.' },
      { pointer: 'PROCESSAR', text: ' - Efetua o preenchimento dos dados dos formulários com automações com base nos parâmetros.' },
      { pointer: 'ANULAR', text: ' - Eliminação do preenchimento dos dados dos formulários com automações com base nos parâmetros' },
      { pointer: 'IDIOMA', text: ' - Visualização da nota em Linguagem Estrangeiras como EN – Inglês, FR - Francês.' },
      { pointer: 'MOEDA', text: ' - Visualização da nota em outras Moedas como USD- Dólar, EUR - Euro' },
      { pointer: 'UNIDADES', text: ' - Visualização da grandeza de valores como Milhões, Dezenas de Milhares, Centenas de Milhares, Milhões, Mil Milhões.' },
      { pointer: 'CONTEXTO', text: ' - Permite a importação e a exportação do Template' }
    ],
    [
      { text: 'Notas Formulário / Com parâmetros Automações.' },
      { text: 'Notas Formulários / Preenchimento Manual' },
      { text: 'Notas Texto (1,2,3)' },
      { text: 'Notas Texto Simples (16, 36.3 ,37.2 ,38.2 , 39.2 , 40.2 , 41.2, 42, 43, 44 )' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Notas às Contas</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>

          <p>
            As Notas às Contas são importantes para fornecer informações
            adicionais e relevantes sobre a situação financeira da empresa,
            permitindo aos usuários das Demonstrações Financeiras compreender
            melhor os dados apresentados.
          </p>
          <p>É importante ressaltar que as Notas
            seguem uma numeração sequencial definida no PGC, sendo que nem todas
            elas são relevantes para todas as empresas. As omissões das Notas
            não significam necessariamente que a empresa está sonegando
            informações relevantes, mas sim que elas não são aplicáveis ou
            pertinentes para a empresa em questão.
            </p>

          <p><strong>Eles também são conhecidos como:</strong></p>
          <Lista isOrdered={true} items={items[0]} />
          <p>
            As notas do Relatório e Contas são geradas automaticamente quando os
            balancetes são carregados ao sistema e processados no evento
            principal.
          </p>
          <p>Funcionalidades dos formulários das notas às Contas:</p>
          <Lista isOrdered={false} items={items[1]} />
          <p>A disposição das notas às contas no sistema estão subdivididas:</p>
          <Lista isOrdered={true} items={items[2]} />
        </div>
      </div>
    </React.Fragment>
  );
}
