import React from "react";
import { Lista } from "../../../components/Lista";

const items = [
  [
    { text: 'Tabelas do Sistema' },
    { text: 'Tabelas da Empresa' },
  ],
  [
    { text: 'Aceder ao Administrador.' },
    { text: 'Aceder ao Menu Tabelas.' },
    { text: 'Selecionar a sessão da tabela. ' },
    { text: 'Selecionar a tabela.  ' },
    { text: 'Clicar em novo.' },
    { text: 'Preencher o código sequencial e a descrição.  ' },
    { text: 'Clicar em gravar.' },
  ],
  [
    { text: 'Selecionar a nota que pretende refletir.' },
    { text: 'Em “Copiar de”: Selecionar a empresa em que pretende copiar o parâmetro.' },
    { text: 'Em “Refletir Em”: Selecionar a empresa em que pretende refletir o parâmetro.' },
    { text: 'Clicar em gravar após a reflexão.' },
  ],

  [{ text: 'FIN - Final", "REG - Movimentos do período.' }, { text: 'RAN - Movimentos do período anterior' }, { text: 'INC - Final do período anterior' },]
 
];

export function Tabelas() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Tabelas</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            Na sessão tabelas do sistema Master contas, é possível gerenciar
            todas as tabelas utilizadas no software.
          </p>
          <p>
            O sistema de tabelas estão repartidos em duas sessões principais:
          </p>
          <p> <b>   <Lista isNumeric={false} isOrdered={true} items={items[0]} /></b></p>
          <p>
            As tabelas do Sistema são compartilhadas por todas as empresas que
            utilizam o sistema, como a sessão de tabelas AGT, SEP, GERAIS. E
            existem também as tabelas específicas de cada empresa, que são
            criadas e gerenciadas individualmente por cada empresa.
          </p>
          <p><b>Como adicionar campos em uma tabela? </b></p>
          <Lista isNumeric={true} isOrdered={false} items={items[1]} />
          <p>O mesmo editor de gravação, permite realizar <b>atualização</b> e <b>eliminação</b> de dados constantes nas tabelas. </p>
          <p>O gerenciamento adequado dessas tabelas é essencial para que o sistema funcione de forma correta e eficiente. </p>
        </div>
      </div>
    </React.Fragment>
  );
}
