import React from 'react';
import './styles.scss';
import { RequiredLogin } from '../../../../components/RequiredLogin';

export function DocumentoResposta() {
  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Documento Resposta</h2> */}
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <RequiredLogin/>
        </div>
      </div>
    </React.Fragment>
  )
}
