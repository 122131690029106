
 import './styles.scss';


interface props {
    filename: string;
    alt: string; 
}
  
/** 
 * @export
 * @param {props} {  
*   //* filename => recebe o nome do ficheiro (nome completo, com as extenção), 
*   //* alt => recebe o nome do alternativo, caso n#ao venha nada, vai ser definido como "master contas"' 
 * }
    //! ⚠ ATT: A imagen deve estar na pasta definida como padrão "doc-master\src\assets\img", caso contrario a imagem não vai ser exibida
 * @return {  um objecto do tipo "JSX.Element" } 
 */
export function CardImage({ filename, alt = 'mastercontas' }: props) {

    const img = require(`../../assets/img/${filename}`);

    return (
        <div className='div-main'>
            <img src={img} alt={alt} />
        </div>
    );
}