import React from 'react';
import { Lista } from '../../../components/Lista';

const items = [
  [
    { pointer: '1.	Em espera: ', text: 'Tarefas que foram agendadas, mas ainda não começaram.' },
    { pointer: '2.	Em execução: ', text: 'Tarefas que estão atualmente em progresso ou sendo trabalhadas.' },
    { pointer: '3.	Pendente: ', text: 'Tarefas que aguardam alguma ação ou aprovação para serem iniciadas.' },
    { pointer: '4.	Terminada: ', text: 'Tarefas que foram concluídas com sucesso.' },
  ]
]

export function CalendarioGestao() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Calendário de Gestão</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O calendário de gestão é a funcionalidade dentro do sistema que permite aos utilizadores visualizarem as tarefas que foram agendadas pelo administrador de tarefas.</p>
          <p>Ao clicar em um dia agendado ou marcado no calendário de gestão, o sistema redimensiona a visualização para a aba "<strong>Minhas Tarefas</strong>", onde os utilizadores podem provavelmente ver as tarefas relacionadas àquele data.</p>
          <p>Dentro da aba "Minhas Tarefas", os utilizadores podem encontrar as tarefas:</p>
          <Lista isNumeric={true} items={items[0]} />
          <p>Esta organização permite que os utilizadores visualizem rapidamente o status de todas as tarefas e saibam exatamente em que fase cada tarefa se encontra. Isso facilita a gestão do fluxo de trabalho, o acompanhamento do progresso e a identificação de tarefas que requerem atenção imediata.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
