import React from 'react';
import './styles.scss';

export function DeclaracaoAnualImpostoIndustrial() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Declaração Anual Modelo I</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p><strong>Declaração Modelo I imposto industrial:</strong> É a declaração utilizada pelas empresas para reportar os seus rendimentos, despesas, lucros e prejuízos, visando o cálculo do imposto devido sobre o lucro.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
