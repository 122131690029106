import React from 'react';
import { Lista } from '../../components/Lista';

export function Fiscalidade() {

  const items = [
    [
      { pointer:'•	Planeamento fiscal: ', text: 'o sistema permite a elaboração do planeamento fiscal anual das obrigações e liquidações.' },
      { pointer:'•	Elaboração de declarações fiscais: ', text: 'o sistema pode automatizar a elaboração de declarações fiscais e outros documentos fiscais necessários, reduzindo a necessidade de preenchimento manual.' },
      { pointer:'•	Cálculo de impostos: ', text: 'o sistema pode fazer cálculos automáticos de impostos, evitando erros humanos e economizando tempo.' },
      { pointer:'•	Controle de prazos: ', text: 'o sistema pode alertar os usuários sobre prazos de entrega de declarações fiscais e pagamento de impostos, evitando multas e penalidades.' },
      { pointer:'•	Personalização: ', text: 'o sistema pode ser personalizado de acordo com as necessidades específicas da empresa, permitindo que os usuários gerenciem suas questões fiscais de forma eficiente e precisa.' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Fiscalidade</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>O módulo de fiscalidade foi desenvolvido para gerenciar todo o processo de fiscalidade em uma empresa, desde o planeamento fiscal, a elaboração do dossier fiscal, o preenchimento de declarações fiscais, e apuramentos de impostos. </p>
        <p>Este módulo foi desenvolvido em cumprimento com as normas fiscais do ordenamento fiscal angolano e é altamente recomendado para departamentos fiscais de empresas que precisam lidar com questões fiscais complexas.</p>
        <p>Algumas das características do módulo de fiscalidade podem incluir:</p>
        <Lista isOrdered={false} items={items[0]}/>
        <p>Com o módulo de fiscalidade do nosso sistema, as empresas podem garantir que suas questões fiscais sejam gerenciadas de forma adequada e de acordo com as normas fiscais do ordenamento fiscal angolano. Isso pode ajudar a evitar multas e penalidades, além de garantir uma gestão adequada e transparente.</p>
        </div>
      </div>
    </React.Fragment>
  )
}