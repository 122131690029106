import React from "react"; 

export function Relatorio() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Relatório</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            Como regra geral o preenchimento das{" "}
            <a href="">Demonstrações Financeiras</a> é efetuado com os dados das{" "}
            <a href="">Notas às Contase</a>, e o preenchimento das
            <a href="">Notas às Contase</a> são efetuados com as informações dos
            Balancetes, logo os três relatórios devem refletir os mesmos
            valores.
          </p>
          <p>
            Os parâmetros da conformidade do relatório possui duas regras de
            automações:
          </p>
          <ul>
            <li>
              <b>1 - Regra Interna</b> – Consiste na obtenção de valores das
              informações gravadas nas <a href="">Notas às Contase</a>{" "} e 
              <a href="">Demonstrações Financeiras</a>, de forma manual ou
              automatizada.
            </li>
            <li>
              <b>2 - Regra de Parâmetro</b> – Consiste na obtenção dos valores dos
              balancetes com base no parâmetro.
            </li>
          </ul>
          <p>
            Atendendo que cada empresa tem a sua própria realidade de
            especialização do código de contas, o preenchimento dos valores do
            parâmetro do balancete no sistema mastercontas é de parametrização
            aberta e interna.
          </p>
          <p>
            Aquando da criação da base de dados, o utilizador terá acessos
            parâmetros padrão: PGG e SAP.
          </p>
          <p>
            Os parâmetros padrão são passiveis de edição, anulação e reflecção
            em outras empresas criadas no sistema. Isso significa que o usuário
            pode personalizar o código de contas de acordo com sua própria
            realidade e necessidades específicas, utilizando os parâmetros
            padrão como base.
          </p>
          <p><strong>Como acessar os parâmetros do Relatório?</strong></p>
          <ol>
            <li>Aceder ao Administrador;</li>
            <li>Aceder ao Parâmetros;</li>
            <li>
              Na sessão “CONFORMIDADES” clicar em conformidades do relatório.
            </li>
          </ol>
          <p><strong>Como editar ou criar um parâmetro?</strong></p>
          <p>
            Os parâmetros foram desenvolvidos com a mascara padrão: (Balancete +
            Tipo do Balancete + Tipo do Valor + Conta)
          </p>
          <p>Exemplo: BT-FIN-VD-31121</p>
          <p><b>Acrónimos das descrições dos Balancetes:</b></p>
          <ol>
            <li>FIN – Final</li>
            <li>REG – Movimentos do período.</li>
            <li>RAN – Movimentos do período anterior</li>
            <li>INC – Final do período anterior</li>
          </ol>
          <p><b>Acrónimos das descrições Valores:</b></p>
          <ol>
            <li>SC – Saldo Credor</li>
            <li>SD – Saldo Devedor</li>
            <li>VD – Valor Movimento Devedor</li>
            <li>VC – Valor do Movimento Credor</li>
            <li>MD – Valor Movimento Devedor</li>
            <li>MC – Valor Movimento Credor</li>
          </ol>
          <p>Exemplos para a formação de um parâmetro da DRT por função:</p>
          <p>
            A 1.ª alínea da Conformidade do Relatório “Imobilizações Corpórea”,
            deve buscar o valor das contas imobilizado corpóreo + obras em
            cursos dos imobilizados corpóreos – as amortizações acumuladas dos
            imobilizados corpóreos no balancete final.{" "}
          </p>
          <p>
            Resultado do Parâmetro: BT_FIN-SD-11 (+) BT_FIN-SD-141 (-)
            BT_FIN-SC-181
          </p>
          <p>
            Além disso, as alterações feitas nos parâmetros são refletidas
            automaticamente em outras empresas o que agiliza e facilita o
            processo da validação da conformidade do relatório. É importante
            lembrar que as mudanças devem ser feitas com cuidado e atenção para
            garantir a integridade e consistência do código de contas.
          </p>
          <p><strong>Como refletir o parâmetro de uma empresa para outra?</strong></p>
          <ol>
            <li>Selecionar a nota que pretende refletir.</li>
            <li>
              Em <b> “Copiar de”</b>: Selecionar a empresa em que pretende copiar o
              parâmetro.
            </li>
            <li>
              Em <b>“Refletir Em”</b>: Selecionar a empresa em que pretende refletir o
              parâmetro.
            </li>
            <li>Clicar em gravar após a reflexão.</li>
          </ol>
        </div>
      </div>
    </React.Fragment>
  );
}
