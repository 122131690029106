
import { propsDefault } from "./interfaces";

export function Unordered({ isNumeric, items }: propsDefault) {

    const alfabetList = ['a) ', 'b) ', 'c) ', 'd) ', 'e) ', 'f) ', 'g) ', 'h) ', 'i) ', 'j) ', 'k) ', 'l) ', 'm) ', 'n) ', 'o) ', 'p) ', 'q) ', 'r) ', 's) ', 't', 'u) ', 'v', 'w) ', 'x) ', 'y) ', 'z) '];
    const numericList = ['1. ', '2. ', '3. ', '4. ', '5. ', '6. ', '7. ', '8. ', '9.', '10. ', '11. ', '12. ', '13. ', '14. ', '15. ', '16. ', '17. ', '18. ', '19. ', '20. ', '21. ', '22. ', '23. ', '24. ', '25. ', '26. ', '27. ', '28. ', '29. ', '30. '];
    var index = 0;
    const itemsList = items.map((item) => (
        <li><p><strong>{ item.pointer === undefined ? ( isNumeric ? numericList[index++] : alfabetList[index++] ) : item.pointer } </strong>{item.text} </p></li>
    )); 
    
    return (
        <ul>
            {itemsList}
        </ul>
    );
}