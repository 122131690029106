import React from 'react'; 
import { Lista } from '../../components/Lista';

export function Reporting() {

  const items = [
    [
      { pointer:'1.	Integração de dados: ', text: 'o sistema pode se integrar com outros sistemas financeiros, como sistemas ERP e de contabilidade, para coletar automaticamente dados financeiros atualizados.'},
      { pointer:'2.	Personalização: ', text: 'os usuários podem personalizar o formato do relatório financeiro de acordo com as necessidades da empresa e os requisitos regulatórios.'},
      { pointer:'3.	Automatização: ', text: 'o sistema pode automatizar a geração de relatórios e o envio para partes interessadas, incluindo gerentes, investidores e reguladores.'},
      { pointer:'4.	Análise financeira: ', text: 'o sistema pode incluir recursos de análise financeira, permitindo que os usuários analisem tendências, comparem o desempenho com objetivos e façam projeções financeiras.'}
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Reporting</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>O módulo Reporting permite aos usuários elaborar o relatório financeiro mensal com facilidade e rapidez. </p>
        <p>Ele permite que os contabilistas e analistas financeiros gerarem relatórios precisos e detalhados em um curto período de tempo, além de garantir a consistência dos dados financeiros apresentados, de forma mensal, trimestral, semestral e anual.</p>
        <p>Algumas das funcionalidades do módulo de Reporting são:</p>
        <Lista isOrdered={false} items={items[0]} />
        <p>Com o módulo de Reporting, as empresas podem fornecer relatórios financeiros precisos, oportunos e de alta qualidade para gerentes, investidores e outras partes interessadas. Isso pode ajudar a melhorar a tomada de decisões financeiras e aumentar a transparência financeira da empresa.</p>
        </div>
      </div>
    </React.Fragment>
  )
}