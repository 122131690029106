import React from 'react';
import './styles.scss';

export function DocumentosGestao() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Documentos Gestão</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A sessão <strong>"Documentos de Gestão"</strong> é o gerenciador do arquivo permanente do dossier fiscal relacionado aos <strong>documentos de gestão.</strong></p>
          <p>Essa seção específica é projetada para armazenar, organizar e gerenciar os documentos de gestão relacionados ao dossier fiscal da empresa ou organização.</p>
          <p>Os documentos de gestão podem abranger uma variedade de informações relacionadas à administração e operação do negócio. Eles podem incluir planos estratégicos, relatórios de desempenho, orçamentos, registros de reuniões da diretoria, políticas internas, relatórios de auditoria interna, avaliações de risco, entre outros documentos utilizados na gestão e governança da organização.</p>
          <p>Ao centralizar os documentos de gestão nessa sessão específica, o objetivo é facilitar o acesso rápido e eficiente a esses documentos quando necessário. Isso pode incluir a recuperação de informações para tomada de decisões, análise de desempenho, revisões internas ou externas, monitoramento de metas e objetivos, entre outros fins de gestão.</p>
        </div>
      </div>
    </React.Fragment>
  )
}
