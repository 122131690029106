import React from "react";
import { Lista } from "../../../components/Lista";

export function DemonstracoesResultados() {

  const items = [
    [
      { pointer: '1.	Balanço Patrimonial:', text: 'Apresenta o ativo, passivo e patrimônio líquido da empresa em um determinado momento, refletindo sua posição financeira.'},
      { pointer: '2.	Demonstração do Resultado do Exercício(DRE):', text: ' Mostra as receitas, custos e despesas da empresa durante um período, resultando no lucro ou prejuízo.'},
      { pointer: '3.	Demonstração do Fluxo de Caixa:', text: ' Indica as entradas e saídas de dinheiro da empresa ao longo de um período, fornecendo informações sobre a liquidez e as atividades operacionais, de investimento e de financiamento.'},

    ]
  ]

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Demonstrações Financeiras</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>

          <strong>DFCs - Demonstrações</strong>
          <p>As Demonstrações Financeiras (DFCs) são relatórios financeiros que fornecem informações sobre a posição financeira, desempenho e fluxos de caixa de uma empresa em um determinado período. </p>
          <p>As DFCs são consideradas mapas oficiais das empresas, pois são preparadas de acordo com as normas contabilísticas e regulamentações financeiras em vigor.</p>
          <p><strong>No sistema Mastercontas estão incluídas as seguintes Demonstrações:</strong></p>
          <Lista isOrdered={false} items={items[0]} />
          <p>Essas demonstrações são fundamentais para avaliar a saúde financeira de uma empresa, tomar decisões estratégicas, atender a requisitos regulatórios e fornecer informações aos investidores, credores e outras partes interessadas. Portanto, elas são consideradas mapas oficiais das empresas, refletindo sua situação financeira e desempenho.</p>

          {/*  <div>  //* Old content
          
            <p>
              As demonstrações financeiras de um negócio apresentam a posição
              financeira, patrimonial e o desempenho das atividades económicas e
              financeiras desenvolvidas, de acordo com os princípios
              contabilísticos do país ou do contexto em que a empresa opera,
              sendo que devem obedecer aos princípios de continuidade e
              comparabilidade entre exercícios.
            </p>
            <p>
              Documentos de natureza obrigatória que compõem às Demonstrações
              Financeiras:
            </p>
            <ul>
              <li>
                Demonstração de Resultados - Documento que apresenta a
                composição dos resultados obtidos, num determinando período de
                relato.
              </li>
              <li>
                Balanço - Documento que apresenta de forma qualitativa e
                quantitativa, numa determinada data, a situação patrimonial e
                financeira da empresa (Ativos, Capital Próprio e Passivo).
              </li>
              <li>
                Demonstração Fluxo de Caixa - Documento que apresenta como foi
                gerado e utilizado os recursos financeiros da empresa no
                determinado período de relato.
              </li>
            </ul>
            <p>
              As Demonstrações Financeiras são geradas automaticamente aquando
              os balancetes são carregados ao sistema e processados. Como
              Importar Balancete?
            </p>
            <h3>Funcionalidades dos Mapas/Formulários das DFCs:</h3>
            <ul>
              <li><strong>GRAVAR</strong> - Informações preenchidas.</li>
              <li><strong>IMPRIMIR</strong> - Funcionalidade de Visualização.</li>
              <li>
                <strong>DADOS</strong> – Visualização / do preenchimento dos valores inseridos de
                forma manual e automatizada bem como os parâmetros de
                preenchimento dos campos.
              </li>
              <li>
                <strong>PROCESSAR</strong> - Efetua o preenchimento dos dados dos formulários com
                automações com base nos parâmetros.
              </li>
              <li>
                <strong>ANULAR</strong> - Eliminação do preenchimento dos dados dos formulários.
              </li>
              <li>
                <strong>IDIOMA</strong> – Visualização nas linguagens EN – Inglês, FR- Francês.
              </li>
              <li>
                <strong>MOEDA</strong> – Visualização em outras Moedas como USD- Dólar, EUR –
                Euro.
              </li>
              <li>
                <strong>CONTEXTO</strong> - Funcionalidade que permite a importação e a
                exportação do Template.
              </li>
              <li>
                <strong>UNIDADES</strong> - Visualização da grandeza de valores como Milhões,
                Dezenas de Milhares, Centenas de Milhares, Milhões, Mil Milhões.
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}
