import React from 'react'; 
import { Lista } from '../../components/Lista';

export function AnaliseEmpresarial() {

  const items = [
    [
      { pointer:'•	Análise de desempenho financeiro: ', text: 'ajuda a analisar o desempenho financeiro da empresa, incluindo indicadores como margem bruta, margem líquida, retorno sobre o patrimônio líquido(ROE) e retorno sobre o investimento(ROI).' },
      { pointer:'•	Análise de liquidez: ', text: 'ajuda a analisar a capacidade da empresa de pagar suas obrigações a curto prazo, incluindo indicadores como a relação de liquidez corrente e a relação de liquidez seca.' },
      { pointer:'•	Análise de endividamento: ', text: 'ajuda a analisar o nível de endividamento da empresa, incluindo indicadores como a relação dívida / patrimônio líquido e a relação dívida / EBITDA.' },
      { pointer:'•	Análise de rentabilidade: ', text: 'ajuda a analisar a rentabilidade da empresa, incluindo indicadores como o retorno sobre o ativo(ROA) e o retorno sobre o capital empregado(ROCE).' },
      { pointer:'•	Elaboração do Balanced Scorecard: ', text: 'ajuda a definir os objetivos estratégicos da empresa e a estabelecer os KPIs relevantes para avaliar o progresso em cada área - chave.' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Analise Empresarial</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>O módulo de Análise Empresarial foi desenvolvido para ajudar a executar a análise econômica e financeira das empresas. Além da análise contabilística e financeira, o sistema também pode incluir a elaboração do Balanced Scorecard (BSC).</p>
          <p>O Sistema de Análise Empresarial pode ajudar a executar a análise financeira e econômica das empresas, fornecendo informações valiosas sobre sua saúde financeira e identificando áreas que precisam de atenção.</p>
          <p>Algumas das funcionalidades que o sistema pode incluir são:</p>
          <Lista isOrdered={false} items={items[0]} />
          <p>Com o Sistema de Análise Empresarial, os gestores e contabilistas podem ter uma visão mais clara da saúde financeira da empresa, identificando áreas que precisam de atenção e estabelecendo metas para o futuro.</p>
        </div>
      </div>
    </React.Fragment>
  )
}