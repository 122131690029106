
import React from 'react'; 
import { Lista } from '../../../components/Lista';

export function BussinesAnalysis() {

    const items = [
        [
            { pointer: '•   Reporting Mapa ', text: '- Recurso EXCEL ' },
            { pointer: '•	Market Analysis ', text: '- Recurso WORD  ' }
        ]
    ]

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Bussines Analysis</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>

                    <p>A solução Mastercontas foi desenvolvida para centralizar todas atividades do departamento contabilístico / financeiro em uma única solução de sistema e fornecer ferramentas para facilitar a criação de relatórios e análises financeiras.</p>
                    <p>A ferramenta Business Analysis é uma dessas ferramentas e permite que o usuário crie relatórios personalizados com suporte do formato EXCEL e WORD incorporado no sistema de acordo com sua preferência.</p>
                    <p>Nesta sessão é possível criar relatórios mais elaborados e detalhados, com gráficos, tabelas e outros recursos visuais a preferência do utilizador.</p>
                    <Lista isOrdered={false} isNumeric={false} items={items[0]}/>
                </div>
            </div>
        </React.Fragment>
    )
}