
import { propsDefault } from "./interfaces";

export function Ordered({ items }: propsDefault) {

    const itemsList = items.map((item) => (
        <li><p>{item.text}</p></li>
    ));

    return (
        <ol>
            {itemsList}
        </ol>
    );
}