import React from 'react';
import { Lista } from '../../components/Lista';

export function Sistema() {

  const items = [
    [
      { pointer:'Parâmetros de visualização: ', text: 'Disposição de visualização de todos os relatórios integrados ao módulo. ' },
      { pointer:'Masterfiles : ', text: 'ficheiro de transmissão eletrónica das informações contidas no módulo.' },
      { pointer:'Comunicações: ', text: 'formulário com ligações a múltiplas plataformas de submissão eletrónica das informações do sistema. ' }
    ]
  ]

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Sistema</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
        <p>A aba "Sistema" presente em cada módulo desenvolvido contém funcionalidades que permitem ao usuário personalizar a visualização de relatórios, gerenciar os arquivos eletrônicos com as informações do módulo (Masterfiles) e acessar formulários com links para diversas plataformas de comunicação eletrônica, tais como as que permitem a submissão eletrônica das informações geradas pelo sistema. Essas funcionalidades visam tornar o uso do sistema mais eficiente e prático para os usuários.</p>
        <p>Funcionalidades gerais da aba sistema: ', text: '</p>
        <Lista isOrdered={false} items={items[0]}/>
        </div>
      </div>
    </React.Fragment>
  )
}