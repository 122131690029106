import React from "react"; 

export function ConfiguracaoEmpresa() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Configuração Empresa</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            O sistema mastercontas foi desenvolvido para realizar rotinas
            distintas no ramo da contabilidade, consolidação de contas, projetos
            de auditoria e revisão de contas, e criação de projetos de
            investimentos.
          </p>
          <p>
            A criação da empresa, deve ser de acordo o tipo do projeto
            pretendido a desenvolver.
          </p>
          <p>
            Toda criação da empresa ocorre com ajuda de um formulário assistente
            de criação que valida os campos obrigatórios e facultativos de
            preenchimento.
          </p>
           <p><strong>1 - Como criar a empresa?</strong></p>
          <ol>
            <li>Aceder administrador</li>
            <li>Clicar em criar empresa</li>
            <li>Selecionar o projeto “tipo de empresa” que pretende criar</li>
            <li>
              Em seguida aplicação abrirá o formulário assistente de criação da
              empresa
            </li>
            <li>
              Deve proceder o preenchimento dos campos obrigatórios, para o
              assistente avançar na próxima sessão de criação da empresa
            </li>
            <li>
              Após a introdução correta dos dados o assistente irá sugerir a
              criação do exercício, caso avançar o assistente de criação do
              exercício será aberto, caso não avançar o processo de criação dará
              prossecução.
            </li>
            <li>
              Após a criação da empresa, clicar na opção terminar operação, e
              fazer novamente o login ao sistema.
            </li>
          </ol>
           <p><strong>2 - Como editar as informações de uma empresa?</strong></p>
          <ol>
            <li>Aceder Administrador</li>
            <li>Clicar em configuração da empresa</li>
            <li>Clicar na alínea da empresa pretendida</li>
            <li>Clicar em editar</li>
            <li>Proceder a edição pretendida nos dados da empresa</li>
            <li>Após conclusão da edição clicar em gravar</li>
          </ol>
           <p><strong>3 - Como selecionar uma empresa?</strong></p>
          <p>
            A seleção de uma empresa pode ocorrer no administrador ou no
            Gerenciamento.
          </p>
           <p><strong>Passos para selecionar a partir do administrador:</strong></p>
          <ol>
            <li>Aceder Administrador</li>
            <li>Clicar em configuração de empresa</li>
            <li>Clicar na alínea da empresa pretendida</li>
            <li>Clicar em selecionar empresa</li>
            <li>
              O sistema irá encerrar para o utilizador e após o login o remeterá
              para a empresa selecionada.
            </li>
          </ol>
          <p><strong>Passos para selecionar a partir do gerenciamento:</strong></p>
          <ol>
            <li>Aceder Gerenciamento</li>
            <li>Clicar em listagem de empresa</li>
            <li>Clicar na alínea da empresa pretendida</li>
            <li>Clicar em selecionar empresa</li>
            <li>
              O sistema irá encerrar para o utilizador e após o login o remeterá
              para a empresa selecionada.
            </li>
          </ol>
          <p><strong>Notas sobre configurações da empresa:</strong></p>
          <p>
            Por rotina de segurança das informações todas as operações
            realizadas de afetação a configuração da empresa são gravadas em um
            formulário de histórico, onde descrimina o tipo de operação
            realizada e o usuário que procedeu.
          </p>
          <p>
            Essa medida de segurança visa garantir a integridade dos dados e
            informações da empresa, possibilitando uma análise detalhada de
            eventuais irregularidades ou inconformidades nas operações
            realizadas.
          </p>
          <p>
            Além disso, essa documentação pode ser utilizada como prova em
            processos administrativos.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
