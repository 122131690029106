import React from "react"; 
import { CardImage } from "../../components/CardImage";

export function EventoPrincipal() {

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Evento Principal</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>
            O sistema <strong>Mastercontas</strong> foi concebido para efetuar o
            máximo de tarefas contabilísticas/financeiras de forma
            automatizadas.
          </p>
          <p>
            O evento principal – é a rotina onde todos os formulários com
            automações internas, de parâmetros e em cadeia são
            processados/reprocessados preenchidos/anulados.
          </p>
          <p>É a origem da obtenção de informação de forma automatizada na aplicação.</p>
          <p>
            Este evento é realizado a partir das funcionalidades Balancetes: “
            Processamentos | Anulação”.
          </p>
          <p>Com o efeito processamento, os seguintes mapas são afetados:</p>
          <ul>
            <li>
              <strong>1.</strong> Gravação| Anulação - Balancetes; <p></p>
            </li>
            <li>
              <strong>2.</strong> Preenchimento das Notas às contas; <p></p>
            </li>
            <li>
              <strong>3.</strong> Preenchimento Modelo I Imposto industrial; <p></p>
            </li>
            <li>
              <strong>4.</strong> Apuramento do Imposto Industrial; <p></p>
            </li>
            <li>
              <strong>5.</strong> Preenchimento das Demonstrações Financeiras; <p></p>
            </li>
            <li>
              <strong>6.</strong> Preenchimento das Analises Contabilísticas; <p></p>
            </li>
            <li>
              <strong>7.</strong> Preenchimento dos mapas/formulários de análise financeira
              “Indicadores e Rácios”; <p></p>
            </li>
            <li>
              <strong>8.</strong> Atualização das Conformidades do Balancete Final; <p></p>
            </li>
            <li>
              <strong>9.</strong> Atualização Conformidades do Relatório; <p></p>
            </li>
            <li>
              <strong>10.</strong> Atualização Conformidades da Contabilidade. <p></p>
            </li>
          </ul>
          <p>
            Em resumo, o evento principal é responsável por automatizar uma
            ampla variedade de tarefas contabilística e financeiras no sistema
            Mastercontas, permitindo que as informações sejam obtidas de forma
            automatizada na aplicação. <p></p>
          </p>
          <p></p>
          <center>
            {/* <p>Image[EVENTO PRINCIPAL]</p> */}
            <CardImage filename="EVENTOPRINCIPAL.jpg" alt="Evento Principal Master Contas"></CardImage>
            {/* <img src={imgEventoProncipal} alt="Evento Principal Master Contas" /> */}
          </center>
          <p></p>
          <p>
            No entanto, para além destas funcionalidades, é ainda possível criar
            eventos de processamento adicionais, com um grau de afetação
            diferente ao do formulário principal, e que permitem ao utilizador
            processar diferentes tipos de formulários de forma mais
            automatizada.
          </p>
          <p>
            Para criar estes eventos adicionais, o utilizador pode usar a
            funcionalidade “Gestão de Eventos”, disponível no módulo “Gestão”.
          </p>
          <p>
            A funcionalidade permite ao utilizador criar eventos de
            processamento com diferentes graus de afetação, como também definir
            a sua periodicidade.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
