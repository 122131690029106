import React from 'react';
import './styles.scss';
import { Lista } from '../../../../components/Lista';

export function Atualizacao() {

  const items = [
    [
      { text: 'Acesso à base de dados correta que contém as informações necessárias para o balancete;' },
      { text: 'As credenciais de acesso à base de dados estão corretas e atualizadas;' },
      { text: 'O tipo de conexão utilizado para acessar a base de dados é o correto e está funcionando corretamente;' },
      { text: 'A versão da aplicação Mastercontas está atualizada e compatível com a base de dados a ser acessada;' },
      { text: 'Não há nenhum conflito ou erro na execução da rotina Extesion.' }
    ],
    [
      { text: 'Selecionar o tipo de balancete; ' },
      { text: 'Selecionar os períodos que deseja obter resultados; ' },
      { text: 'Clicar em atualizar; ' },
      { text: 'Gravar.' }
    ]
  ];

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Actualização</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <p>A atualização das informações dos balancetes, é a rotina de busca direita de informações em uma base de dados diferente da aplicação Mastercontas. </p>
          <p>Para executar a rotina de atualização do balancete, deve garantir primeiramente: </p>
          <Lista isOrdered={false} isNumeric={true} items={items[0]} />
          {/* <hr /> */}
          <p><strong>Como atualizar o balancete:</strong></p>
          <ul>
            <Lista isOrdered={false} isNumeric={false} items={items[1]} />
          </ul>
          {/* <hr /> */}
          <p>É importante que as informações sejam conferidas e validadas para garantir que não haja erros ou omissões que possam afetar os processamentos. </p>
          <p>Para realizar essa rotina, é importante que a base de dados utilizada esteja sempre atualizada e integrada à aplicação Mastercontas para que as informações sejam transferidas corretamente.</p>
        </div>
      </div>
    </React.Fragment>
  )
};
